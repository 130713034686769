import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import DeviceService from "../device/DeviceService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { FileUploader, NumberBox } from "devextreme-react";

const authProvider = AuthProvider();
const deviceService = DeviceService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return deviceService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return deviceService.findOne(key).then((response) => {
        return response;
      });
    },
    update: (key: any, values: any) => {
      values.id = key;
      return deviceService.updateDevice(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function DeviceUserPage() {
  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar title={t("device") + " " + t("lists")} icon="devices" fontSize="medium" />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("deviceTxUpdate")}
            allowAdding={false}
            allowDeleting={false}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "device.information" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="name" />
              <Item dataField="txPowerDbm" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="name"
            dataType="string"
            allowEditing={false}
            sortOrder="asc"
          >
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "power.dbm" })}
            dataField="txPowerDbm"
            editCellRender={(d) => {
              return (
                <NumberBox
                  defaultValue={d.value}
                  min={10}
                  max={31.50}
                  showSpinButtons={true}
                  step={0.25}
                  onValueChanged={(val) => {
                    d.setValue(val.value);
                  }}
                />
              );
            }}
          />
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeviceUserPage;
