import React from "react";
import AuthProvider from "../../../../providers/AuthProvider";
import ko from "knockout";
import "devexpress-reporting/dx-reportdesigner";
import "devexpress-reporting/dx-webdocumentviewer";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import "devextreme/dist/css/dx.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";
import themes from "devextreme/ui/themes";

import messagesReportingTR from "assets/i18n/devextreme/dx-reporting.tr.json";
import messagesAnalyticsTR from "assets/i18n/devextreme/dx-analytics-core.tr.json";
import { getLangLocalStorage } from "../../../../providers/LanguageProvider";
import messagesAnalyticsEN from "../../../../assets/i18n/devextreme/dx-analytics-core.en.json";
import messagesReportingEN from "../../../../assets/i18n/devextreme/dx-reporting.en.json";

const authProvider = AuthProvider();

class ReportViewer extends React.Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();

    ajaxSetup.ajaxSettings = {
      headers: {
        ProjectUrl: process.env.REACT_APP_API_URL,
        Authorization: "Bearer " + authProvider.getAuthData().access_token,
      },
    };
  }

  render() {
    themes.current("generic.light");
    const style = {
      height: "calc(100vh - 130px)",
    };
    return (
      <div
        style={style}
        ref={(ref) => {
          this.divRef = ref;
        }}
        data-bind="dxReportViewer: $data"
      />
    );
  }
  componentDidMount() {
    ko.applyBindings(
      {
        reportUrl: ko.observable(this.props.item.reportName),
        requestOptions: {
          host: process.env.REACT_APP_REPORT_URL,
          invokeAction: "/DXXRDV",
        },
        callbacks: {
          BeforeRender: (s, e) => {
            e.reportPreview.zoom(1);
            // e.reportPreview.zoom(ZoomAutoBy.PageWidth);
          },
          CustomizeElements: (s, e) => {
            // const panelPart = e.GetById(PreviewElements.RightPanel);
            // const indexname = e.Elements.indexOf(panelPart);
            // e.Elements.splice(indexname, 1);
          },
          ParametersInitialized: (s, e) => {
            // console.log('ParametersInitialized', s, e)
            // e.parametersModel.submit();
            // parametersInitialized.previewModel.parametersModel.submit();
          },
          CustomizeLocalization: async (s, e) => {
            const lang = await getLangLocalStorage();
            console.log("CustomizeLocalization", lang);
            if (lang === "tr") {
              e.LoadMessages(messagesReportingTR);
              e.LoadMessages(messagesAnalyticsTR);
            } else {
              e.LoadMessages(messagesAnalyticsEN);
              e.LoadMessages(messagesReportingEN);
            }
          },
        },
      },
      this.divRef
    );
  }
  componentWillUnmount() {
    ko.cleanNode(this.divRef);
    themes.current("material.blue.light");
  }
}

export default ReportViewer;
