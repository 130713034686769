import AuthProvider from "../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "person/";
function PersonService() {
  const findOne = async (id) => {
    const requestOptions = {
      method: "GET",
    };
    return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
  };

  const findAll = async (loadOptions) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loadOptions),
    };
    return authProvider.authFetch(basePath + "personFindAll", requestOptions);
  };

  const save = async (data) => {
    const file = data.image;
    const formData: FormData = new FormData();
    const blobData = new Blob([JSON.stringify(data)], { type: "application/json" });
    formData.append("data", blobData);
    if (file && file.name) {
      formData.append("file", file, file.name);
    }
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    return authProvider.authFetch(basePath + "save", requestOptions);
  };

  const update = (data) => {
    // console.log('data', data);
    const file = data.image;
    // console.log('file', file);
    const formData: FormData = new FormData();
    const blobData = new Blob([JSON.stringify(data)], { type: "application/json" });
    formData.append("data", blobData);
    if (file && file.name) {
      formData.append("file", file, file.name);
    }
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    return authProvider.authFetch(basePath + "update", requestOptions);
  };

  const deleteOne = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "delete", requestOptions);
  };

  return {
    findOne,
    findAll,
    save,
    update,
    deleteOne,
  };
}

export default PersonService;
