/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-countup components
import CountUp from "react-countup";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Colors
import colors from "assets/theme/base/colors";
// Media Query
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

function DefaultCounterCard({
                                title,
                                totalBoxCount,
                                countTotal,
                                countDevice,
                                titleTotalBoxCount,
                                titleCountTotal,
                                titleCountDevice,
                                description,
                            }) {
    const {light, dark, rapidoLogin, black} = colors;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("xxl"));
    // const matchesTablet = useMediaQuery(theme.breakpoints.up("lg"));
    return (
        <Card sx={{height: "100%", width: "100%", borderRadius: 2}}>
            <SuiBox
                height={"100%"}
                textAlign="center"
                lineHeight={1.25}
                color={"black"}
                bgColor={"light"}
            >
                <SuiBox mb={1} color={"light"} bgColor={"dark"} pt={2.25} pb={2.25} pl={3}>
                    <SuiTypography textAlign="left" variant="h6" color={"light"}>
                        {title}
                    </SuiTypography>
                </SuiBox>
                <SuiBox display={"flex"} flexDirection={"column"}
                        gap={{xs: "15px", sm: "30px", md: "30px", lg: "30px", xl: "30px", xxl: "50px"}}>
                    <SuiBox mt={{xs: 0, sm: 2, md: 2, lg: 2, xl: 2, xxl: 4}} pt={{lg: 0, xxl: 2}} pb={0}
                            color={"black"}>
                        <Grid container spacing={0} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={5}>
                                {matches && (
                                    <SuiBox color={"dark"} textAlign="center">
                                        <SuiTypography variant="h2" color={"rapidoLogin"}>
                                            <CountUp end={countDevice} duration={1}/>
                                        </SuiTypography>
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox color={"dark"} textAlign="center">
                                        <SuiTypography variant="h3" color={"rapidoLogin"}>
                                            <CountUp end={countDevice} duration={1}/>
                                        </SuiTypography>
                                    </SuiBox>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={1}>
                                {matches && (
                                    <SuiBox>
                                        <Divider
                                            sx={{
                                                border: 0.1,
                                                borderRadius: 2,
                                                height: 65,
                                                color: "black",
                                                bgColor: "black",
                                                opacity: 0.6,
                                                gradient: 0,
                                            }}
                                            orientation="vertical"
                                        />
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox display="grid" justifyContent="center" mt={-1} mb={-0.5}>
                                        <Divider
                                            sx={{
                                                border: 0.1,
                                                borderRadius: 2,
                                                width: 150,
                                                color: "black",
                                                bgColor: "black",
                                                opacity: 0.6,
                                                gradient: 0,
                                            }}
                                            orientation="horizontal"
                                        />
                                    </SuiBox>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
                                {matches && (
                                    <SuiBox color={"black"}>
                                        {titleCountDevice && (
                                            <SuiTypography
                                                variant="h5"
                                                color={"black"}
                                                textAlign="left"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {titleCountDevice}
                                            </SuiTypography>
                                        )}
                                        {description && (
                                            <SuiTypography
                                                variant="h6"
                                                textAlign="left"
                                                color={"black"}
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {description}
                                            </SuiTypography>
                                        )}
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox
                                        mt={-0.5}
                                        mb={2}
                                        color={black.main}
                                        display="grid"
                                        justifyContent="center"
                                    >
                                        {titleCountDevice && (
                                            <SuiTypography
                                                variant="h6"
                                                color={"black"}
                                                textAlign="center"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {titleCountDevice}
                                            </SuiTypography>
                                        )}
                                        {description && (
                                            <SuiTypography
                                                variant="h6"
                                                textAlign="center"
                                                color={"black"}
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {description}
                                            </SuiTypography>
                                        )}
                                    </SuiBox>
                                )}
                            </Grid>
                        </Grid>
                    </SuiBox>
                    <SuiBox mt={0} pt={0} pb={0} color={"black"}>
                        <Grid container spacing={0} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={5}>
                                {matches && (
                                    <SuiBox color={"dark"} textAlign="center">
                                        <SuiTypography variant="h2" color={"rapidoLogin"}>
                                            <CountUp end={totalBoxCount} duration={1}/>
                                        </SuiTypography>
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox color={"dark"} textAlign="center">
                                        <SuiTypography variant="h3" color={"rapidoLogin"}>
                                            <CountUp end={totalBoxCount} duration={1}/>
                                        </SuiTypography>
                                    </SuiBox>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={1}>
                                {matches && (
                                    <SuiBox>
                                        <Divider
                                            sx={{
                                                border: 0.1,
                                                borderRadius: 2,
                                                height: 65,
                                                color: "black",
                                                bgColor: "black",
                                                opacity: 0.6,
                                                gradient: 0,
                                            }}
                                            orientation="vertical"
                                        />
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox display="grid" justifyContent="center" mt={-1} mb={-0.5}>
                                        <Divider
                                            sx={{
                                                border: 0.1,
                                                borderRadius: 2,
                                                width: 150,
                                                color: "black",
                                                bgColor: "black",
                                                opacity: 0.6,
                                                gradient: 0,
                                            }}
                                            orientation="horizontal"
                                        />
                                    </SuiBox>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
                                {matches && (
                                    <SuiBox color={"black"}>
                                        {titleTotalBoxCount && (
                                            <SuiTypography
                                                variant="h5"
                                                color={"black"}
                                                textAlign="left"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {titleTotalBoxCount}
                                            </SuiTypography>
                                        )}
                                        {description && (
                                            <SuiTypography
                                                variant="h6"
                                                textAlign="left"
                                                color={"black"}
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {description}
                                            </SuiTypography>
                                        )}
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox color={"black"} display="grid" justifyContent="center" mt={-0.5} mb={2}>
                                        {titleTotalBoxCount && (
                                            <SuiTypography
                                                variant="h6"
                                                color={"black"}
                                                textAlign="center"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {titleTotalBoxCount}
                                            </SuiTypography>
                                        )}
                                        {description && (
                                            <SuiTypography
                                                variant="h6"
                                                textAlign="center"
                                                color={"black"}
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {description}
                                            </SuiTypography>
                                        )}
                                    </SuiBox>
                                )}
                            </Grid>
                        </Grid>
                    </SuiBox>
                    <SuiBox mb={{lg: 0, xl: 1, xxl: 2}} pb={{xs: 1, lg: 0, xxl: 2}} color={"black"}>
                        <Grid container spacing={0} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={5}>
                                {matches && (
                                    <SuiBox color={"dark"} textAlign="center">
                                        <SuiTypography variant="h2" color={"rapidoLogin"}>
                                            <CountUp end={countTotal} duration={1}/>
                                        </SuiTypography>
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox color={"dark"} textAlign="center">
                                        <SuiTypography variant="h3" color={"rapidoLogin"}>
                                            <CountUp end={countTotal} duration={1}/>
                                        </SuiTypography>
                                    </SuiBox>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={1}>
                                {matches && (
                                    <SuiBox>
                                        <Divider
                                            sx={{
                                                border: 0.1,
                                                borderRadius: 2,
                                                height: 65,
                                                color: "black",
                                                bgColor: "black",
                                                opacity: 0.6,
                                                gradient: 0,
                                            }}
                                            orientation="vertical"
                                        />
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox display="grid" justifyContent="center" mt={-1} mb={-0.5}>
                                        <Divider
                                            sx={{
                                                border: 0.1,
                                                borderRadius: 2,
                                                width: 150,
                                                color: "black",
                                                bgColor: "black",
                                                opacity: 0.6,
                                                gradient: 0,
                                            }}
                                            orientation="horizontal"
                                        />
                                    </SuiBox>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
                                {matches && (
                                    <SuiBox color={"black"}>
                                        {titleCountTotal && (
                                            <SuiTypography
                                                variant="h5"
                                                color={"black"}
                                                textAlign="left"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {titleCountTotal}
                                            </SuiTypography>
                                        )}
                                        {description && (
                                            <SuiTypography
                                                variant="h6"
                                                textAlign="left"
                                                color={"black"}
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {description}
                                            </SuiTypography>
                                        )}
                                    </SuiBox>
                                )}
                                {!matches && (
                                    <SuiBox color={"black"} display="grid" justifyContent="center" mt={-0.5} mb={0}>
                                        {titleCountTotal && (
                                            <SuiTypography
                                                variant="h6"
                                                color={"black"}
                                                textAlign="center"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                            >
                                                {titleCountTotal}
                                            </SuiTypography>
                                        )}
                                        {description && (
                                            <SuiTypography
                                                variant="h6"
                                                textAlign="center"
                                                color={"black"}
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                            >
                                                {description}
                                            </SuiTypography>
                                        )}
                                    </SuiBox>
                                )}
                            </Grid>
                        </Grid>
                    </SuiBox>
                </SuiBox>
            </SuiBox>
        </Card>
    );
}

// Setting default values for the props of DefaultCounterCard
DefaultCounterCard.defaultProps = {
    description: "",
    titleCountDevice: "",
    titleTotalBoxCount: "",
    titleCountTotal: "",
    title: "",
};

// Typechecking props for the BlogCard
DefaultCounterCard.propTypes = {
    totalBoxCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    countTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    countDevice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    titleCountTotal: PropTypes.string.isRequired,
    titleTotalBoxCount: PropTypes.string.isRequired,
    titleCountDevice: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default DefaultCounterCard;
