import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import vendor from "../../../../../../assets/images/faces/vendor.png";
import Typography from "@mui/material/Typography";

function SelectVendorPage({ data, selectItem }) {
  return (
    <Grid container mt={2} spacing={0} bgcolor="transparent">
      {data &&
        data.map((val) => {
          return (
            <Grid
              component={Card}
              item
              key={val.id}
              ml={1}
              mr={2}
              mb={1}
              xs={12}
              sm={12}
              md={3}
              lg={2}
            >
              <Card
                sx={{ width: "100%", cursor: "pointer" }}
                onClick={() => {
                  selectItem(val);
                }}
              >
                <CardMedia
                  component="img"
                  width="100%"
                  image={
                    val.contentId
                      ? process.env.REACT_APP_API_URL +
                        "binaryContents/getPublicImage/" +
                        val.contentId
                      : vendor
                  }
                  alt="thumbnail"
                />
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        position: 'absolute',*/}
                {/*        bottom: 0,*/}
                {/*        left: 0,*/}
                {/*        width: '100%',*/}
                {/*        bgcolor: 'rgba(0, 0, 0, 0.54)',*/}
                {/*        color: 'white',*/}
                {/*        padding: '5px',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Typography variant="h5">Lizard</Typography>*/}
                {/*    <Typography variant="body2">{val.name}</Typography>*/}
                {/*</Box>*/}
                <CardContent>
                  <Typography
                    sx={{
                      position: "absolute",
                      bottom: "10px",
                      left: "10px",
                      color: "black",
                      backgroundColor: "transparent",
                    }}
                  >
                    {val.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default SelectVendorPage;
