import i18next from "./../i18n";
let lang;
export const getLngLocalStorage = () => {
  if (lang) {
    return lang;
  }
  lang = localStorage.getItem("lang");
  if (!lang) {
    setLangLocalStorage(navigator.language);
  }
  return lang;
};

export const getLangLocalStorage = async () => {
  if (lang) {
    return lang;
  }
  lang = localStorage.getItem("lang");
  if (!lang) {
    await setLangLocalStorage(navigator.language);
  }
  return lang;
};

export const setLangLocalStorage = (language) => {
  if (!language) {
    language = localStorage.getItem("lang");
    if (!language) {
      language = navigator.language;
    }
  }
  lang = sortLang(language);
  localStorage.setItem("lang", lang);
  i18next.changeLanguage(lang);
};

const sortLang = (lang) => {
  let shortLang = lang;
  if (shortLang.indexOf("_") !== -1) {
    shortLang = shortLang.split("_")[0];
  }
  if (shortLang.indexOf("-") !== -1) {
    shortLang = shortLang.split("-")[0];
  }

  return shortLang;
};
