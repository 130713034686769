import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  Form,
  Item,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  Popup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import InventoryTagService from "../inventorytag/InventoryTagService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import InditexTagRecordService from "./InditexTagRecordService";
import VendorService from "../vendor/VendorService";
import SectionService from "../section/SectionService";
import ProductTypesService from "../producttype/ProductTypeService";
import TagTypeService from "../tagtype/TagTypeService";
import TagSubTypeService from "../tagsubtype/TagSubTypeService";
import notify from "devextreme/ui/notify";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EpcTagService from "./EpcTagService";
import { confirm } from "devextreme/ui/dialog";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { SpeedDialAction } from "devextreme-react";
import { t } from "i18next";
import TagActiveTypeService from "../tagactivetype/TagActiveTypeService";
import InditexTagStatusService from "./InditexTagStatusService";

const authProvider = AuthProvider();
const inditexTagRecordService = InditexTagRecordService();
const inventoryTagService = InventoryTagService();
const vendorService = VendorService();
const sectionService = SectionService();
const productTypeService = ProductTypesService();
const tagTypeService = TagTypeService();
const inditexTagStatusService = InditexTagStatusService();
const tagSubTypeService = TagSubTypeService();
const epcTagService = EpcTagService();
const tagActiveTypeService = TagActiveTypeService();

let selectedInditexRecordId = 0;
const epcTags = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    console.log(selectedInditexRecordId);
    return epcTagService.findAllByInditexTagRecordsId(selectedInditexRecordId).then(
      (response) => ({
        data: response,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return epcTagService.findOne(key).then((response) => {
      return response;
    });
  },
});

const inventoryTagDataSourceV1 = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    return inventoryTagService.findAllVersion(1).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return inventoryTagService.findOne(key).then((response) => {
      return response;
    });
  },
});

const inventoryTagDataSourceV2 = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    return inventoryTagService.findAllVersion(2).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return inventoryTagService.findOne(key).then((response) => {
      return response;
    });
  },
});

// const vendorSizeDataSource = new CustomStore({
//   key: "id",
//   load(loadOptions: any) {
//     return productSizeService.findAll(loadOptions).then(
//       (response) => ({
//         data: response.items,
//         totalCount: response.totalCount,
//       }),
//       (err) => {
//         return err.json().then((r) => {
//           throw r.errorMessage ? r.errorMessage : r.warningMessage;
//         });
//       }
//     );
//   },
//   byKey: (key: any) => {
//     return productSizeService.findOne(key).then((response) => {
//       return response;
//     });
//   },
// });

const vendorDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    if (isNewRow) {
      loadOptions.filter = ["view", "=", true];
    }
    return vendorService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return vendorService.findOne(key).then((response) => {
      return response;
    });
  },
});
const sectionDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    if (isNewRow) {
      loadOptions.filter = ["view", "=", true];
    }
    return sectionService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return sectionService.findOne(key).then((response) => {
      return response;
    });
  },
});
const productTypeDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    if (isNewRow) {
      loadOptions.filter = ["view", "=", true];
    }
    return productTypeService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return productTypeService.findOne(key).then((response) => {
      return response;
    });
  },
});
const tagTypeDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    if (isNewRow) {
      loadOptions.filter = ["view", "=", true];
    }
    return tagTypeService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return tagTypeService.findOne(key).then((response) => {
      return response;
    });
  },
});

const inditexTagStatusSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    return inditexTagStatusService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return inditexTagStatusService.findOne(key).then((response) => {
      return response;
    });
  },
});

const tagActiveTypeDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    if (isNewRow) {
      loadOptions.filter = ["view", "=", true];
    }
    return tagActiveTypeService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return tagActiveTypeService.findOne(key).then((response) => {
      return response;
    });
  },
});

const tagSubTypeDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    if (isNewRow) {
      loadOptions.filter = ["view", "=", true];
    }
    return tagSubTypeService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return tagSubTypeService.findOne(key).then((response) => {
      return response;
    });
  },
});

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return inditexTagRecordService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return inditexTagRecordService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return inditexTagRecordService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return inditexTagRecordService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return inditexTagRecordService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

// function setStateValue(rowData, value) {
//   rowData.brand = null;
//   this.defaultSetCellValue(rowData, value);
// }

let barcodeNumber;

function setBarcodeValue(newData, value, currentRowData) {
  let controlBit;
  barcodeNumber = value.toString();
  newData.barcode = value;
  if (barcodeNumber.length === 14) {
    controlBit = barcodeNumber.substring(13, 14);
    barcodeNumber = barcodeNumber.substring(0, 13);

    var sum = 0;
    var factor = 3;

    for (var i = barcodeNumber.length; i > 0; --i) {
      let temp = parseInt(barcodeNumber.substring(i - 1, i));
      sum += temp * factor;
      factor = 4 - factor;
    }

    if (((10 - (sum % 10)) % 10).toString() === controlBit) {
      newData.model = barcodeNumber.substring(1, 5);
      newData.quality = barcodeNumber.substring(5, 8);
      newData.color = barcodeNumber.substring(8, 11);
      newData.bodySize = barcodeNumber.substring(11, 13);
      notify({
        message: t("barcode.parse.success"),
        type: "success",
        displayTime: 3000,
        height: 50,
        shading: false,
      });
    } else {
      newData.model = 0;
      newData.quality = 0;
      newData.color = 0;
      newData.bodySize = 0;
      notify({
        message: t("barcode.parse.failed"),
        type: "error",
        displayTime: 3000,
        height: 50,
        shading: false,
      });
    }
  } else {
    newData.model = 0;
    newData.quality = 0;
    newData.color = 0;
    newData.bodySize = 0;
    notify({
      message: t("barcode.entered.missing"),
      type: "error",
      displayTime: 3000,
      height: 50,
      shading: false,
    });
  }
  this.defaultSetCellValue(newData, value);
  return;
}

let selectedInditexRow;
const onExporting = (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("EPC");
  console.log(selectedInditexRow);
  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    includeHeader: false,

    encodeExecutableContent: true,
  }).then(function () {
    worksheet.spliceRows(0, 1);
    workbook.csv.writeBuffer().then((buffer) => {
      // let bufferString = buffer.toString();
      // var lines = bufferString.split('\n');
      // lines.splice(0, 1);
      // var newtext = lines.join('\n');
      //
      // buffer = Buffer.from(newtext, "utf-8");
      //
      // console.log(buffer.toString());
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        selectedInditexRow.uniqueProviderId +
          "-" +
          selectedInditexRow.id +
          "_" +
          selectedInditexRow.model +
          "_" +
          selectedInditexRow.quality +
          "_" +
          selectedInditexRow.color +
          "_" +
          selectedInditexRow.bodySize +
          "_" +
          (selectedInditexRow.po !== null &&
          selectedInditexRow.po !== "" &&
          selectedInditexRow.po !== undefined
            ? selectedInditexRow.po
            : "0") +
          ".txt"
      );
    });
  });
  e.cancel = true;
};
let isNewRow = false;
let clone = false;
function InditexTagRecordsPage() {
  const [dataGrid, setDataGrid] = useState();
  const [openDialogv1, setOpenDialogv1] = useState(false);
  const [openDialogv2, setOpenDialogv2] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [epcDetailDialog, setEpcDetailDialog] = useState(false);
  const [errorDetailDialog, setErrorDetailDialog] = useState(false);

  function openEpcDialog(inditexRecord) {
    // setSelectedRow(inditexRecord);
    selectedInditexRecordId = inditexRecord.id;
    selectedInditexRow = inditexRecord;
    setEpcDetailDialog(true);
  }


  function addRow() {
    clone = true;
    dataGrid.instance.addRow();
    // dataGrid.instance.deselectAll();
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  const epcTagDatav1 = new CustomStore({
    key: "serialNumber",
    load(loadOptions: any) {
      return inditexTagRecordService.generateInditexEPCv1(selectedRow.id).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tagSubTypeService.findOne(key).then((response) => {
        return response;
      });
    },
  });
  const epcTagDatav2 = new CustomStore({
    key: "serialNumber",
    load(loadOptions: any) {
      return inditexTagRecordService.generateInditexEPCv2(selectedRow.id).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            setOpenDialogv2(false);
            dataGrid.instance.refresh();
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tagSubTypeService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  function saveAllv1() {
    console.log("v1");
    console.log(selectedRow);
    setOpenDialogv1(false);
    dataGrid.instance.refresh();
    return epcTagService.saveAllv1(selectedRow.id).then(
      () => {
        setSelectedRow({});
        dataGrid.instance.clearSelection();
        dataGrid.instance.refresh();
      },
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  }

  function saveAllv2() {
    console.log("v2");
    console.log(selectedRow);
    setOpenDialogv2(false);
    dataGrid.instance.refresh();
    return epcTagService.saveAllv2(selectedRow.id).then(
      () => {
        setSelectedRow({});
        dataGrid.instance.clearSelection();
        dataGrid.instance.refresh();
      },
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  }

  function generateEPC(version) {
    // console.log(selectedRow);
    // var version = document.getElementById(id + "-select").value;
    if (version === 1) {
      setOpenDialogv1(true);
    } else if (version === 2) {
      setOpenDialogv2(true);
    }
    return;
  }

  // const getFilteredVendor = (options) => {
  //   console.log(options);
  //   if (options.data && options.data.brand && options.data.brand.id) {
  //     console.log(vendorSizeDataSource);
  //     return {
  //       store: vendorSizeDataSource,
  //       filter: ["vendor.id", "=", options.data.brand.id],
  //     };
  //   }
  //   return null;
  // };

  const { t } = useTranslation();

  function onInitNewRow(e) {
    isNewRow = true;
    if (clone) {
      e.data.bodySize = selectedRow.bodySize;
      e.data.brand = selectedRow.brand;
      e.data.color = selectedRow.color;
      e.data.easv1 = selectedRow.easv1;
      e.data.easv2 = selectedRow.easv2;
      e.data.model = selectedRow.model;
      e.data.barcode = selectedRow.barcode;
      e.data.piece = selectedRow.piece;
      e.data.quality = selectedRow.quality;
      e.data.productType = selectedRow.productType;
      e.data.section = selectedRow.section;
      e.data.tagActive = selectedRow.tagActive;
      e.data.tagType = selectedRow.tagType;
      e.data.tagSubType = selectedRow.tagSubType;
      e.data.po = selectedRow.po;
      e.data.period = selectedRow.period;
      e.data.purchaseCenterId = selectedRow.purchaseCenterId;
      clone = false;
    }
    // if (dataSource._items.length > 0) {
    //   e.data.startSerialNumber =
    //     dataSource._items[0].startingSerial > 0 ? dataSource._items[0].startingSerial : 2000000000;
    // } else {
    //   e.data.startSerialNumber = 1;
    // }
  }

  function onEditCanceling(e) {
    isNewRow = false;
  }

  function onEditingStart(e) {
    isNewRow = true;
  }

  function selectionChanged(e) {
    if (e.selectedRowsData.length === 0) return;
    if (e.selectedRowsData[0].status === 1) {
      dataGrid.instance.clearSelection();
    } else {
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  function createEPCPlatformRFID(id) {
    console.log("gettagsrfid");
    console.log(id);
    dataGrid.instance.beginCustomLoading();
    return inditexTagRecordService.createEPCPlatformRFID(id).then(
      () => {
        dataGrid.instance.clearSelection();
        dataGrid.instance.refresh();
        dataGrid.instance.endCustomLoading();
      },
      (err) => {
        return err.json().then((r) => {
          dataGrid.instance.endCustomLoading();
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  }

  function createEPCPlatformRFIDWithOut(id) {
    console.log("EPCPlatformRFIDWithOut");
    console.log(id);
    dataGrid.instance.beginCustomLoading();
    return inditexTagRecordService.createEPCPlatformRFIDWithOut(id).then(
      () => {
        dataGrid.instance.clearSelection();
        dataGrid.instance.refresh();
        dataGrid.instance.endCustomLoading();
      },
      (err) => {
        return err.json().then((r) => {
          dataGrid.instance.endCustomLoading();
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  }

  function addMenuItems(e) {
    if (e.row.rowType === "data") {
      // e.items can be undefined
      if (!e.items) e.items = [];
      dataGrid.instance.selectRows([e.row.data.id], false);
      setSelectedRow(e.row.data);
      if (authProvider.isAuth("inditexTagRecordsSave")) {
        e.items.push({
          text: t("{{key, capitalize}}", { key: "clone.row" }),
          onItemClick: () => {
            addRow();
          },
        });
      }
      if (
        e.row.data.version.indexOf("v1") !== -1 &&
        e.row.data.status !== 1 &&
        e.row.data.status !== 2 &&
        authProvider.isAuth("epcTagRecordSave") &&
        authProvider.isAuth("generateLocaleEpc")
      ) {
        e.items.push({
          text: t("{{key, capitalize}}", { key: "generate.epc.v1" }),
          onItemClick: () => {
            generateEPC(1);
          },
        });
      }
      if (
        e.row.data.version.indexOf("v2") !== -1 &&
        e.row.data.status !== 1 &&
        e.row.data.status !== 2 &&
        authProvider.isAuth("epcTagRecordSave") &&
        authProvider.isAuth("generateLocaleEpc")
      ) {
        e.items.push({
          text: t("{{key, capitalize}}", { key: "generate.epc.v2" }),
          onItemClick: () => {
            generateEPC(2);
          },
        });
      }
      if (
        e.row.data.version.indexOf("getTagsRFID") !== -1 &&
        e.row.data.status !== 1 &&
        e.row.data.status !== 2 &&
        authProvider.isAuth("epcTagRecordSave") &&
        authProvider.isAuth("generateITXEpc")
      ) {
        e.items.push({
          text: t("{{key, capitalize}}", { key: "generate.epc.getTagsRFID" }),
          onItemClick: () => {
            createEPCPlatformRFID(e.row.data.id);
          },
        });
      }
      if (
        e.row.data.version.indexOf("platformMocaco") !== -1 &&
        e.row.data.status !== 1 &&
        e.row.data.status !== 2 &&
        authProvider.isAuth("epcTagRecordSave") &&
        authProvider.isAuth("generateITXEpc")
      ) {
        e.items.push({
          text: t("{{key, capitalize}}", { key: "generate.epc.platformMocaco" }),
          onItemClick: () => {
            createEPCPlatformRFIDWithOut(e.row.data.id);
          },
        });
      }

      if (e.row.data.status === 1) {
        e.items.push({
          text: t("{{key, capitalize}}", { key: "refresh" }),
          onItemClick: () => {
            dataGrid.instance.refresh();
          },
        });
      }
      if (e.row.data.status === 2 && authProvider.isAuth("epcTagRecordFindAll")) {
        e.items.push({
          text: t("{{key, capitalize}}", { key: "epc.view" }),
          onItemClick: () => {
            openEpcDialog(e.row.data);
          },
        });
      }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("inditexTagRecord") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          onInitNewRow={onInitNewRow}
          onEditingStart={onEditingStart}
          onEditCanceling={onEditCanceling}
          onSelectionChanged={selectionChanged}
          onContextMenuPreparing={addMenuItems}
          selectedRowKeys={selectedRow.id}
          onRowDblClick={(test) => {
            if (test.data.status === 2) {
              setSelectedRow(test.data);
              openEpcDialog(test.data);
            } else if (test.data.status === 1) {
              dataGrid.instance.refresh();
            }
          }}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Selection mode="single" />
          <Toolbar>
            <Item location="after">
              {/*<Button*/}
              {/*  icon="trash"*/}
              {/*  onClick={() => {*/}
              {/*    let result = confirm(t("epc.delete.message.all"), "Uyarı");*/}
              {/*    result.then((dialogResult) => {*/}
              {/*      if (dialogResult) {*/}
              {/*        dataGrid.instance.beginCustomLoading();*/}
              {/*        epcTagService.deleteAll().then(*/}
              {/*          () => {*/}
              {/*            dataGrid.instance.refresh();*/}
              {/*            dataGrid.instance.endCustomLoading();*/}
              {/*          },*/}
              {/*          (err) => {*/}
              {/*            return err.json().then((r) => {*/}
              {/*              dataGrid.instance.refresh();*/}
              {/*              dataGrid.instance.endCustomLoading();*/}
              {/*              throw r.errorMessage ? r.errorMessage : r.warningMessage;*/}
              {/*            });*/}
              {/*          }*/}
              {/*        );*/}
              {/*      }*/}
              {/*    });*/}
              {/*  }}*/}
              {/*/>*/}
            </Item>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("inditexTagRecordsUpdate")}
            allowAdding={authProvider.isAuth("inditexTagRecordsSave")}
            allowDeleting={authProvider.isAuth("inditexTagRecordsDelete")}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "inditex.tag.records.title" })}
              showTitle={true}
            />
            <Form>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "common.information" })}
                colSpan={2}
                colCount={2}
              >
                <Item dataField="brand.id" />
                <Item dataField="section.id" />
                <Item dataField="productType.id" />
                <Item dataField="tagActive.id" />
                <Item dataField="tagType.id" />
                <Item dataField="po" />
                <Item dataField="piece" />
                {/*<Item dataField="startSerialNumber" />*/}
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "manuel.entry" })}
                colCount={4}
              >
                <Item dataField="model" />
                <Item dataField="quality" />
                <Item dataField="color" />
                <Item dataField="bodySize" />
                {/*<TabbedItem colSpan={3}>*/}
                {/*  <TabPanelOptions deferRendering={true} />*/}
                {/*  <Tab title={t("{{key, capitalize}}", { key: "bodySize.input.manuel" })}>*/}
                {/*  </Tab>*/}
                {/*  <Tab title={t("{{key, capitalize}}", { key: "bodySize.input.select" })}>*/}
                {/*    <Item dataField="bodySize2" />*/}
                {/*  </Tab>*/}
                {/*</TabbedItem>*/}
              </Item>
              <Item itemType="group" caption={t("{{key, capitalize}}", { key: "input.barcode" })}>
                <Item dataField="barcode" />
              </Item>

              <Item itemType="group" caption={t("{{key, capitalize}}", { key: "v1.information" })}>
                <Item dataField="easv1.id" />
              </Item>

              <Item itemType="group" caption={t("{{key, capitalize}}", { key: "v2.information" })}>
                <Item dataField="tagSubType.id" />
                <Item dataField="easv2.id" />
              </Item>

              <Item
                itemType="group"
                colSpan={2}
                caption={t("{{key, capitalize}}", { key: "inditex.information" })}
              >
                <Item dataField="period" />
                <Item dataField="purchaseCenterId" />
              </Item>
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption=""
            dataField="id"
            dataType="number"
            sortOrder="desc"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            cellRender={(item) => (
              <>
                {item.data.status === 0 ? <Button icon="add" /> : <></>}
                {item.data.status === 1 ? (
                  <Button type="default" icon="clock" onClick={() => dataGrid.instance.refresh()} />
                ) : (
                  <></>
                )}
                {item.data.status === 2 ? <Button type="success" icon="check" /> : <></>}
                {item.data.status === 3 ? (
                  <Button
                    type="danger"
                    icon="remove"
                    onClick={() => {
                      setSelectedRow(item.data);
                      selectedInditexRow = item.data;
                      setErrorDetailDialog(true);
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "work.order.number" })}
            dataField="uniqueProviderId"
            dataType="number"
            width={150}
          />
          <Column caption="" dataField="status" dataType="number" width={150} visible={false} />
          <Column
            caption={t("{{key, capitalize}}", { key: "po.text" })}
            dataField="po"
            dataType="string"
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "piece" })}
            allowFiltering={false}
            dataField="piece"
            width={80}
            dataType="string"
          >
            <RequiredRule></RequiredRule>
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "brand" })}
            dataField="brand.id"
            // setCellValue={setStateValue}
          >
            <Lookup dataSource={vendorDataSource} displayExpr="name" valueExpr="id" />
            <RequiredRule></RequiredRule>
          </Column>
          <Column caption={t("{{key, capitalize}}", { key: "sectionId" })} dataField="section.id">
            <Lookup dataSource={sectionDataSource} displayExpr="description" valueExpr="id" />
            <RequiredRule></RequiredRule>
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "productTypeId" })}
            dataField="productType.id"
          >
            <Lookup dataSource={productTypeDataSource} displayExpr="description" valueExpr="id" />
            <RequiredRule></RequiredRule>
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "barcode" })}
            dataField="barcode"
            dataType="string"
            width={150}
            setCellValue={setBarcodeValue}
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "model" })}
            dataField="model"
            dataType="string"
            width={70}
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "quality" })}
            dataField="quality"
            dataType="string"
            width={70}
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "color" })}
            dataField="color"
            dataType="string"
            width={70}
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "body_size" })}
            dataField="bodySize"
            dataType="string"
            width={70}
          ></Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "period" })}
            dataField="period"
            dataType="string"
            visible={false}
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "active" })}
            dataField="tagActive.id"
            visible={false}
          >
            <Lookup
              dataSource={tagActiveTypeDataSource}
              valueExpr="id"
              displayExpr="description"
            ></Lookup>
            <RequiredRule></RequiredRule>
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "purchaseCenterId" })}
            dataField="purchaseCenterId"
            dataType="string"
            visible={false}
          ></Column>
          {/*<Column*/}
          {/*  caption={t("{{key, capitalize}}", { key: "startSerialNumber" })}*/}
          {/*  dataField="startSerialNumber"*/}
          {/*  dataType="number"*/}
          {/*  visible={false}*/}
          {/*>*/}
          {/*  <RequiredRule></RequiredRule>*/}
          {/*</Column>*/}
          <Column
            caption={t("{{key, capitalize}}", { key: "easIdv1" })}
            dataField="easv1.id"
            visible={false}
          >
            <Lookup
              dataSource={inventoryTagDataSourceV1}
              displayExpr="description"
              valueExpr="id"
            />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "easIdv2" })}
            dataField="easv2.id"
            visible={false}
          >
            <Lookup
              dataSource={inventoryTagDataSourceV2}
              displayExpr="description"
              valueExpr="id"
            />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "tagSubTypeId" })}
            dataField="tagSubType.id"
            visible={false}
          >
            <Lookup dataSource={tagSubTypeDataSource} displayExpr="description" valueExpr="id" />
          </Column>
          <Column caption={t("{{key, capitalize}}", { key: "tagTypeId" })} dataField="tagType.id">
            <RequiredRule></RequiredRule>
            <Lookup dataSource={tagTypeDataSource} displayExpr="description" valueExpr="id" />
          </Column>
          {/*<Column*/}
          {/*  caption={t("{{key, capitalize}}", { key: "isPlatform" })}*/}
          {/*  dataField="isCreatedPlatform"*/}
          {/*>*/}
          {/*  <Lookup*/}
          {/*    dataSource={ECreatedPlatform}*/}
          {/*    displayExpr={(row) => {*/}
          {/*      return t("{{key, capitalize}}", {*/}
          {/*        key: row.label,*/}
          {/*      });*/}
          {/*    }}*/}
          {/*    valueExpr="value"*/}
          {/*  ></Lookup>*/}
          {/*  <RequiredRule></RequiredRule>*/}
          {/*</Column>*/}
          <Column
            caption={t("{{key, capitalize}}", { key: "isPlatform" })}
            dataField="inditexTagStatus.id"
          >
            <Lookup dataSource={inditexTagStatusSource} displayExpr="name" valueExpr="id" />
          </Column>
        </DataGrid>

        {selectedRow !== undefined && !epcDetailDialog && !openDialogv1 && !openDialogv2 ? (
          <>
            {selectedRow.version !== undefined ? (
              <SpeedDialAction
                icon="copy"
                visible={authProvider.isAuth("epcTagRecordSave")}
                label={t("clone.row")}
                index={0}
                onClick={() => addRow()}
              />
            ) : (
              <></>
            )}

            {selectedRow.version !== undefined &&
            selectedRow.version.indexOf("v1") !== -1 &&
            selectedRow.status !== 1 &&
            selectedRow.status !== 2 ? (
              <SpeedDialAction
                icon="add"
                visible={authProvider.isAuth(
                  "epcTagRecordSave" && authProvider.isAuth("generateLocaleEpc")
                )}
                label={t("generate.epc.v1")}
                index={1}
                onClick={() => generateEPC(1)}
              />
            ) : (
              <></>
            )}

            {selectedRow.version !== undefined &&
            selectedRow.version.indexOf("v2") !== -1 &&
            selectedRow.status !== 1 &&
            selectedRow.status !== 2 ? (
              <SpeedDialAction
                icon="add"
                visible={
                  authProvider.isAuth("epcTagRecordSave") &&
                  authProvider.isAuth("generateLocaleEpc")
                }
                label={t("generate.epc.v2")}
                index={2}
                onClick={() => generateEPC(2)}
              />
            ) : (
              <></>
            )}

            {selectedRow.version !== undefined &&
            selectedRow.version.indexOf("getTagsRFID") !== -1 &&
            selectedRow.status !== 1 &&
            selectedRow.status !== 2 ? (
              <SpeedDialAction
                icon="add"
                visible={
                  authProvider.isAuth("epcTagRecordSave") && authProvider.isAuth("generateITXEpc")
                }
                label={t("generate.epc.getTagsRFID")}
                index={3}
                onClick={() => createEPCPlatformRFID(selectedRow.id)}
              />
            ) : (
              <></>
            )}

            {selectedRow.version !== undefined &&
            selectedRow.version.indexOf("platformMocaco") !== -1 &&
            selectedRow.status !== 1 &&
            selectedRow.status !== 2 ? (
              <SpeedDialAction
                icon="add"
                visible={
                  authProvider.isAuth("epcTagRecordSave") && authProvider.isAuth("generateITXEpc")
                }
                label={t("generate.epc.platformMocaco")}
                index={4}
                onClick={() => createEPCPlatformRFIDWithOut(selectedRow.id)}
              />
            ) : (
              <></>
            )}

            {selectedRow.status === 2 ? (
              <SpeedDialAction
                icon="bulletlist"
                visible={authProvider.isAuth("epcTagRecordFindAll")}
                label={t("epc.view")}
                index={5}
                onClick={() => openEpcDialog(selectedRow)}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </SuiBox>
      <Footer />
      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialogv1}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenDialogv1(false);
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? selectedRow.uniqueProviderId + "-" + selectedRow.id + " - V1"
            : "V1"}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <span style={{ color: "red", fontSize: "large", fontWeight: "bolder" }}>
            {t("epc.detail.info")}
          </span>
          <DataGrid
            dataSource={epcTagDatav1}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
            showColumnHeaders={false}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              <Item name="columnChooserButton" />
            </Toolbar>

            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Column
              caption={t("{{key, capitalize}}", { key: "serialNumber" })}
              width={200}
              dataField="serialNumber"
            ></Column>
            <Column caption={t("{{key, capitalize}}", { key: "epc" })} dataField="epc"></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "access.password" })}
              dataField="password"
            ></Column>
          </DataGrid>
        </DialogContent>
        <div
          style={{
            width: "100%",
          }}
        >
          <Button
            onClick={saveAllv1}
            text={t("{{key, capitalize}}", { key: "generate" })}
            style={{
              float: "right",
              backgroundColor: "trasparent",
              color: "#03a9f4",
              boxShadow: "none",
              marginRight: "20px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          ></Button>
        </div>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialogv2}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenDialogv2(false);
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? selectedRow.uniqueProviderId + "-" + selectedRow.id + " - V2"
            : "V2"}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <span style={{ color: "red", fontSize: "large", fontWeight: "bolder" }}>
            {t("epc.detail.info")}
          </span>
          <DataGrid
            dataSource={epcTagDatav2}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              <Item name="columnChooserButton" />
            </Toolbar>
            <Scrolling rowRenderingMode="virtual"></Scrolling>

            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />

            <Column
              caption={t("{{key, capitalize}}", { key: "serialNumber" })}
              dataField="serialNumber"
            ></Column>
            <Column caption={t("{{key, capitalize}}", { key: "epc" })} dataField="epc"></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "password" })}
              dataField="password"
            ></Column>
          </DataGrid>
        </DialogContent>
        <div
          style={{
            width: "100%",
          }}
        >
          <Button
            onClick={saveAllv2}
            text={t("{{key, capitalize}}", { key: "generate" })}
            style={{
              float: "right",
              backgroundColor: "trasparent",
              color: "#03a9f4",
              boxShadow: "none",
              marginRight: "20px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          ></Button>
        </div>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setEpcDetailDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={epcDetailDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setEpcDetailDialog(false);
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? selectedRow.uniqueProviderId +
              "-" +
              selectedRow.id +
              " " +
              t("{{key, capitalize}}", { key: "epc.detail.title" })
            : t("{{key, capitalize}}", { key: "epc.detail.title" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <DataGrid
            dataSource={epcTags}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
            onExporting={onExporting}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              <Item location="after">
                <Button
                  visible={authProvider.isAuth("epcTagRecordDelete")}
                  icon="trash"
                  onClick={() => {
                    let result = confirm(t("epc.delete.message"), t("epc.delete.message.title"));
                    result.then((dialogResult) => {
                      if (dialogResult) {
                        dataGrid.instance.beginCustomLoading();
                        epcTagService.deleteInditexTagRecordsId(selectedRow.id).then(
                          () => {
                            setEpcDetailDialog(false);
                            dataGrid.instance.refresh();
                            dataGrid.instance.endCustomLoading();
                            setSelectedRow({});
                            dataGrid.instance.clearSelection();
                          },
                          (err) => {
                            return err.json().then((r) => {
                              dataGrid.instance.refresh();
                              dataGrid.instance.endCustomLoading();
                              throw r.errorMessage ? r.errorMessage : r.warningMessage;
                            });
                          }
                        );
                      }
                    });
                  }}
                />
              </Item>
              <Item name="exportButton" />
              <Item name="columnChooserButton" />
            </Toolbar>
            <Export enabled={true} formats={["csv"]} allowExportSelectedData={true} />

            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />
            <Selection mode="multiple" />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Column
              caption={t("{{key, capitalize}}", { key: "serialNumber" })}
              width={200}
              dataField="serialNumber"
              allowExporting={false}
            ></Column>
            <Column caption={t("{{key, capitalize}}", { key: "epc" })} dataField="epc"></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "password" })}
              dataField="password"
              width={150}
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "kill.password" })}
              dataField="killPassword"
              width={150}
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "tid" })}
              dataField="tid"
              allowExporting={false}
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "version" })}
              dataField="version"
              allowExporting={false}
              width={150}
            ></Column>
          </DataGrid>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setEpcDetailDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={errorDetailDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setErrorDetailDialog(false);
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? selectedRow.uniqueProviderId +
              "-" +
              selectedRow.id +
              " " +
              t("{{key, capitalize}}", { key: "error.message.title" })
            : t("{{key, capitalize}}", { key: "error.message.title" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <span style={{ color: "red", fontSize: "large", fontWeight: "bolder" }}>
            {t("{{key, capitalize}}", { key: "error.message.detail" }) +
              selectedRow.inditexErrorMessage}
          </span>
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default InditexTagRecordsPage;
