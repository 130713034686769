import React, {useState, useEffect} from "react";

// react-router components
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import ScrollView from "devextreme-react/scroll-view";
import List from "@mui/material/List";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarDesktopMenu,
    navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
} from "context";
import colors from "../../../assets/theme/base/colors";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import AuthService from "../../../pages/share/authentication/AuthService";
import {LoadPanel} from "devextreme-react";
import TabPanel from "devextreme-react/tab-panel";
import {useTranslation} from "react-i18next";
import SuiSelect from "../../../components/SuiSelect";
import {getLangLocalStorage, setLangLocalStorage} from "../../../providers/LanguageProvider";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AnnouncementService from "../../../pages/share/announcement/AnnouncementService";
import {betweenDate} from "../../../providers/UtilsProvider";
import announcementImage from "assets/images/announcement.png";
import Popup, {Position} from "devextreme-react/popup";
import {Item} from "devextreme-react/data-grid";
import all from "../../../assets/Documents/all.pdf";
import inspectionNew from "../../../assets/Documents/inspectionNew.pdf";
import inspectionFree from "../../../assets/Documents/inspectionFree.pdf";
import report from "../../../assets/Documents/report.pdf";
import notify from "devextreme/ui/notify";
import HtmlEditor from "devextreme-react/html-editor";

function DashboardNavbar({absolute, light, isMini, onChangeSearch, noLangSelect}) {
    const {dark} = colors;
    const [announcementVisible, setAnnouncementVisible] = useState(false);
    const [oneAnnouncementData, setOneAnnouncementData] = useState();

    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);
    const announcementService = AnnouncementService();
    const authService = AuthService();
    const navigate = useNavigate();
    const [showLoadPanel, setShowLoadPanel] = useState(false);
    const [announcementData, setAnnouncementData] = useState();
    const [timer, setTimer] = useState();

    const [lang, setLang] = useState();

    const [languages, setLanguages] = useState();

    const {t} = useTranslation();

    const [informationVisible, setInformationVisible] = useState(false);
    const path = ["inspection/new", "inspection/free", "report-list"];
    const file = [inspectionNew, inspectionFree, report];
    const [selectedFile, setSelectedFile] = useState();

    getLangLocalStorage().then((lng) => {
        setLang(lng);
    });

    useEffect(() => {
        const options = JSON.parse(localStorage.getItem("langs")).map((item) => ({
            value: item.lang,
            label: item.title,
        }));
        setLanguages(options);

        announcementService.findAllCurrentDate().then((result) => {
            setAnnouncementData(result);
        });

        // setLanguages(localStorage.getItem('langs'));
    }, []);

    useEffect(() => {
        const options = JSON.parse(localStorage.getItem("langs")).map((item) => ({
            value: item.lang,
            label: item.title,
        }));
        setLanguages(options);

        // setLanguages(localStorage.getItem('langs'));
    }, []);

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = (id) => {
        if (id != undefined) {
            // announcementService.findOne(id).then((result) => {
            //     setOneAnnouncementData(result);
            // });
            // setAnnouncementVisible(true);

            setOneAnnouncementData(announcementData.find((data) => data.id == id));
            setAnnouncementVisible(true);

            // for (let i = 0; i < announcementData.length; i++) {
            //     if (announcementData[i].id == id) {
            //         setOneAnnouncementData(announcementData[i]);
            //         setAnnouncementVisible(true);
            //         break;
            //     }
            // }
        }
        setOpenMenu(false);
        // navigate("/announcement");
    };

    const handleOpenUserMenu = (event) => setOpenUserMenu(event.currentTarget);
    const handleCloseUserMenu = () => setOpenUserMenu(false);

    const handleOpenInformationMenu = () => {
        setSelectedFile(path.indexOf(window.location.pathname.slice(1)));
        if (path.indexOf(window.location.pathname.slice(1)) != -1) {
            setInformationVisible(true);
        } else {
            notify({
                message: t("{{key, capitalize}}", {key: "not.found.document"}),
                type: "error",
                displayTime: 3000,
                height: 50,
                shading: false,
            });
        }
    };

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={() => {
                handleCloseMenu();
            }}
            sx={{mt: 0}}
        >
            {announcementData
                ? announcementData.map((item) => {
                    let announcementDate;
                    if (betweenDate(item.releaseDate).days) {
                        announcementDate = `${betweenDate(item.releaseDate).days} ${t("{{key, capitalize}}", {
                            key: "days",
                        })}`;
                    } else if (!betweenDate(item.releaseDate).days && betweenDate(item.releaseDate).hours) {
                        announcementDate = `${betweenDate(item.releaseDate).hours} ${t(
                            "{{key, capitalize}}",
                            {
                                key: "hours",
                            }
                        )}`;
                    } else if (
                        !betweenDate(item.releaseDate).hours &&
                        betweenDate(item.releaseDate).minutes
                    ) {
                        announcementDate = `${betweenDate(item.releaseDate).minutes} ${t(
                            "{{key, capitalize}}",
                            {
                                key: "minutes.ago",
                            }
                        )}`;
                    }

                    return (
                        <NotificationItem
                            key={item.id}
                            image={<img src={announcementImage} alt="person"/>}
                            title={[item.title]}
                            date={
                                //   betweenDate(item.releaseDate).days +
                                //   " " +
                                //   t("{{key, capitalize}}", {
                                //       key: "days",
                                //   }) +
                                //   " " +
                                //   betweenDate(item.releaseDate).hours +
                                //   " " +
                                //   t("{{key, capitalize}}", {
                                //       key: "hours",
                                //   }) +
                                //   " " +
                                //   betweenDate(item.releaseDate).minutes +
                                //   " " +
                                //   t("{{key, capitalize}}", {
                                //       key: "minutes.ago",
                                //   })
                                `${announcementDate}`
                            }
                            onClick={() => {
                                handleCloseMenu(item.id);
                            }}
                        />
                    );
                })
                : null}
        </Menu>
    );

    const renderUserMenu = () => (
        <Menu
            anchorEl={openUserMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openUserMenu)}
            onClose={handleCloseUserMenu}
            sx={{mt: 2}}
        >
            <MenuItem
                onClick={() => {
                    handleCloseUserMenu();
                    navigate("/profile");
                }}
            >
                <Icon
                    sx={({palette: {dark, white}}) => ({
                        color: light ? white.main : dark.main,
                    })}
                >
                    perm_identity
                </Icon>
                <Typography sx={{ml: 1}} variant="body2" color="text.secondary">
                    {t("{{key, capitalize}}", {key: "profile"})}
                </Typography>
            </MenuItem>

            <MenuItem
                onClick={() => {
                    handleCloseUserMenu();
                    setShowLoadPanel(true);
                    authService
                        .logout()
                        .then(() => {
                            setShowLoadPanel(false);
                            navigate("/");
                        })
                        .catch(() => {
                            navigate("/");
                        });
                }}
            >
                <Icon
                    sx={({palette: {dark, white}}) => ({
                        color: light ? white.main : dark.main,
                    })}
                >
                    logout
                </Icon>
                <Typography sx={{ml: 1}} variant="body2" color="text.secondary">
                    {t("{{key, capitalize}}", {key: "exit"})}
                </Typography>
            </MenuItem>
        </Menu>
    );

    const notificationCalculate = () => {
        if (announcementData != undefined) {
            if (announcementData.length > 9) {
                return "9+";
            } else {
                return announcementData.length;
            }
        } else {
            return "0";
        }
    };
    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, {transparentNavbar, absolute, light})}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SuiBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>
                    {/*<Icon*/}
                    {/*  fontSize="large"*/}
                    {/*  sx={navbarDesktopMenu}*/}
                    {/*  onClick={handleMiniSidenav}*/}
                    {/*>*/}
                    {/*  {miniSidenav ? "menu_open" : "menu"}*/}
                    {/*</Icon>*/}
                </SuiBox>
                {isMini ? null : (
                    <SuiBox sx={(theme) => navbarRow(theme, {isMini})}>
                        {onChangeSearch ? (
                            <SuiBox pr={1}>
                                <SuiInput
                                    placeholder={t("{{key, capitalize}}", {key: "search"}) + "..."}
                                    icon={{component: "search", direction: "left"}}
                                    onKeyUp={(e) => {
                                        if (e.keyCode === 13) {
                                            window.clearTimeout(timer);
                                            onChangeSearch(e.target.value);
                                        }
                                    }}
                                    onChange={(e) => {
                                        window.clearTimeout(timer);
                                        if (!e.target.value) {
                                            onChangeSearch(null);
                                            return;
                                        }
                                        setTimer(setTimeout(() => onChangeSearch(e.target.value), 400));
                                    }}
                                />
                            </SuiBox>
                        ) : null}
                        <SuiBox color={light ? "white" : "inherit"}>
                            <IconButton
                                size="large"
                                color="inherit"
                                // sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>

                            <IconButton
                                size="large"
                                color="inherit"
                                //sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "5px",
                                        width: "20px",
                                        fontSize: "12px",
                                        height: "20px",
                                        paddingTop: "0px",
                                        right: "8px",
                                    }}
                                >
                                    <p
                                        style={{
                                            background: "#082342",
                                            color: "white",
                                            borderRadius: "30px",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {notificationCalculate()}
                                    </p>
                                </div>
                            </IconButton>
                            <IconButton
                                size="large"
                                color="inherit"
                                // sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon>settings</Icon>
                            </IconButton>
                            <IconButton
                                size="large"
                                color="inherit"
                                // sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenUserMenu}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>person</Icon>
                            </IconButton>
                            <IconButton
                                size="large"
                                color="inherit"
                                // sx={navbarIconButton}
                                onClick={handleOpenInformationMenu}
                            >
                                <Icon>help</Icon>
                            </IconButton>
                            {renderMenu()}
                            {renderUserMenu()}
                        </SuiBox>

                        {lang && !noLangSelect ? (
                            <SuiBox width={70}>
                                <SuiSelect
                                    option={dark.main}
                                    isLight={light}
                                    size="large"
                                    defaultValue={{value: lang, label: lang.toUpperCase()}}
                                    options={languages}
                                    onChange={(e) => {
                                        setLangLocalStorage(e.value);
                                    }}
                                />
                            </SuiBox>
                        ) : null}
                    </SuiBox>
                )}
            </Toolbar>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={showLoadPanel}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={true}
            />{" "}
            <Popup
                visible={announcementVisible}
                onHiding={() => setAnnouncementVisible(false)}
                showCloseButton={true}
                showTitle={true}
                title={t("{{key, capitalize}}", {key: "announcement"})}
                closeOnOutsideClick={true}
                width={"65%"}
                height={"60%"}
            >
                <Position my="center"/>
                {oneAnnouncementData && oneAnnouncementData.body ? (
                    <ScrollView>
                        <h1>{oneAnnouncementData.title}</h1>
                        <div
                            dangerouslySetInnerHTML={{__html: oneAnnouncementData.body}}
                            style={{paddingTop: 10}}
                        ></div>

                        <p align={"right"} style={{paddingTop: 10}}>
                            {moment(oneAnnouncementData.releaseDate).format("DD/MM/YYYY")}
                        </p>
                    </ScrollView>
                ) : null}
            </Popup>
            <Popup
                visible={informationVisible}
                onHiding={() => {
                    setInformationVisible(false);
                    setSelectedFile(undefined);
                }}
                showCloseButton={true}
                showTitle={true}
                title={t("{{key, capitalize}}", {key: "user.guide"})}
                closeOnOutsideClick={true}
                width={"65%"}
                height={"60%"}
            >
                <Position my="center"/>
                <TabPanel
                    width={"100%"}
                    height={"100%"}
                    animationEnabled={true}
                    scrollByContent={true}
                    showNavButtons={true}
                >
                    <Item title={t("{{key, capitalize}}", {key: "current.page"})}>
                        <embed
                            src={file[selectedFile] + "#toolbar=0"}
                            type="application/pdf"
                            width={"100%"}
                            height={"100%"}
                        ></embed>
                    </Item>
                    <Item title={t("{{key, capitalize}}", {key: "all.page"})}>
                        <embed
                            src={all + "#toolbar=0"}
                            type="application/pdf"
                            width={"100%"}
                            height={"100%"}
                        ></embed>
                    </Item>
                </TabPanel>
            </Popup>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
