import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  Form,
  Item,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  Popup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TempeWithoutPoService from "./TempeWithoutPoService";
import ScrollView from "devextreme-react/scroll-view";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import notify from "devextreme/ui/notify";
import TagSubTypeService from "../tagsubtype/TagSubTypeService";
import VendorService from "../vendor/VendorService";
import { NumberBox } from "devextreme-react";
import { saveAs } from "file-saver";
import TagTypeService from "../tagtype/TagTypeService";
import SectionService from "../section/SectionService";
import ProductTypeService from "../producttype/ProductTypeService";
import TempeEpcResultService from "../tempetagrecord/TempeEpcResultService";

const authProvider = AuthProvider();
const tempeWithoutPoService = TempeWithoutPoService();
const tagTypeService = TagTypeService();
const tagSubTypeService = TagSubTypeService();
const vendorService = VendorService();
const sectionService = SectionService();
const productTypeService = ProductTypeService();
const tempeEpcResultService = TempeEpcResultService();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return tempeWithoutPoService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tempeWithoutPoService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return tempeWithoutPoService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return tempeWithoutPoService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return tempeWithoutPoService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function TempeWithoutPoPage() {
  const [dataGrid, setDataGrid] = useState();
  const [tagDataGrid, setTagDataGrid] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedTagRow, setSelectedTagRow] = useState();
  const [openTagRecord, setOpenTagRecord] = useState();
  const [openEPCRecord, setOpenEPCRecord] = useState();
  const { t } = useTranslation();
  // function onEditorPreparing(e) {
  //   if (isedit)
  //     e.editorOptions.disabled =
  //       e.dataField == "tagSubType.id" || e.dataField == "tagType.id" ? false : true;
  // }

  function onInitNewRow(e) {
    isNewRow = true;
    // if (e.dataField === "po" && e.parentType === "dataRow") {
    //   e.editorOptions.disabled = e.row.data && e.row.data.po === "";
    // }
  }

  function onEditCanceling(e) {
    isNewRow = false;
    isedit = false;
  }

  let isedit = false;

  function onEditingStart(e) {
    isNewRow = true;
    isedit = true;
  }

  let isNewRow = false;
  const tagSubTypeDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return tagSubTypeService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tagSubTypeService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const tagTypeDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return tagTypeService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tagTypeService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const sectionDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return sectionService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return sectionService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const vendorDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return vendorService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return vendorService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const epcTags = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      loadOptions.filter = ["tempeWithoutPo.id", "=", selectedTagRow.id];
      return tempeEpcResultService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tempeEpcResultService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("EPC");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      includeHeader: false,

      encodeExecutableContent: true,
    }).then(function () {
      debugger;
      worksheet.spliceRows(0, 1);
      workbook.csv.writeBuffer().then((buffer) => {
        // eslint-disable-next-line no-undef
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          selectedTagRow !== undefined && selectedTagRow !== null
            ? (selectedTagRow !== undefined ? selectedTagRow.rfidRequestId : "-") +
                "_" +
                (selectedTagRow.sizeCode != undefined
                  ? selectedTagRow.sizeCode.toString().padStart(2, "0")
                  : "00") +
                "_" +
                (selectedTagRow.tagType !== undefined && selectedTagRow.tagType.code !== undefined
                  ? "'" + selectedTagRow.tagType.code + "'"
                  : "'-'") +
                "_" +
                (selectedTagRow.tagSubType !== undefined &&
                selectedTagRow.tagSubType.code !== undefined
                  ? "'" + selectedTagRow.tagSubType.code + "'"
                  : "'-'") +
                "_" +
                (selectedTagRow !== undefined && selectedTagRow.quantity !== undefined
                  ? selectedTagRow.quantity
                  : "-") +
                ".txt"
            : "'-----'.txt"
        );
      });
    });
    e.cancel = true;
  };

  const productTypeDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return productTypeService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return productTypeService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("section") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          rowAlternationEnabled={true}
          onInitNewRow={onInitNewRow}
          onEditingStart={onEditingStart}
          onEditCanceling={onEditCanceling}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            // allowUpdating={false}
            allowUpdating={authProvider.isAuth("tempeWithoutPoUpdate")}
            allowAdding={authProvider.isAuth("tempeWithoutPoSave")}
            allowDeleting={false}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "tempe.unorder.information" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="brand.id" />
              <Item dataField="productType.id" />
              <Item dataField="tagType.id" />
              <Item dataField="tagSubType.id" />
              <Item itemType="group" colCount={3} colSpan={2}>
                <Item dataField="colorCode" />
                <Item dataField="sizeCode" />
                <Item dataField="quantity" />
              </Item>
              {/*<Item*/}
              {/*  itemType="group"*/}
              {/*  caption={t("{{key, capitalize}}", { key: "manuel.entry" })}*/}
              {/*  colCount={2}*/}
              {/*>*/}
              {/*  <Item dataField="model" />*/}
              {/*  <Item dataField="quality" />*/}
              {/*</Item>*/}
              {/*<Item itemType="group" caption={t("{{key, capitalize}}", { key: "input.barcode" })}>*/}
              {/*  <Item dataField="barcode" />*/}
              {/*</Item>*/}
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
          <Column
            caption=""
            dataField="id"
            dataType="number"
            sortOrder="desc"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            allowEditing={false}
            cellRender={(item) => (
              <Button
                type="default"
                icon="bulletlist"
                onClick={async () => {
                  await new Promise((resolve) => setTimeout(resolve, 100)); // 3 sec*/}
                  setSelectedTagRow(item.data);
                  setOpenEPCRecord(true);
                }}
              />
            )}
          />

          <Column
            caption={t("{{key, capitalize}}", { key: "work.order.number" })}
            dataField="rfidRequestId"
            dataType="number"
          ></Column>
          <Column caption={t("{{key, capitalize}}", { key: "brand" })} dataField="brand.id">
            <RequiredRule />
            <Lookup dataSource={vendorDataSource} displayExpr="name" valueExpr="id" />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "productTypeId" })}
            dataField="productType.id"
          >
            <Lookup dataSource={productTypeDataSource} displayExpr="description" valueExpr="id" />
            <RequiredRule />
          </Column>
          <Column caption={t("{{key, capitalize}}", { key: "tagTypeId" })} dataField="tagType.id">
            <Lookup dataSource={tagTypeDataSource} displayExpr="description" valueExpr="id" />
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "tagSubTypeId" })}
            dataField="tagSubType.id"
          >
            <Lookup dataSource={tagSubTypeDataSource} displayExpr="description" valueExpr="id" />
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "colorCode" })}
            dataField="colorCode"
            dataType="number"
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "sizeCode" })}
            dataField="sizeCode"
            dataType="number"
          ></Column>
          <Column
            dataType="number"
            caption={t("{{key, capitalize}}", { key: "matched" })}
            dataField="matched"
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "quantity" })}
            dataField="quantity"
            dataType="number"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "percentMatched" })}
            dataField="percentMatched"
            dataType="number"
          ></Column>
        </DataGrid>
      </SuiBox>
      <Footer />

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          setOpenEPCRecord(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openEPCRecord}
        key={12}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenEPCRecord(false);
          }}
        >
          {selectedTagRow !== undefined
            ? selectedTagRow.rfidRequestId +
              " " +
              t("{{key, capitalize}}", { key: "epc.detail.title" })
            : t("{{key, capitalize}}", { key: "epc.detail.title" })}
          <br />
          <span style={{ fontSize: "smaller" }}>
            {selectedTagRow !== undefined && selectedTagRow !== null
              ? t("{{key, capitalize}}", { key: "model" }) +
                ":" +
                (selectedTagRow.brand !== undefined && selectedTagRow.brand.name !== undefined
                  ? "'" + selectedTagRow.brand.name.toString() + "'"
                  : "'-'") +
                " - " +
                t("{{key, capitalize}}", { key: "productTypeId" }) +
                ":" +
                (selectedTagRow.productType !== undefined &&
                selectedTagRow.productType.description !== undefined
                  ? "'" + selectedTagRow.productType.description + "'"
                  : "'-'") +
                " - " +
                t("{{key, capitalize}}", { key: "sizeCode" }) +
                ": " +
                (selectedTagRow.sizeCode !== undefined
                  ? selectedTagRow.sizeCode.toString().padStart(2, "0")
                  : "00") +
                " - " +
                t("{{key, capitalize}}", { key: "tagType" }) +
                ":" +
                (selectedTagRow.tagType !== undefined &&
                selectedTagRow.tagType.description !== undefined
                  ? "'" + selectedTagRow.tagType.description + "'"
                  : "'-'") +
                " - " +
                t("{{key, capitalize}}", { key: "tagSubType" }) +
                ":" +
                (selectedTagRow.tagSubType !== undefined &&
                selectedTagRow.tagSubType.description !== undefined
                  ? "'" + selectedTagRow.tagSubType.description + "'"
                  : "'-'")
              : "'-----'"}
          </span>
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <DataGrid
            dataSource={epcTags}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
            onExporting={onExporting}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              <Item name="exportButton" />
              <Item name="columnChooserButton" />
            </Toolbar>
            <Export enabled={true} formats={["csv"]} allowExportSelectedData={true} />

            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />
            <Selection mode="multiple" />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Column
              caption={t("{{key, capitalize}}", { key: "epc" })}
              width={400}
              dataField="epcHex"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "accessPasswordHex" })}
              dataField="accessPasswordHex"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "kill.password" })}
              dataField="killPasswordHex"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "userMemoryHex" })}
              dataField="userMemoryHex"
              allowExporting={false}
            ></Column>
          </DataGrid>
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default TempeWithoutPoPage;
