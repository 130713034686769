// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

const { dark } = colors;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      color: dark.main,
    },
  },
};
