// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import FormField from "../FormField";
import { useTranslation } from "react-i18next";
import ProfileService from "../../profile/ProfileService";
import notify from "devextreme/ui/notify";
import React, { useState } from "react";
import { LoadPanel } from "devextreme-react";

let profileModel = {
  oldPassword: "",
  password: null,
  confirmPassword: "",
};

function ChangePassword() {
  const [showLoadPanel, setShowLoadPanel] = useState(false);
  const profileService = ProfileService();
  const { t } = useTranslation();
  return (
    <Card id="change-password">
      <SuiBox pt={2} px={2} lineHeight={1}>
        <SuiTypography variant="h6" fontWeight="medium">
          {t("{{key, capitalize}}", { key: "change.password" })}
        </SuiTypography>
      </SuiBox>
      <SuiBox component="form" p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              onChange={(e) => {
                profileModel.oldPassword = e.target.value;
              }}
              label={t("{{key, capitalize}}", { key: "current.password" })}
              placeholder={t("{{key, capitalize}}", { key: "current.password" })}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              onChange={(e) => {
                profileModel.password = e.target.value;
              }}
              label={t("{{key, capitalize}}", { key: "new.password" })}
              placeholder={t("{{key, capitalize}}", { key: "new.password" })}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              onChange={(e) => {
                profileModel.confirmPassword = e.target.value;
              }}
              label={t("{{key, capitalize}}", { key: "confirm.password" })}
              placeholder={t("{{key, capitalize}}", { key: "confirm.password" })}
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <SuiBox mt={2}>
          <SuiButton
            variant="gradient"
            color="dark"
            fullWidth
            onClick={() => {
              if (
                profileModel.password == null ||
                profileModel.password !== profileModel.confirmPassword
              ) {
                notify({
                  message: t("missing.information"),
                  type: "error",
                  displayTime: 3000,
                  height: 50,
                  shading: false,
                });
                return;
              }
              setShowLoadPanel(true);
              profileService.update(profileModel).then(
                () => {
                  setShowLoadPanel(false);
                },
                (err) => {
                  setShowLoadPanel(false);
                }
              );
            }}
          >
            {t("update.password")}
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <LoadPanel
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={true}
      />
    </Card>
  );
}

export default ChangePassword;
