import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Colors
import colors from "assets/theme/base/colors";
// Card:
import Card from "@mui/material/Card";
// Soft UI Dashboard Chart, Data Set and Counter Card components
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// Footer
import Footer from "../../../../examples/Footer";
// Data
import ControllerCard from "examples/Cards/ControllerCard";
// Navbar and DashboardLayout
import DashboardLayout from "../../../../layouts/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
// Icons for cards
import rapidoScanIcon from "../../../../assets/images/rapidoScan.svg";
import rapidoFreeScanIcon from "../../../../assets/images/rapidoFreeScan.svg";
import rapidoReportIcon from "../../../../assets/images/rapidoReport.svg";
import rapidoDocumentIcon from "../../../../assets/images/rapidoDocument.svg";
// import DummyService from "../../service/DummyService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PieChart } from "devextreme-react";
// Devextreme DataGrid
import DataGrid, { Scrolling, Paging, Pager, Column } from "devextreme-react/data-grid";
import {
  Aggregation,
  ArgumentAxis,
  CommonSeriesSettings,
  Format,
  Label,
  Legend,
  Series,
  Title,
  Tooltip,
} from "devextreme-react/chart";
import { initWebsocket } from "../../../../providers/WebSocketProvider";
import { CardActions, CardHeader } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Connector, Export } from "devextreme-react/pie-chart";
// import ProductService from "../../../app/product/ProductService";
import DataSource from "devextreme/data/data_source";
import dxFunnel from "devextreme/viz/funnel";

import CustomStore from "devextreme/data/custom_store";
// real service
import RapidoService from "../../rpd/RapidoService";

const rapidoService = RapidoService();

const readerDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    return rapidoService.result("vw_dashboard_box_count_by_department").then(
      (data) => ({
        data: data,
        totalCount: data.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
});

function MainDashboardPage() {
  const { light, dark } = colors;
  const [sum, setSum] = useState(0);
  const { t } = useTranslation();
  const [dashboardTotalBoxCount, setDashboardTotalBoxCount] = useState(0);
  const [dashboardDeviceCount, setDashboardDeviceCount] = useState(0);
  const [dashboardTotalCounting, setDashboardTotalCounting] = useState(0);
  const [dashboardBoxCountByOrder, setDashboardBoxCountByOrder] = useState([]);

  // Cihaz sayısı: vw_dashboard_device_count
  // Toplam sayım: vw_dashboard_total_counting
  // Siparişe göre kutu sayısı: vw_dashboard_box_count_by_order
  // Markaya göre kutu sayısı: vw_dashboard_box_count_by_department

  const customizeText = (arg) => `${arg.argumentText}: ${arg.valueText} (${arg.percentText})`;

  const customizeTooltip = (arg) => {
    return { text: `${arg.argumentText}<br />${arg.valueText}` };
  };

  useEffect(() => {
    rapidoService.result("vw_dashboard_total_counting").then((result) => {
      setDashboardTotalCounting(result[0].sayim_sayisi);
    });
    rapidoService.result("vw_dashboard_device_count").then((result) => {
      setDashboardDeviceCount(result[0].cihaz_sayisi);
    });
    rapidoService.result("vw_dashboard_box_count_by_order").then((result) => {
      setDashboardBoxCountByOrder(result);
    });
    rapidoService.result("vw_dashboard_box_count_by_department").then((result) => {
      const totalPieces = result.map((val) => val.toplam_urun_sayisi);
      setDashboardTotalBoxCount(totalPieces.reduce((count, acc) => count + acc, 0));
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}>
              <Card sx={{ height: "100%", borderRadius: 2 }}>
                <SuiBox bgColor={light.main}>
                  <SuiBox mb={1} color={light.main} bgColor={dark.main} pt={2.25} pb={2.25} pl={3}>
                    <SuiTypography color={"light"} variant="h6">
                      {t("{{key, capitalize}}", {
                        key: "total.count.by.brand",
                      })}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox>
                    <PieChart id="pie" dataSource={readerDataSource} palette="Bright">
                      {/* <Title
                        text={
                          t("{{key, capitalize}}", {
                            key: "total.box.count",
                          }) +
                          " : " +
                          sum
                        }
                        horizontalAlignment={"center"}
                        verticalAlignment={"bottom"}
                        color="#000000"
                      /> */}
                      <CommonSeriesSettings argumentField="marka" />
                      <Series
                        valueField="toplam_urun_sayisi"
                        argumentField="marka"
                        hoverMode="onlyPoint"
                        selectionMode="onlyPoint"
                      >
                        <Label visible={true} position="outside" customizeText={customizeText}>
                          <Connector visible={true} width={1} />
                        </Label>
                      </Series>
                      <Export enabled={false} />
                      <Tooltip
                        enabled={true}
                        location="edge"
                        cornerRadius={2}
                        customizeTooltip={customizeTooltip}
                      />
                      <Legend
                        hoverMode="excludePoints"
                        visible={true}
                        verticalAlignment={"top"}
                        horizontalAlignment={"right"}
                        // rowItemSpacing={10}
                      />
                    </PieChart>
                  </SuiBox>
                </SuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card sx={{ height: "100%", borderRadius: 2 }}>
                <SuiBox bgColor={light.main}>
                  <SuiBox color={light.main} bgColor={dark.main} pt={2.25} pb={2.25} pl={3}>
                    <SuiTypography color={"light"} variant="h6">
                      {t("{{key, capitalize}}", {
                        key: "box.count.by.order",
                      })}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox>
                    <DataGrid
                      dataSource={dashboardBoxCountByOrder}
                      showBorders={true}
                      rowAlternationEnabled={true}
                      repaintChangesOnly={true}
                      // hoverStateEnabled={true}
                    >
                      <Column
                        dataField={"po"}
                        alignment={"left"}
                        caption={t("{{key, capitalize}}", {
                          key: "po.number",
                        })}
                      />
                      <Column
                        sortingMethod={(a, b) => a - b}
                        dataField={"toplam_urun_sayisi"}
                        alignment={"center"}
                        caption={t("{{key, capitalize}}", {
                          key: "counted.pieces",
                        })}
                      />
                      <Column
                        sortingMethod={(a, b) => a - b}
                        dataField={"kutu_sayisi"}
                        alignment={"right"}
                        caption={t("{{key, capitalize}}", {
                          key: "box.count",
                        })}
                      />
                      <Scrolling rowRenderingMode={"virtual"} />
                      <Paging defaultPageSize={7} />
                      <Pager visible={false} />
                    </DataGrid>
                  </SuiBox>
                </SuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <DefaultCounterCard
                countDevice={dashboardDeviceCount}
                totalBoxCount={dashboardTotalBoxCount}
                countTotal={dashboardTotalCounting}
                titleCountDevice={t("{{key, capitalize}}", {
                  key: "device.count",
                })}
                titleTotalBoxCount={t("{{key, capitalize}}", {
                  key: "total.box.count",
                })}
                titleCountTotal={t("{{key, capitalize}}", {
                  key: "total.count",
                })}
                // description={"Lorem ipsum"}
                title={t("{{key, capitalize}}", { key: "stats" })}
                // description={t("{{key, capitalize}}", {
                //   key: "device.count",
                // })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ControllerCard
                icon={rapidoScanIcon}
                title={t("{{key, capitalize}}", { key: "new.count" })}
                // description={t("{{key, capitalize}}", {
                //   key: "dashboard.match.explanation",
                // })}
                to={"/inspection/new"}
                // description="Lorem ipsum dolor sit amet"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ControllerCard
                icon={rapidoFreeScanIcon}
                title={t("{{key, capitalize}}", { key: "free.count" })}
                // description={t("{{key, capitalize}}", {
                //   key: "dashboard.loan.return.explanation",
                // })}
                to={"/inspection/free"}
                // description="Lorem ipsum dolor sit amet"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ControllerCard
                icon={rapidoReportIcon}
                title={t("{{key, capitalize}}", {
                  key: "report",
                })}
                // description={t("{{key, capitalize}}", {
                //   key: "dashboard.controlpoint.explanation",
                // })}
                to={"/report-list"}
                // description="Lorem ipsum dolor sit amet"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ControllerCard
                icon={rapidoDocumentIcon}
                title={t("{{key, capitalize}}", { key: "document" })}
                // description={t("{{key, capitalize}}", {
                //   key: "dashboard.counting.explanation",
                // })}
                to={"/document-list"}
                // description="Lorem ipsum dolor sit amet"
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MainDashboardPage;
