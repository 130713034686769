import AuthProvider from "../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "epcTagRecord/";

function EpcTagService() {
  const findOne = async (id) => {
    const requestOptions = {
      method: "GET",
    };
    return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
  };

  const findAll = async (loadOptions) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loadOptions),
    };
    return authProvider.authFetch(basePath + "findAll", requestOptions);
  };

  const save = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return authProvider.authFetch(basePath + "save", requestOptions);
  };

  const update = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return authProvider.authFetch(basePath + "update", requestOptions);
  };

  const deleteOne = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "delete", requestOptions);
  };

  const saveAllv1 = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "saveallv1", requestOptions);
  };
  const saveAllv2 = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "saveallv2", requestOptions);
  };
  const findAllByInditexTagRecordsId = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "epcTagInditexTagRecord", requestOptions);
  };

  const deleteAll = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    return authProvider.authFetch(basePath + "deleteAll", requestOptions);
  };

  const deleteInditexTagRecordsId = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "deleteInditexTagRecordsId", requestOptions);
  };

  return {
    findOne,
    findAll,
    save,
    update,
    deleteOne,
    saveAllv1,
    saveAllv2,
    findAllByInditexTagRecordsId,
    deleteAll,
    deleteInditexTagRecordsId,
  };
}

export default EpcTagService;
