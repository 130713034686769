import LoginPage from "./pages/share/authentication/login";
import DocumentPage from "./pages/share/document/manager";
import ReportListPage from "./pages/share/reports/list";
import MediaPage from "./pages/share/media";
import RealPersonPage from "./pages/share/person/real-person";
import LegalEntityPage from "./pages/share/person/legal-entity";
import UserPage from "./pages/share/users/user";
import RolePage from "./pages/share/users/role";
import DepartmentPage from "./pages/share/departments/department";
import DepartmentTypePage from "./pages/share/departments/departmentType";
import LocationPage from "./pages/share/locations/location";
import LocationTypePage from "./pages/share/locations/locationType";
import ReportManagerPage from "./pages/share/reports/manager";
import ReportDesignerPage from "./pages/share/reports/designer";
import DocumentListPage from "./pages/share/document/list";
import LangPage from "./pages/share/langs/lang";
import LangMessagePage from "./pages/share/langs/message";
import DevicePage from "./pages/app/device";
import VendorPage from "./pages/app/vendor";
import NewInspectionPage from "./pages/app/inspection/new";
import ProfilePage from "./pages/share/profile";
import FreeInspectionPage from "./pages/app/inspection/free";
import AnnouncementPage from "./pages/share/announcement";
import InditexSettingsPage from "./pages/app/inditexsettings";
import ProductTypesPage from "./pages/app/producttype";
import SectionPage from "./pages/app/section";
import TagSubTypePage from "./pages/app/tagsubtype";
import TagTypePage from "./pages/app/tagtype";
import InventoryTagsPage from "./pages/app/inventorytag";
import InditexTagRecordsPage from "./pages/app/inditextagrecord";
import GeneralSettingsPage from "./pages/share/generalsettings";
import TagActiveTypePage from "./pages/app/tagactivetype";
import MainDashboardPage from "pages/share/dashboards/main-dashboard";
import TempeTagRecordsPage from "./pages/app/tempetagrecord";
import DeviceUserPage from "./pages/app/deviceuser";
import InspectionRecordsPage from "./pages/app/inspection/records";
import GlobalInspectionRecordsPage from "./pages/app/inspection/globalrecords";
import RfKeeperOrderGroupPage from "./pages/app/rfkeeper";
import RfKeeperTypesPage from "./pages/app/rfkeeper/type";
import RfKeeperIndexPage from "./pages/app/rfkeeper/indexname";
import RfKeeperCsvIndexPage from "./pages/app/rfkeeper/csvindex";
import LogColumnPage from "./pages/app/devicelog/logcolumn";
import LogCsvIndexPage from "./pages/app/devicelog/logcsvindex";
import LogDevicePage from "./pages/app/devicelog/logdevice";
import LogTagRecords from "./pages/app/logtagrecord";
import TempeWithoutPoRecordsPage from "./pages/app/tempetagrecord";
import TempeWithoutPoPage from "./pages/app/tempewithoutporecord";
import TempeEpcDecodePage from "./pages/app/tempeepcdecode";
import RfKeeperTokenPage from "./pages/app/rfkeeper/token";
import NewGlobalInspectionPage from "./pages/app/globalInspection/new";
import CompanyPrefixPage from "./pages/app/companyprefix";
import SgtinCsvIndexPage from "./pages/app/sgtin/csvindex";
import SgtinIndexPage from "./pages/app/sgtin/indexname";
import SgtinOrderGroupPage from "./pages/app/sgtin";
import SgtinTypesPage from "./pages/app/sgtin/type";
import DepartmentSettingsPage from "./pages/app/departmentsettings";

const routes = [
  {
    type: "collapse",
    name: "dashboard",
    key: "dashboards",
    icon: "dashboard",
    authorities: ["dashboardFindAll"],
    collapse: [
      {
        name: "main.dashboard",
        key: "main.dashboard",
        route: "/dashboards/main-dashboard",
        authorities: ["dashboardFindAll"],
        component: <MainDashboardPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "inspection",
    key: "inspection",
    icon: "control_point_duplicate",
    authorities: ["inspecitonSave"],
    collapse: [
      {
        name: "new",
        key: "new",
        route: "/inspection/new",
        authorities: ["inspecitonSave"],
        component: <NewInspectionPage />,
      },
      {
        name: "free",
        key: "free",
        route: "/inspection/free",
        authorities: ["inspecitonSave"],
        component: <FreeInspectionPage />,
      },
      {
        name: "inditexrecords",
        key: "inditexrecords",
        route: "/inspection/records",
        authorities: ["inspecitonDetailFindAll", "inspecitonFindAll"],
        component: <InspectionRecordsPage />,
      },
      {
        name: "ginarecords",
        key: "ginarecords",
        route: "/inspection/globalrecords",
        authorities: ["inspecitonDetailFindAll", "inspecitonFindAll"],
        component: <GlobalInspectionRecordsPage />,
      },
      // {
      //   name: "SGTIN",
      //   key: "sgtin",
      //   route: "/inspection/sgtin",
      //   authorities: ["globalInspecitonDetailFindAll", "globalInspecitonFindAll"],
      //   component: <NewGlobalInspectionPage />,
      // },
    ],
  },
  {
    type: "link",
    name: "inditexTagRecord",
    key: "inditex",
    icon: "assessment",
    route: "inditex",
    authorities: ["inditexTagRecordsFindAll"],
    noCollapse: true,
    component: <InditexTagRecordsPage />,
  },
  {
    type: "collapse",
    name: "tempe",
    key: "tempe",
    icon: "assessment",
    authorities: ["tempeValidationSave", "tempeSizeRequestSave", "tempeWithoutPoSave"],
    collapse: [
      {
        name: "tagwithpo",
        key: "tagwithpo",
        route: "/tempe/tagwithpo",
        authorities: ["tempeValidationSave", "tempeSizeRequestSave"],
        component: <TempeTagRecordsPage />,
      },
      {
        name: "tagwithoutpo",
        key: "tagwithoutpo",
        route: "/tempe/tagwithoutpo",
        authorities: ["tempeWithoutPoSave"],
        component: <TempeWithoutPoPage />,
      },
      {
        name: "logupload",
        key: "log",
        route: "/tagRecord/log",
        authorities: ["logDeviceFindAll"],
        component: <LogTagRecords />,
      },
      {
        name: "epcdecode",
        key: "epcdecode",
        route: "/tempe/epcdecode",
        authorities: ["tempeEpcDecode"],
        component: <TempeEpcDecodePage />,
      },
    ],
  },
  {
    type: "link",
    name: "castro",
    key: "castro",
    icon: "assessment",
    route: "/castro",
    authorities: ["rfKeeperOrderGroupFindAll"],
    noCollapse: true,
    component: <RfKeeperOrderGroupPage />,
  },
  {
    type: "link",
    name: "sgtin",
    key: "sgtin",
    icon: "assessment",
    route: "/sgtin",
    authorities: ["sgtinOrderGroupFindAll"],
    noCollapse: true,
    component: <SgtinOrderGroupPage />,
  },
  {
    type: "link",
    name: "report",
    key: "report-list",
    icon: "assessment",
    route: "/report-list",
    authorities: ["reportFindAll"],
    noCollapse: true,
    component: <ReportListPage />,
  },
  {
    type: "link",
    name: "document",
    key: "document-list",
    icon: "filter_none",
    route: "/document-list",
    authorities: ["documentFindAll"],
    noCollapse: true,
    component: <DocumentListPage />,
  },
  {
    type: "link",
    name: "file",
    key: "media",
    icon: "file_copy",
    route: "/media",
    authorities: ["mediaGetItems"],
    noCollapse: true,
    component: <MediaPage />,
  },
  {
    type: "link",
    name: "announcement",
    key: "announcement",
    icon: "campaign';",
    route: "/announcement",
    authorities: ["announcementSave"],
    noCollapse: true,
    component: <AnnouncementPage />,
  },
  {
    type: "link",
    name: "deviceuser",
    key: "deviceuser",
    icon: "devices",
    route: "/deviceuser",
    authorities: ["deviceTxUpdate"],
    noCollapse: true,
    component: <DeviceUserPage />,
  },
  {
    type: "link",
    name: "departmentSettings",
    key: "departmentSettings",
    icon: "filter_none",
    route: "/departmentSettings",
    authorities: ["departmentSettingsSave"],
    noCollapse: true,
    component: <DepartmentSettingsPage />,
  },
  {
    type: "title",
    title: "manager",
    key: "title-pages",
    authorities: [
      "personSave",
      "roleSave",
      "userSave",
      "locationSave",
      "locationTypeSave",
      "departmentSave",
      "departmentTypeSave",
      "reportSave",
      "mediaGetItems",
      "deviceSave",
    ],
  },
  {
    type: "collapse",
    name: "definition",
    key: "definition",
    icon: "settings",
    authorities: ["inditexSettingsFindAll"],
    collapse: [
      {
        name: "inditexSettings",
        key: "inditexSettings",
        route: "/definition/inditexSettings",
        authorities: ["inditexSettingsFindAll"],
        component: <InditexSettingsPage />,
      },
      {
        name: "section",
        key: "section",
        route: "/definition/section",
        authorities: ["sectionFindAll"],
        component: <SectionPage />,
      },
      {
        name: "productTypes",
        key: "productTypes",
        route: "/definition/productTypes",
        authorities: ["productTypesFindAll"],
        component: <ProductTypesPage />,
      },
      {
        name: "tagActiveType",
        key: "tagActiveType",
        route: "/definition/tagActiveType",
        authorities: ["tagActiveTypeFindAll"],
        component: <TagActiveTypePage />,
      },
      // {
      //   name: "productSize",
      //   key: "productSize",
      //   route: "/definition/productSize",
      //   authorities: ["productSizeSave"],
      //   component: <ProductSizePage />,
      // },
      {
        name: "inventoryTagType",
        key: "inventoryTagType",
        route: "/definition/inventoryTagType",
        authorities: ["inventoryTagTypeFindAll"],
        component: <InventoryTagsPage />,
      },
      {
        name: "tagType",
        key: "tagType",
        route: "/definition/tagType",
        authorities: ["tagTypeFindAll"],
        component: <TagTypePage />,
      },
      {
        name: "tagSubType",
        key: "tagSubType",
        route: "/definition/tagSubType",
        authorities: ["tagSubTypeFindAll"],
        component: <TagSubTypePage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "sgtin-setting",
    key: "sgtin-setting",
    icon: "settings",
    authorities: ["sgtinTypeFindAll", "sgtinIndexFindAll", "sgtinCsvIndexFindAll"],
    collapse: [
      {
        name: "sgtin-type",
        key: "sgtin-type",
        route: "/sgtin-setting/sgtin-type",
        authorities: ["sgtinTypeFindAll"],
        component: <SgtinTypesPage />,
      },
      {
        name: "indexname",
        key: "indexname",
        route: "/sgtin-setting/indexname",
        authorities: ["sgtinIndexFindAll"],
        component: <SgtinIndexPage />,
      },
      {
        name: "csv-indexname",
        key: "csv-indexname",
        route: "/sgtin-setting/csv-indexname",
        authorities: ["sgtinCsvIndexFindAll"],
        component: <SgtinCsvIndexPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "rfkeeper",
    key: "rfkeeper",
    icon: "settings",
    authorities: [
      "rfKeeperTypeFindAll",
      "rfKeeperIndexFindAll",
      "rfKeeperCsvIndexFindAll",
      "rfKeeperTokenFindAll",
      "rfKeeperTokenSave",
      "rfKeeperTokenUpdate",
      "rfKeeperTokenFindOne",
    ],
    collapse: [
      {
        name: "rfkeeper-type",
        key: "rfkeeper-type",
        route: "/rfkeeper/rfkeeper-type",
        authorities: ["rfKeeperTypeFindAll"],
        component: <RfKeeperTypesPage />,
      },
      {
        name: "rfkeeper-indexname",
        key: "rfkeeper-indexname",
        route: "/rfkeeper/rfkeeper-indexname",
        authorities: ["rfKeeperIndexFindAll"],
        component: <RfKeeperIndexPage />,
      },
      {
        name: "rfkeeper-csv-indexname",
        key: "rfkeeper-csv-indexname",
        route: "/rfkeeper/rfkeeper-csv-indexname",
        authorities: ["rfKeeperCsvIndexFindAll"],
        component: <RfKeeperCsvIndexPage />,
      },
      {
        name: "rfkeeper-token",
        key: "rfkeeper-token",
        route: "/rfkeeper/rfkeeper-token",
        authorities: [
          "rfKeeperTokenFindAll",
          "rfKeeperTokenSave",
          "rfKeeperTokenUpdate",
          "rfKeeperTokenFindOne",
        ],
        component: <RfKeeperTokenPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "logdevicesettings",
    key: "logdevicesettings",
    icon: "settings",
    authorities: ["logColumnFindAll", "logCsvIndexFindAll", "logDeviceFindAll"],
    collapse: [
      {
        type: "link",
        name: "logDevice",
        key: "logDevice",
        icon: "filter_none",
        route: "/logdevicesettings/logDevice",
        authorities: ["logDeviceFindAll"],
        noCollapse: true,
        component: <LogDevicePage />,
      },
      {
        type: "link",
        name: "logColumn",
        key: "logColumn",
        icon: "filter_none",
        route: "/logdevicesettings/logColumn",
        authorities: ["logColumnFindAll"],
        noCollapse: true,
        component: <LogColumnPage />,
      },
      {
        type: "link",
        name: "logCsvIndex",
        key: "logCsvIndex",
        icon: "filter_none",
        route: "/logdevicesettings/logCsvIndex",
        authorities: ["logCsvIndexFindAll"],
        noCollapse: true,
        component: <LogCsvIndexPage />,
      },
    ],
  },
  {
    type: "link",
    name: "device",
    key: "device",
    icon: "devices",
    route: "/device",
    authorities: ["deviceSave"],
    noCollapse: true,
    component: <DevicePage />,
  },
  {
    type: "link",
    name: "brand",
    key: "brand",
    icon: "store",
    route: "/brand",
    authorities: ["vendorFindAll"],
    noCollapse: true,
    component: <VendorPage />,
  },
  {
    type: "link",
    name: "companyPrefix",
    key: "companyPrefix",
    icon: "store",
    route: "/companyPrefix",
    authorities: ["companyPrefixFindAll"],
    noCollapse: true,
    component: <CompanyPrefixPage />,
  },

  {
    type: "link",
    name: "profile",
    key: "profile",
    route: "/profile",
    authorities: ["none"],
    noCollapse: true,
    component: <ProfilePage />,
  },

  {
    type: "link",
    name: "document",
    key: "document",
    icon: "filter_none",
    route: "/document",
    authorities: ["documentSave"],
    noCollapse: true,
    component: <DocumentPage />,
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: "user",
    authorities: ["none"],
    collapse: [
      {
        name: "Basic",
        key: "basic",
        route: "/authentication/login",
        authorities: ["none"],
        component: <LoginPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "person",
    key: "person",
    icon: "person",
    authorities: ["personSave"],
    collapse: [
      {
        name: "realPerson",
        key: "real-person",
        route: "/person/real-person",
        authorities: ["personSave"],
        component: <RealPersonPage />,
      },
      {
        name: "legalEntity",
        key: "legal-entity",
        route: "/person/legal-entity",
        authorities: ["personSave"],
        component: <LegalEntityPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "user",
    key: "users",
    icon: "verified_user",
    authorities: ["roleSave", "userSave"],
    collapse: [
      {
        name: "user",
        key: "user",
        route: "/users/user",
        authorities: ["userSave"],
        component: <UserPage />,
      },
      {
        name: "role",
        key: "role",
        route: "/users/role",
        authorities: ["roleSave"],
        component: <RolePage />,
      },
    ],
  },
  // { type: "divider", key: "divider-1", authorities: [] },
  {
    type: "collapse",
    name: "department",
    key: "department",
    icon: "widgets",
    authorities: ["departmentSave", "departmentTypeSave"],
    collapse: [
      {
        name: "department",
        key: "department",
        route: "/department/department",
        authorities: ["departmentSave"],
        component: <DepartmentPage />,
      },
      {
        name: "type",
        key: "department-type",
        route: "/department/department-type",
        authorities: ["departmentTypeSave"],
        component: <DepartmentTypePage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "location",
    key: "location",
    icon: "edit_location",
    authorities: ["locationSave", "locationTypeSave"],
    collapse: [
      {
        name: "location",
        key: "location",
        route: "/location/location",
        authorities: ["locationSave"],
        component: <LocationPage />,
      },
      {
        name: "type",
        key: "location-type",
        route: "/location/location-type",
        authorities: ["locationTypeSave"],
        component: <LocationTypePage />,
      },
    ],
  },

  {
    type: "collapse",
    name: "report",
    key: "report",
    icon: "assessment",
    authorities: ["reportSave"],
    collapse: [
      {
        name: "manager",
        key: "manager",
        route: "/report/manager",
        authorities: ["reportSave"],
        component: <ReportManagerPage />,
      },
      {
        name: "design",
        key: "design",
        route: "/report/design",
        authorities: ["reportSave"],
        component: <ReportDesignerPage />,
      },
    ],
  },

  {
    type: "collapse",
    name: "language",
    key: "languages",
    icon: "language",
    authorities: ["languagesSave"],
    collapse: [
      {
        name: "language",
        key: "lang",
        route: "/languages/lang",
        authorities: ["languagesSave"],
        component: <LangPage />,
      },
      {
        name: "message",
        key: "lang-message",
        route: "/languages/lang-message",
        authorities: ["languageMessagesSave"],
        component: <LangMessagePage />,
      },
    ],
  },
  {
    type: "link",
    name: "generalSettings",
    key: "general-settings",
    icon: "settings",
    route: "/general-settings",
    authorities: ["generalSettingsSave"],
    noCollapse: true,
    component: <GeneralSettingsPage />,
  },
];

export default routes;
