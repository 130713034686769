import { useEffect, useState } from "react";
import DropDownBox, { DropDownOptions } from "devextreme-react/drop-down-box";
import CustomStore from "devextreme/data/custom_store";
import LocationService from "../location/LocationService";
import ScrollView from "devextreme-react/scroll-view";
import TreeList, {
  Column,
  FilterRow,
  LoadPanel,
  RemoteOperations,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/tree-list";
import DataSource from "devextreme/data/data_source";
import { mergeEquallyLabeledTypes } from "../../../../providers/UtilsProvider";
import { useTranslation } from "react-i18next";

const locationService = LocationService();

const locationDataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return locationService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return locationService.findOne(key).then((response) => {
        response.name = mergeEquallyLabeledTypes(response, []);
        return response;
      });
    },
  }),
});

function LocationTreeList({ value, onValueChanged }) {
  useEffect(() => {}, []);
  const [treeBoxOpened, setTreeBoxOpened] = useState(false);
  const [itemValue, setItemValued] = useState(value);
  const [treeList, setTreeList] = useState();
  const [selected, setSelected] = useState(false);
  const { t } = useTranslation();
  const treeViewRender = () => {
    return (
      <ScrollView width="100%" height="100%">
        <TreeList
          dataSource={locationDataSource}
          keyExpr="id"
          parentIdExpr="parent.id"
          hasItemsExpr="hasItems"
          rowAlternationEnabled={true}
          rootValue={null}
          filterMode="fullBranch"
          ref={(ref) => {
            if (ref) {
              setTreeList(ref.instance);
              if (itemValue) {
                ref.instance.filter(["id", "=", itemValue]);
              } else {
                ref.instance.clearFilter();
              }
            }
          }}
          onSelectionChanged={(e) => {
            setTreeBoxOpened(false);
            setItemValued(e.selectedRowsData[0].id);
            onValueChanged(e.selectedRowsData[0]);
            setSelected(true);
          }}
          onRowClick={() => {
            if (selected) {
              setTreeBoxOpened(false);
              setSelected(true);
            } else {
            }
          }}
          onRowExpanding={() => {
            setSelected(false);
          }}
        >
          <RemoteOperations filtering={true} sorting={true} />
          <SearchPanel visible={true} width={250} />
          <Selection mode="single" />
          <FilterRow visible={true} />
          <LoadPanel enabled={true} />
          <Scrolling mode="standard" />
          <Column
            sortOrder="asc"
            dataField="code"
            caption={t("{{key, capitalize}}", { key: "code" })}
            width={200}
          />
          <Column dataField="name" caption={t("{{key, capitalize}}", { key: "name" })} />
        </TreeList>
      </ScrollView>
    );
  };

  return (
    <DropDownBox
      value={itemValue}
      opened={treeBoxOpened}
      valueExpr="id"
      displayExpr="name"
      placeholder={t("{{key, capitalize}}", { key: "placeholder.location" })}
      showClearButton={true}
      dataSource={locationDataSource}
      onValueChanged={(e) => {
        if (e.value == null) {
          setItemValued(null);
          onValueChanged(null);
          if (treeList) {
            treeList.clearFilter();
          }
        }
      }}
      contentRender={treeViewRender}
      onOptionChanged={(e) => {
        if (e.name === "opened") {
          setTreeBoxOpened(e.value);
        }
      }}
    >
      <DropDownOptions
        minHeight="300px"
        fullScreen={false}
        visible={true}
        showCloseButton={true}
        showTitle={true}
        // onShown={(e) => {
        //     e.component.content().style.height = 'auto';
        // }}
      />
    </DropDownBox>
  );
}

export default LocationTreeList;
