import AuthProvider from "../../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "report/";
function ReportManagerService() {
  const findOne = async (id) => {
    const requestOptions = {
      method: "GET",
    };
    return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
  };

  const findAll = async (loadOptions) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loadOptions),
    };
    return authProvider.authFetch(basePath + "findAll", requestOptions);
  };

  const save = async (data) => {
    const file = data.image;
    const formData: FormData = new FormData();
    const blobData = new Blob([JSON.stringify(data)], { type: "application/json" });
    formData.append("data", blobData);
    if (file && file.name) {
      formData.append("file", file, file.name);
    }
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json'},
    //     body: JSON.stringify(data)
    // };
    return authProvider.authFetch(basePath + "save", requestOptions);
  };

  const update = (data) => {
    const file = data.image;
    const formData: FormData = new FormData();
    const blobData = new Blob([JSON.stringify(data)], { type: "application/json" });
    formData.append("data", blobData);
    if (file && file.name) {
      formData.append("file", file, file.name);
    }
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json'},
    //     body: JSON.stringify(data)
    // };
    return authProvider.authFetch(basePath + "update", requestOptions);
  };

  const deleteOne = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "delete", requestOptions);
  };

  const findAllReports = async () => {
    const token = await authProvider.getToken();
    const formData: FormData = new FormData();
    formData.append("actionKey", "getUrls");
    formData.append("arg", "true");
    const requestOptions = {
      method: "POST",
      headers: {
        ProjectUrl: process.env.REACT_APP_API_URL,
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    return fetch(process.env.REACT_APP_REPORT_URL + "DXXRD", requestOptions);
  };

  return {
    findOne,
    findAll,
    save,
    update,
    deleteOne,
    findAllReports,
  };
}

export default ReportManagerService;
