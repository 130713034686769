import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Popup,
  Lookup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import LogCsvIndexService from "./LogCsvIndexService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import LogDeviceService from "../logdevice/LogDeviceService";
import LogColumnService from "../logcolumn/LogColumnService";

const authProvider = AuthProvider();
const logCsvIndexService = LogCsvIndexService();
const logDeviceService = LogDeviceService();
const logColumnService = LogColumnService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return logCsvIndexService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return logCsvIndexService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.personType = "Person";
      return logCsvIndexService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return logCsvIndexService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return logCsvIndexService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

const deviceDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    return logDeviceService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return logDeviceService.findOne(key).then((response: any) => {
      return response;
    });
  },
});

const logColumnDataSource = new CustomStore({
  key: "id",
  load(loadOptions: any) {
    return logColumnService.findAll(loadOptions).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return logColumnService.findOne(key).then((response: any) => {
      return response;
    });
  },
});
function LogCsvIndexPage() {
  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("logCsvIndex") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("logCsvIndexUpdate")}
            allowAdding={authProvider.isAuth("logCsvIndexSave")}
            allowDeleting={authProvider.isAuth("logCsvIndexDelete")}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "log.field.device.information" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="logDevice.id" />
              <Item dataField="logColumn.id" />
              <Item dataField="indexNumber" colSpan={2} />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption={t("{{key, capitalize}}", { key: "logDevice.name" })}
            dataField="logDevice.id"
          >
            <Lookup dataSource={deviceDataSource} displayExpr="name" valueExpr="id" />
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "logColumn.name" })}
            dataField="logColumn.id"
          >
            <Lookup dataSource={logColumnDataSource} displayExpr="name" valueExpr="id" />
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "indexnumber" })}
            dataField="indexNumber"
            dataType="string"
          >
            <RequiredRule />
          </Column>
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LogCsvIndexPage;
