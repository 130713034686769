import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import React, {useState} from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import {useTranslation} from "react-i18next";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import SuiBox from "../../../../components/SuiBox";
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    FilterRow,
    Form,
    Item,
    LoadPanel,
    Lookup,
    Pager,
    Paging,
    Popup,
    RequiredRule,
    SearchPanel,
    Toolbar
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Footer from "../../../../examples/Footer";
import AuthProvider from "../../../../providers/AuthProvider";
import RfKeeperTokenService from "./RfKeeperTokenService";
import RfKeeperTypeService from "../type/RfKeeperTypeService";
import DepartmentService from "../../../share/departments/department/DepartmentService";


const authProvider = AuthProvider();
const rfKeeperTokenService = RfKeeperTokenService();
const rfKeeperTypeService = RfKeeperTypeService();
const departmentService = DepartmentService();

const rfKeeperTokenSource = new DataSource({
    store: new CustomStore({
        key: "id", load(loadOptions: any) {
            return rfKeeperTokenService.findAll(loadOptions).then((response) => ({
                data: response.items, totalCount: response.totalCount,
            }), (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            });
        }, byKey: (key: any) => {
            return rfKeeperTokenService.findOne(key).then((response) => {
                return response;
            });
        }, insert: (values: any) => {
            return rfKeeperTokenService.save(values).then(() => {
            }, (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            });
        }, update: (key: any, values: any) => {
            values.id = key;
            return rfKeeperTokenService.update(values).then(() => {
            }, (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            });
        }, remove: (key: any) => {
            return rfKeeperTokenService.deleteOne(key).then(() => {
            }, (err) => {
                return err.json().then((r) => {
                    throw r.errorMessage ? r.errorMessage : r.warningMessage;
                });
            });
        },
    }),
});
const rfKeeperTypeSource = new CustomStore({
    key: "id", load(loadOptions: any) {
        return rfKeeperTypeService.findAll(loadOptions).then((response) => ({
            data: response.items, totalCount: response.totalCount,
        }), (err) => {
            return err.json().then((r) => {
                throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
        });
    }, byKey: (key: any) => {
        return rfKeeperTypeService.findOne(key).then((response: any) => {
            return response;
        });
    },
});
const departmentSource = new CustomStore({
    key: "id", load(loadOptions: any) {
        return departmentService.findAll(loadOptions).then((response) => ({
            data: response.items, totalCount: response.totalCount,
        }), (err) => {
            return err.json().then((r) => {
                throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
        });
    }, byKey: (key: any) => {
        return departmentService.findOne(key).then((response: any) => {
            return response;
        });
    },
});

function RfKeeperTokenPage() {
    const {t} = useTranslation();
    const [dataGrid, setDataGrid] = useState();

    const onGridInsertedUpdated = (d, val) => {
        if (!d.value) {
            d.setValue([val.data]);
        } else {
            d.setValue(d.value);
        }
    };
    const editGridDepartmentRfKeeperTypesCell = (d) => {
        return (<DataGrid
            dataSource={d.value ? d.value : []}
            allowSearch={true}
            allowFiltering={true}
            allowSorting={true}
            onRowInserted={(val) => {
                onGridInsertedUpdated(d, val);
            }}
            onRowUpdated={(val) => {
                onGridInsertedUpdated(d, val);
            }}
            onRowRemoved={(val) => {
                onGridInsertedUpdated(d, val);
            }}
        >
            <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}/>
            <Paging defaultPageSize={10}/>
            <SearchPanel visible={true} highlightCaseSensitive={true} width={240}/>
            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]}/>
            <Column caption={t("{{key, capitalize}}", {key: "rfKeeperType"})} dataField="rfKeeperType">
                <Lookup dataSource={rfKeeperTypeSource} displayExpr="indexName" valueExpr="id"/>
                <RequiredRule/>
            </Column>
        </DataGrid>)

    };
    return (<DashboardLayout>
        <DashboardNavbar
            title={t("section") + " " + t("lists")}
            icon="filter_none"
            fontSize="medium"
        />
        <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{borderRadius: 2}}>
            <DataGrid
                dataSource={rfKeeperTokenSource}
                showBorders={false}
                remoteOperations={true}
                rowAlternationEnabled={true}
                ref={(ref) => {
                    setDataGrid(ref);
                }}
            >
                <FilterRow visible={true}/>
                <ColumnChooser enabled={false}/>
                <LoadPanel enabled={true}/>
                <Toolbar>
                    <Item department="after">
                        <Button
                            icon="refresh"
                            onClick={() => {
                                dataGrid.instance.refresh();
                            }}
                        />
                    </Item>
                    <Item name="addRowButton"/>
                    <Item name="searchPanel"/>
                    <Item name="columnChooserButton"/>
                </Toolbar>
                <SearchPanel visible={true} highlightCaseSensitive={true} width={240}/>

                <Editing
                    mode="popup"
                    allowUpdating={authProvider.isAuth("rfKeeperTokenUpdate")}
                    allowAdding={authProvider.isAuth("rfKeeperTokenSave")}
                    allowDeleting={authProvider.isAuth("rfKeeperTokenDelete")}
                >
                    <Popup title={t("{{key, capitalize}}", {key: "rf.keeper.token.title"})} showTitle={true}/>
                    <Form>
                        <Item dataField="rfKeeperType.id"/>
                        <Item dataField="department.id"/>
                        <Item dataField="customerId" colSpan={2}/>
                        <Item dataField="brandId" colSpan={2}/>
                        <Item dataField="username" colSpan={2}/>
                        <Item dataField="token" colSpan={2}/>

                    </Form>
                </Editing>
                <Paging defaultPageSize={10}/>
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]}/>

                <Column
                    caption={t("{{key, capitalize}}", {key: "customer.id"})}
                    dataField="customerId"
                    dataType="string"
                >
                    <RequiredRule/>
                </Column>
                <Column
                    caption={t("{{key, capitalize}}", {key: "brand.id"})}
                    dataField="brandId"
                    dataType="string"
                >
                    <RequiredRule/>
                </Column>
                <Column
                    caption={t("{{key, capitalize}}", {key: "username"})}
                    dataField="username"
                    dataType="string"
                >
                    <RequiredRule/>
                </Column>

                <Column
                    caption={t("{{key, capitalize}}", {key: "token"})}
                    dataField="token"
                    dataType="string"
                >
                    <RequiredRule/>
                </Column>
                <Column caption={t("{{key, capitalize}}", {key: "rfkeeper.type.name"})} dataField="rfKeeperType.id">
                    <Lookup dataSource={rfKeeperTypeSource} displayExpr="indexName" valueExpr="id"/>
                    <RequiredRule/>
                </Column>
                <Column caption={t("{{key, capitalize}}", {key: "department"})} dataField="department.id">
                    <Lookup dataSource={departmentSource} displayExpr="name" valueExpr="id"/>
                    <RequiredRule/>
                </Column>

            </DataGrid>
        </SuiBox>
        <Footer/>
    </DashboardLayout>)
}

export default RfKeeperTokenPage;
