import AuthProvider from "../../../providers/AuthProvider";
import notify from "devextreme/ui/notify";
import { getLangLocalStorage } from "../../../providers/LanguageProvider";

const authProvider = AuthProvider();
const basePath = "rfKeeperOrder/";

function RfKeeperOrderService() {
  const findOne = async (id) => {
    const requestOptions = {
      method: "GET",
    };
    return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
  };

  const findAll = async (loadOptions) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loadOptions),
    };
    return authProvider.authFetch(basePath + "findAll", requestOptions);
  };

  const save = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return authProvider.authFetch(basePath + "save", requestOptions);
  };
  const createAllEPC = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return authProvider.authFetch(basePath + "createAllEPC", requestOptions);
  };

  const update = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return authProvider.authFetch(basePath + "update", requestOptions);
  };

  const deleteOne = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "delete", requestOptions);
  };

  const downloadAllEpc = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    const token = await AuthProvider().getToken();
    const language = await getLangLocalStorage();
    if (language) {
      requestOptions.headers = {
        ...requestOptions.headers,
        "Accept-Language": `${language}`,
      };
    }
    if (token) {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    let input = process.env.REACT_APP_API_URL + basePath + "downloadAllEpc";

    return fetch(input, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          // const isJson = response.headers.get('content-type')?.includes('application/json');
          // console.log('isJson', isJson)
          return response.blob();
        } else if (response.status === 400) {
          throw response;
        } else if (response.status === 401) {
          return Promise.reject(new Response(JSON.stringify({ errorMessage: "Yetkiniz yok" })));
        } else {
          return Promise.reject(
            new Response(JSON.stringify({ errorMessage: "Bilinmeyen Hata oluştu" }))
          );
        }
      })
      .catch((er) => {
        if (er instanceof SyntaxError) {
          return Promise.resolve();
        }

        er.clone()
          .json()
          .then((err) => {
            // return showErrorMessage(err.errorMessage ? err.errorMessage : err.warningMessage);
            notify({
              message: err.errorMessage ? err.errorMessage : err.warningMessage,
              type: "error",
              displayTime: 3000,
              height: 50,
              shading: false,
            });
          });
        return Promise.reject(er);
      });

    // return authProvider.authFetch(basePath + "downloadAllEpc", requestOptions);
  };

  const downloadAllEpcExcel = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    const token = await AuthProvider().getToken();
    const language = await getLangLocalStorage();
    if (language) {
      requestOptions.headers = {
        ...requestOptions.headers,
        "Accept-Language": `${language}`,
      };
    }
    if (token) {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    let input = process.env.REACT_APP_API_URL + basePath + "downloadAllEpcExcel";

    return fetch(input, requestOptions)
      .then(async (response) => {
        if (response.ok) {
          // const isJson = response.headers.get('content-type')?.includes('application/json');
          // console.log('isJson', isJson)
          return response.blob();
        } else if (response.status === 400) {
          throw response;
        } else if (response.status === 401) {
          return Promise.reject(new Response(JSON.stringify({ errorMessage: "Yetkiniz yok" })));
        } else {
          return Promise.reject(
            new Response(JSON.stringify({ errorMessage: "Bilinmeyen Hata oluştu" }))
          );
        }
      })
      .catch((er) => {
        if (er instanceof SyntaxError) {
          return Promise.resolve();
        }

        er.clone()
          .json()
          .then((err) => {
            // return showErrorMessage(err.errorMessage ? err.errorMessage : err.warningMessage);
            notify({
              message: err.errorMessage ? err.errorMessage : err.warningMessage,
              type: "error",
              displayTime: 3000,
              height: 50,
              shading: false,
            });
          });
        return Promise.reject(er);
      });

    // return authProvider.authFetch(basePath + "downloadAllEpc", requestOptions);
  };

  const changeExtraRate = async (selectedRow, extraRate) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: selectedRow.id, extraRate: extraRate }),
    };
    return authProvider.authFetch(basePath + "changeExtra", requestOptions);
  };

  const saveAll = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "saveAll", requestOptions);
  };

  return {
    findOne,
    findAll,
    save,
    update,
    deleteOne,
    createAllEPC,
    changeExtraRate,
    downloadAllEpc,
    downloadAllEpcExcel,
    saveAll,
  };
}

export default RfKeeperOrderService;
