import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Popup,
  Lookup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import InventoryTagService from "./InventoryTagService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { EActiveType } from "../../../providers/ConstantProvider";

const authProvider = AuthProvider();
const inventoryTagService = InventoryTagService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return inventoryTagService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return inventoryTagService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return inventoryTagService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return inventoryTagService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return inventoryTagService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function InventoryTagsPage() {
  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("inventoryTag") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("inventoryTagTypeUpdate")}
            allowAdding={authProvider.isAuth("inventoryTagTypeSave")}
            allowDeleting={authProvider.isAuth("inventoryTagTypeDelete")}
          >
            <Popup title="EAS(Inventory Tag) Bilgileri" showTitle={true} />
            <Form>
              <Item dataField="version" />
              <Item dataField="code" />
              <Item dataField="description" />
              <Item dataField="view" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption={t("{{key, capitalize}}", { key: "version" })}
            dataField="version"
            dataType="number"
            width={150}
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "code" })}
            dataField="code"
            dataType="string"
            width={250}
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "description" })}
            dataField="description"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column caption={t("{{key, capitalize}}", { key: "view" })} dataField="view" width={200}>
            <Lookup
              dataSource={EActiveType}
              displayExpr={(row) => {
                return t("{{key, capitalize}}", {
                  key: row.label,
                });
              }}
              valueExpr="value"
            />
          </Column>
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InventoryTagsPage;
