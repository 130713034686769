import React, { useEffect, useState } from "react";
import DocumentService from "../DocumentService";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import Grid from "@mui/material/Grid";
import { CardActions, Icon } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import SuiButton from "../../../../components/SuiButton";
import { LoadPanel } from "devextreme-react";
import { useTranslation } from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const documentService = DocumentService();

function DocumentListPage() {
  const { t } = useTranslation();

  useEffect(() => {}, []);
  const [showLoadPanel, setShowLoadPanel] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    documentService
      .findAll({})
      .then((response) => {
        setShowLoadPanel(false);
        setData(response.items);
      })
      .catch((err) => {
        setShowLoadPanel(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("document") + " " + t("lists")}
        icon="filter_none"
        fontSize={"medium"}
        onChangeSearch={(e) => {
          setShowLoadPanel(true);
          documentService
            .findAll(
              e
                ? {
                    filter: [["title", "contains", e], "or", ["description", "contains", e]],
                  }
                : {}
            )
            .then((response) => {
              setShowLoadPanel(false);
              setData(response.items);
            })
            .catch((err) => {
              setShowLoadPanel(false);
            });
        }}
      />
      <SuiBox p={2} shadow="none" bgColor="transparent">
        <Grid container mt={2} spacing={0} bgcolor="transparent">
          {data &&
            data.map((val) => {
              return (
                <Grid
                  component={Card}
                  item
                  key={val.id}
                  ml={1}
                  mb={1}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                >
                  <Card style={{ width: "100%" }}>
                    <CardMedia
                      // style={{
                      //     height: 200,
                      //     width: 'auto',
                      // }}
                      component="img"
                      height="160"
                      image={
                        process.env.REACT_APP_API_URL +
                        "binaryContents/getPublicImage/" +
                        val.thumbnailContentId
                      }
                      alt="thumbnail"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {val.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {val.description}
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {val.documentType === "Private" && (
                        <Icon style={{ marginRight: "auto" }}>lock</Icon>
                      )}
                      {val.link && (
                        <Link href={val.link} target="_blank" sx={{ paddingRight: "10px" }}>
                          <SuiButton variant="gradient" color="info" iconOnly circular>
                            <Icon>link</Icon>
                          </SuiButton>
                        </Link>
                      )}
                      {val.contentId && (
                        <SuiButton
                          variant="gradient"
                          color="warning"
                          iconOnly
                          circular
                          onClick={() => {
                            documentService.download(val.id).then((r) => {});
                          }}
                        >
                          <Icon>download</Icon>
                        </SuiButton>
                      )}
                      {/*<Button size="small">Share</Button>*/}
                      {/*<Button size="small">Learn More</Button>*/}
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </SuiBox>
      <Footer />

      <LoadPanel
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={true}
      />
    </DashboardLayout>
  );
}

export default DocumentListPage;
