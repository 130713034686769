import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Lookup,
  Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import SgtinIndexService from "./SgtinIndexService";

const authProvider = AuthProvider();
const sgtinIndexService = SgtinIndexService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return sgtinIndexService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return sgtinIndexService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return sgtinIndexService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return sgtinIndexService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return sgtinIndexService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function SgtinIndexPage() {
  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();

  function onInitNewRow(e) {
    e.data.required = false;
  }
  function allowUpdating(e) {
    debugger;
    return !e.row.data.required;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("productTypes") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          onInitNewRow={onInitNewRow}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("sgtinIndexUpdate") && allowUpdating}
            allowAdding={authProvider.isAuth("sgtinIndexSave")}
            allowDeleting={authProvider.isAuth("sgtinIndexDelete") && allowUpdating}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "sgtin.index.title" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="indexName" />
              <Item dataField="required" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="indexName"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "required" })}
            dataField="required"
            dataType="boolean"
          ></Column>
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SgtinIndexPage;
