import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DepartmentService from "./DepartmentService";
import TreeList, {
  Column,
  FilterRow,
  SearchPanel,
  Lookup,
  Scrolling,
  RequiredRule,
  RemoteOperations,
  Editing,
  Toolbar,
  Item,
  LoadPanel,
} from "devextreme-react/tree-list";
import Button from "devextreme-react/button";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import DepartmentTypeService from "../departmentType/DepartmentTypeService";
import LocationTreeList from "../../locations/locationTreeList";
import { mergeEquallyLabeledTypes } from "../../../../providers/UtilsProvider";
import DataGrid, { Form, Popup } from "devextreme-react/data-grid";
import { EContactType } from "../../../../providers/ConstantProvider";
import { useTranslation } from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const departmentTypeService = DepartmentTypeService();
const departmentService = DepartmentService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return departmentService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return departmentService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.personType = "Person";
      return departmentService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return departmentService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return departmentService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

const departmentTypeDataSource = new CustomStore({
  key: "id",
  load() {
    return departmentTypeService.findAll({}).then(
      (response) => ({
        data: response.items,
        totalCount: response.totalCount,
      }),
      (err) => {
        return err.json().then((r) => {
          throw r.errorMessage ? r.errorMessage : r.warningMessage;
        });
      }
    );
  },
  byKey: (key: any) => {
    return departmentTypeService.findOne(key).then((response) => {
      return response;
    });
  },
});

function DepartmentPage() {
  const { t } = useTranslation();
  const [treeList, setTreeList] = useState();

  const onContactInsertedUpdated = (d, val) => {
    if (!d.value) {
      d.setValue([val.data]);
    } else {
      d.setValue(d.value);
    }
  };
  const editGridContactCell = (d) => {
    const value = d.value ? d.value : [];
    return (
      <DataGrid
        dataSource={value}
        allowSearch={false}
        allowFiltering={false}
        allowSorting={false}
        onRowInserted={(val) => {
          onContactInsertedUpdated(d, val);
        }}
        onRowUpdated={(val) => {
          onContactInsertedUpdated(d, val);
        }}
        onRowRemoved={(val) => {
          onContactInsertedUpdated(d, val);
        }}
      >
        <Editing mode="form" allowUpdating={true} allowAdding={true} allowDeleting={true} />
        <Column
          caption={t("{{key, capitalize}}", { key: "contact.information" })}
          dataField="contactType"
          width={200}
        >
          <Lookup dataSource={EContactType} displayExpr="label" valueExpr="value" />
        </Column>
        <Column
          caption={t("{{key, capitalize}}", { key: "value" })}
          dataField="label"
          dataType="string"
        />
      </DataGrid>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("department") + " " + t("lists")}
        icon="widgets"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <TreeList
          dataSource={dataSource}
          keyExpr="id"
          parentIdExpr="parent.id"
          hasItemsExpr="hasItems"
          rowAlternationEnabled={true}
          rootValue={null}
          ref={(ref) => {
            setTreeList(ref);
          }}
        >
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("departmentUpdate")}
            allowDeleting={authProvider.isAuth("departmentDelete")}
            allowAdding={authProvider.isAuth("departmentSave")}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "department.information" })}
              showTitle={true}
            />
            <Form>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "general.information" })}
              >
                <Item dataField="code" />
                <Item dataField="departmentType.id" />
                <Item dataField="name" />
                <Item dataField="location" />
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "legal.entity.information" })}
              >
                <Item dataField="person.identityNumber" />
                <Item dataField="person.name" />
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "contact.information" })}
                colSpan={2}
              >
                <Item dataField="contacts" />
              </Item>
            </Form>
          </Editing>
          <SearchPanel visible={true} width={250} />
          <Scrolling mode="standard" />
          <RemoteOperations filtering={true} sorting={true} />
          <FilterRow visible={true} />
          <LoadPanel enabled={true} />

          <Toolbar visible={true}>
            <Item department="after">
              <Button
                visible={true}
                icon="refresh"
                onClick={() => {
                  treeList.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" visible={false} />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <Column
            sortOrder="asc"
            dataField="code"
            allowFiltering={false}
            caption={t("{{key, capitalize}}", { key: "code" })}
            width={200}
          >
            <RequiredRule />
          </Column>

          <Column
            dataField="departmentType.id"
            allowFiltering={false}
            caption={t("{{key, capitalize}}", { key: "type" })}
            width={200}
          >
            <Lookup dataSource={departmentTypeDataSource} valueExpr="id" displayExpr="name" />
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "location" })}
            dataField="location"
            cellRender={(cr) => {
              return <div>{mergeEquallyLabeledTypes(cr.data.location, [])}</div>;
            }}
            editCellRender={(ecr) => {
              return (
                <LocationTreeList
                  value={ecr.value && ecr.value.id ? ecr.value.id : null}
                  onValueChanged={(e) => {
                    ecr.setValue(e);
                  }}
                />
              );
            }}
          >
            <RequiredRule />
          </Column>

          <Column dataField="name" caption={t("{{key, capitalize}}", { key: "name" })}>
            <RequiredRule />
          </Column>

          <Column
            visible={false}
            allowFiltering={false}
            dataField="person.identityNumber"
            caption={t("{{key, capitalize}}", { key: "tax.identification.number" })}
          />
          <Column
            visible={false}
            allowFiltering={false}
            dataField="person.name"
            caption={t("{{key, capitalize}}", { key: "superscription" })}
          />

          <Column
            visible={false}
            allowSearch={false}
            allowFiltering={false}
            caption={" "}
            dataField="contacts"
            editCellRender={editGridContactCell}
          />
        </TreeList>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DepartmentPage;
