import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "../../../../../assets/theme/base/breakpoints";
import SuiTypography from "../../../../../components/SuiTypography";
import SuiBox from "../../../../../components/SuiBox";
import Card from "@mui/material/Card";
import { Tooltip } from "@mui/material";
import SuiInput from "../../../../../components/SuiInput";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import DataGrid, {
  Column,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { sendWebSocketMessage } from "../../../../../providers/WebSocketProvider";
import { LoadPanel } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { getLangLocalStorage } from "../../../../../providers/LanguageProvider";
import SuiSnackbar from "../../../../../components/SuiSnackbar";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import InspectionService from "../../InspectionService";
import { ESection } from "../../../../../providers/AppConstantProvider";
import VendorService from "../../../vendor/VendorService";
import GlobalInspectionService from "../../../globalInspection/GlobalInspectionService";

const inspectionService = InspectionService();
const vendorService = new VendorService();
const globalInspectionService = GlobalInspectionService();

const globalInspectionDataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return globalInspectionService.findAll(loadOptions).then(
        (response) => {
          response.items.forEach((item) => {
            item.totalCount = response.totalCount;
          });
          return {
            data: response.items,
            totalCount: response.totalCount,
          };
        },
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

const inspectionDataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return inspectionService.findAll(loadOptions).then(
        (response) => {
          response.items.forEach((item) => {
            item.totalCount = response.totalCount;
          });
          return {
            data: response.items,
            totalCount: response.totalCount,
          };
        },
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function FreeInspectionDetailPage({ vendor, device }) {
  const timeOutIdRef = useRef();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabIndex, setTabIndex] = useState(0);
  const tabIndexRef = useRef(0);

  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [difference, setDifference] = useState(false);
  const [showLoadPanel, setShowLoadPanel] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const [snackbarContent, setSnackbarContent] = useState("");

  const [po, setPo] = useState();
  const poRef = useRef();
  const [quantity, setQuantity] = useState();
  const quantityRef = useRef();

  const [model, setModel] = useState();
  const [color, setColor] = useState();
  const [quality, setQuality] = useState();

  const [inspectionType, setInspectionType] = useState("freeReading");

  const [dataGrid, setDataGrid] = useState();

  const [vendorData, setVendorData] = useState();

  const [a1, setA1] = useState();
  const [a2, setA2] = useState();
  const [a3, setA3] = useState();
  const [a4, setA4] = useState();
  const [a5, setA5] = useState();
  const [a6, setA6] = useState();
  const [errorVariable, setErrorVariable] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const returnInspection = (e) => {
    setShowLoadPanel(false);
    if (timeOutIdRef.current) {
      clearTimeout(timeOutIdRef.current);
    }
    const data = e.detail;
    console.log("returnInspection", data);
    setShowSnackbar(false);
    setSnackbarContent("");

    switch (data.command) {
      case "startAnswer":
        setShowLoadPanel(true);
        timeOutIdRef.current = setTimeout(() => {
          notify({
            message: t("notFoundTag"),
            type: "error",
            displayTime: 3000,
            height: 50,
            shading: false,
          });
          sendMessage("stopOffer", getTargetData());
          // setShowLoadPanel(false);
        }, 15000);
        break;
      case "stopAnswer":
      case "refreshAnswer":
      case "open":
      case "close":
        break;
      case "saveNextAnswer":
        notify({
          message: data.data,
          type: "success",
          displayTime: 3000,
          height: 50,
          shading: false,
        });
        inspectionDataSource.filter(["po", "=", poRef.current]);
        inspectionDataSource.reload();
        clear();
        break;
      case "error":
        setShowSnackbar(true);
        setSnackbarContent(t(data.data));
        break;
      case "data":
        let sizeData = beautifullyData(data.data);
        sizeData = beautifullyData2(sizeData);
        setData(sizeData);
        setAssortments(sizeData);
        console.log(sizeData);
        break;
      default:
    }
  };
  useEffect(() => {
    if (vendor.prefix) {
      setInspectionType("globalInspection");
    } else {
      setInspectionType("freeReading");
    }
  }, [vendor]);

  const beautifullyData2 = (dataObject) => {
    const groupedData = {};
    if (!vendor.prefix) {
      dataObject.forEach((item) => {
        if (!(item.brand === vendor.code)) {
          item = { ...item, brand: 9999 };
          if (!groupedData[item.brand]) {
            groupedData[item.brand] = { ...item, expected: 0 };
          } else {
            groupedData[item.brand].counted += item.counted;
          }
        } else {
          groupedData[item.size] = { ...item };
        }
      });
    } else {
      dataObject.forEach((item) => {
        if (!(item.companyPrefix === vendor.prefix)) {
          if (!groupedData[item.brand]) {
            groupedData[item.brand] = { ...item, expected: 0 };
          } else {
            groupedData[item.brand].counted += item.counted;
          }
        } else {
          groupedData[item.gtin] = { ...item };
        }
      });
    }
    return Object.values(groupedData);
  };

  const beautifullyData = (dataObject) => {
    setErrorVariable(0);
    let tempModel, tempColor, tempQuality, tempErrorVariable, tempGtin;
    return dataObject
      .reduce(
        (res, obj) => {
          if (obj.gtin) {
            if (tempGtin === undefined) {
              tempGtin = obj.gtin;
            }
            let key;
            if (vendor && vendor.code === "5555") {
              key = obj.brand;
            } else {
              key = obj.gtin;
            }

            if (tempGtin !== obj.gtin) {
              tempErrorVariable = tempErrorVariable + 1;
              setErrorVariable(tempErrorVariable);
            }
            debugger;
            if (!(key in res)) {
              res.__array.push(
                (res[key] = {
                  gtin: obj.gtin,
                  brand: obj.brand,
                  companyPrefix: obj.companyPrefix,
                  counted: 1,
                  expected: tabIndexRef.current === 0 ? quantityRef.current : 1,
                })
              );
            } else {
              res[key].counted += 1;
              res[key].expected =
                tabIndexRef.current === 0 ? quantityRef.current : res[key].counted;
            }
          } else {
            if (tempModel === undefined || tempQuality === undefined || tempColor === undefined) {
              tempModel = obj.model;
              tempColor = obj.color;
              tempQuality = obj.quality;
            }
            let key;
            if (vendor && vendor.code === "5555") {
              key = obj.brand;
            } else {
              key = obj.brand + "||" + obj.size;
            }
            if (tempModel !== obj.model || tempQuality !== obj.quality || tempColor !== obj.color) {
              tempErrorVariable = tempErrorVariable + 1;
              setErrorVariable(tempErrorVariable);
            }
            if (!(key in res)) {
              res.__array.push(
                (res[key] = {
                  size: obj.size,
                  brand: obj.brand,
                  counted: 1,
                  expected: tabIndexRef.current === 0 ? quantityRef.current : 1,
                })
              );

              setModel(obj.model);
              tempModel = obj.model;
              setColor(obj.color);
              tempColor = obj.color;
              setQuality(obj.quality);
              tempQuality = obj.quality;
            } else {
              res[key].counted += 1;
              res[key].expected =
                tabIndexRef.current === 0 ? quantityRef.current : res[key].counted;
            }
          }

          return res;
        },
        { __array: [] }
      )
      .__array.sort(function (a, b) {
        return a.size - b.size;
      });
  };

  const gcd = (...arr) => {
    if ([...arr].length > 0) {
      const _gcd = (x, y) => (!y ? x : gcd(y, x % y));
      return [...arr].reduce((a, b) => _gcd(a, b));
    }
  };

  const setAssortments = (sizeData) => {
    const ebob = gcd(...sizeData.map((v) => v.counted));
    sizeData.forEach((item, index) => {
      switch (index) {
        case 0:
          setA1((item.counted / ebob).toFixed(0));
          break;
        case 1:
          setA2((item.counted / ebob).toFixed(0));
          break;
        case 2:
          setA3((item.counted / ebob).toFixed(0));
          break;
        case 3:
          setA4((item.counted / ebob).toFixed(0));
          break;
        case 4:
          setA5((item.counted / ebob).toFixed(0));
          break;
        case 5:
          setA6((item.counted / ebob).toFixed(0));
          break;
        default:
      }
    });
  };

  useEffect(() => {
    window.addEventListener("inspection", returnInspection);
    vendorService.findAllInspection({}).then((r) => {
      console.log(r);
      setVendorData(r);
    });
    return () => {
      window.removeEventListener("inspection", returnInspection);
    };
  }, []);

  const sendMessage = (cmd, d) => {
    setShowLoadPanel(true);
    getLangLocalStorage().then((language) => {
      sendWebSocketMessage({ command: cmd, lang: language, data: d ? d : getTargetData() });
    });
    timeOutIdRef.current = setTimeout(() => {
      notify({
        message: t("sureDeviceTurnOn"),
        type: "error",
        displayTime: 3000,
        height: 50,
        shading: false,
      });
      setShowLoadPanel(false);
    }, 15000);
  };

  const getTargetData = () => {
    return {
      txPowerDbm: device.txPowerDbm,
      quantity: quantityRef.current,
      vendorCode: vendor.code,
      color: color,
      quality: quality,
      expected: quantityRef.current,
      model: model,
      inspectionType: inspectionType,
    };
  };

  const startOffer = () => {
    clear();
    sendMessage("startFreeOffer", getTargetData());
  };

  const clear = () => {
    setData({});
    setA1("");
    setA2("");
    setA3("");
    setA4("");
    setA5("");
    setA6("");
  };

  return (
    <>
      <Card>
        <SuiBox p={2} mt={1} width="100%">
          <Grid container spacing={3}>
            <Grid item xs={4} md={2}>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography
                    textTransform="capitalize"
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("{{key, capitalize}}", { key: "targetQuantity" })}
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  inputProps={{ type: "number" }}
                  placeholder={t("{{key, capitalize}}", { key: "targetQuantity" })}
                  icon={{
                    component: "beenhere",
                    direction: "left",
                  }}
                  // value={quantity}
                  onChange={(e) => {
                    setQuantity(Number(e.target.value));
                    quantityRef.current = Number(e.target.value);
                    clear();
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={4} md={2}>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography
                    textTransform="capitalize"
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("{{key, capitalize}}", { key: "model" })}
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  inputProps={{ type: "text", readOnly: true }}
                  placeholder={model}
                  // defaultValue={model}
                  onChange={(e) => {
                    setModel(e.target.value);
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={4} md={2}>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography
                    textTransform="capitalize"
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("{{key, capitalize}}", { key: "quality" })}
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  inputProps={{ type: "number", readOnly: true }}
                  placeholder={quality}
                  onChange={(e) => {
                    setQuality(e.target.value);
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={4} md={2}>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography
                    textTransform="capitalize"
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("{{key, capitalize}}", { key: "color" })}
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  inputProps={{ type: "text", readOnly: true }}
                  placeholder={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        {!vendor.prefix ? (
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" width="60%">
              <SuiBox width="80%">
                <AppBar position="static">
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabIndex}
                    onChange={(event, tabIndex) => {
                      tabIndexRef.current = tabIndex;
                      setTabIndex(tabIndex);
                      if (tabIndex === 0) {
                        if (vendor.prefix) {
                          setInspectionType("globalInspection");
                        } else {
                          setInspectionType("freeReading");
                        }
                      } else setInspectionType("assortmentChecking");
                    }}
                  >
                    <Tab label={t("{{key, capitalize}}", { key: "freeReading" })} />
                    <Tab label={t("{{key, capitalize}}", { key: "assortmentChecking" })} />
                  </Tabs>
                </AppBar>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        ) : (
          <></>
        )}
        <SuiBox p={2} mt={1} width="100%">
          <Grid container spacing={3}>
            {tabIndex === 1 ? (
              <>
                <Grid item xs={8} md={4} sx={{ textAlign: "right" }}>
                  <SuiBox mb={1} ml={0.5}>
                    <SuiTypography
                      textTransform="capitalize"
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      {t("{{key, capitalize}}", { key: "assortment" })}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={2} md={1}>
                  <SuiBox mb={2} lineHeight={1.25}>
                    <SuiInput
                      inputProps={{ type: "text" }}
                      value={a1}
                      readOnly={true}
                      onChange={(e) => {}}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={2} md={1}>
                  <SuiBox mb={2} lineHeight={1.25}>
                    <SuiInput
                      inputProps={{ type: "text" }}
                      value={a2}
                      readOnly={true}
                      onChange={(e) => {}}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={2} md={1}>
                  <SuiBox mb={2} lineHeight={1.25}>
                    <SuiInput
                      inputProps={{ type: "text" }}
                      value={a3}
                      readOnly={true}
                      onChange={(e) => {}}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={2} md={1}>
                  <SuiBox mb={2} lineHeight={1.25}>
                    <SuiInput
                      inputProps={{ type: "text" }}
                      value={a4}
                      readOnly={true}
                      onChange={(e) => {}}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={2} md={1}>
                  <SuiBox mb={2} lineHeight={1.25}>
                    <SuiInput
                      inputProps={{ type: "text" }}
                      value={a5}
                      readOnly={true}
                      onChange={(e) => {}}
                    />
                  </SuiBox>
                </Grid>
                <Grid item xs={2} md={1}>
                  <SuiBox mb={2} lineHeight={1.25}>
                    <SuiInput
                      inputProps={{ type: "text" }}
                      value={a6}
                      readOnly={true}
                      onChange={(e) => {}}
                    />
                  </SuiBox>
                </Grid>
              </>
            ) : null}
          </Grid>
        </SuiBox>
        <SuiBox p={2} mt={0} width="100%" sx={{ minHeight: "20.25rem" }}>
          <SuiBox
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SuiBox sx={{ marginRight: "auto" }}>
              <DataGrid
                showBorders={false}
                remoteOperations={false}
                rowAlternationEnabled={true}
                dataSource={data}
              >
                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

                {!vendor.prefix ? (
                  <Column dataField="brand" caption={t("{{key, capitalize}}", { key: "brand" })}>
                    <Lookup dataSource={vendorData} displayExpr="name" valueExpr="code" />
                  </Column>
                ) : (
                  <Column
                    dataField="companyPrefix"
                    caption={t("{{key, capitalize}}", { key: "brand" })}
                  >
                    <Lookup dataSource={vendorData} displayExpr="name" valueExpr="code" />
                  </Column>
                )}

                {!vendor.prefix ? (
                  <Column dataField="size" caption={t("{{key, capitalize}}", { key: "size" })} />
                ) : (
                  <Column dataField="gtin" caption={t("{{key, capitalize}}", { key: "gtin" })} />
                )}
                <Column
                  dataField="expected"
                  caption={t("{{key, capitalize}}", { key: "expected" })}
                  dataType="number"
                />
                <Column
                  dataField="counted"
                  caption={t("{{key, capitalize}}", { key: "counted" })}
                  dataType="number"
                />
                <Column
                  dataField="difference"
                  caption={t("{{key, capitalize}}", { key: "difference" })}
                  dataType="number"
                  cellRender={(cellData) => {
                    return (
                      <div>
                        {cellData.value === 0 ? (
                          <SuiTypography variant="body2" color="dark">
                            {cellData.value}
                          </SuiTypography>
                        ) : (
                          <SuiTypography variant="body2" color="error">
                            {cellData.value}
                          </SuiTypography>
                        )}
                      </div>
                    );
                  }}
                  calculateCellValue={(rowData) => {
                    const val = rowData.counted - rowData.expected;
                    if (data && data.length > 0) {
                      const totalCounted = data
                        .map((item) => item.counted)
                        .reduce((acc, item) => item + acc);
                      const totalExpected = data
                        .map((item) => item.expected)
                        .reduce((acc, item) => item + acc);
                      if (!vendor.prefix) {
                        if (tabIndex === 1) {
                          setDifference(
                            totalCounted === totalExpected &&
                              totalCounted === quantity &&
                              errorVariable === 0
                          );
                        } else {
                          setDifference(
                            totalCounted === totalExpected &&
                              totalCounted === quantity &&
                              data.length === 1 &&
                              errorVariable === 0
                          );
                        }
                      } else {
                        setDifference(
                          totalCounted === quantity && (errorVariable === 0 || isNaN(errorVariable))
                        );
                      }
                    }
                    return val;
                  }}
                />

                <Summary>
                  <TotalItem
                    column="counted"
                    summaryType="sum"
                    displayFormat={
                      t("{{key, capitalize}}", { key: "sum" }) +
                      ": {0} " +
                      t("{{key, capitalize}}", { key: "number" })
                    }
                  />
                </Summary>
              </DataGrid>
            </SuiBox>
            <SuiBox>
              <SuiBox display="flex" alignItems="center">
                <SuiBox
                  sx={{ cursor: "pointer" }}
                  lineHeight={0}
                  onClick={() => {
                    // setData({});
                    sendMessage("stopOffer", getTargetData());
                  }}
                >
                  <Tooltip title={t("{{key, capitalize}}", { key: "stop" })} placement="top" arrow>
                    <CardMedia
                      component="img"
                      height="60"
                      image="/images/inspection/stop.png"
                      alt="stop"
                    />
                  </Tooltip>
                </SuiBox>

                {quantity != null && quantity > 0 ? (
                  <SuiBox
                    sx={{ cursor: "pointer" }}
                    lineHeight={0}
                    onClick={() => {
                      startOffer();
                    }}
                  >
                    <Tooltip
                      title={t("{{key, capitalize}}", { key: "start" })}
                      placement="top"
                      arrow
                    >
                      <CardMedia
                        component="img"
                        height="60"
                        image="/images/inspection/play.png"
                        alt="start"
                      />
                    </Tooltip>
                  </SuiBox>
                ) : null}
              </SuiBox>
              {data.length > 0 ? (
                <CardMedia
                  sx={{ ml: 10, mt: 4, width: 120 }}
                  component="img"
                  image={
                    difference && !data.find((item) => item.brand === "9999")
                      ? "/images/inspection/approved.png"
                      : "/images/inspection/rejected.png"
                  }
                  alt="green iguana"
                />
              ) : null}
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
      <LoadPanel
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={true}
      />
      {snackbarContent !== "" ? (
        <SuiSnackbar
          sx={{ maxWidth: 400 }}
          color="error"
          icon="error"
          title={t("{{key, capitalize}}", { key: "error" })}
          content={snackbarContent}
          dateTime=""
          autoHideDuration={10000}
          open={showSnackbar}
          onClose={toggleSnackbar}
          close={toggleSnackbar}
        />
      ) : null}
    </>
  );
}

export default FreeInspectionDetailPage;
