import notify from "devextreme/ui/notify";
import { getLangLocalStorage } from "./LanguageProvider";

let data;

function AuthProvider() {
  const authFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const token = await getToken();
    const language = await getLangLocalStorage();

    init = init || {};
    if (language) {
      init.headers = {
        ...init.headers,
        "Accept-Language": `${language}`,
      };
    }

    if (token) {
      init.headers = {
        ...init.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    input = process.env.REACT_APP_API_URL + input;
    return fetch(input, init)
      .then((response) => {
        if (response.ok) {
          // const isJson = response.headers.get('content-type')?.includes('application/json');
          // console.log('isJson', isJson)
          return response.json();
        } else if (response.status === 400) {
          throw response;
        } else if (response.status === 401) {
          setAuthData(null);
          window.location.replace(window.location.origin + "/authentication/login");
          return Promise.reject(
            new Response(JSON.stringify({ errorMessage: "Oturum Süresi Tamamlandı" }))
          );
        } else {
          return Promise.reject(
            new Response(JSON.stringify({ errorMessage: "Bilinmeyen Hata oluştu" }))
          );
        }
      })
      .then((json) => {
        if (json && json.infoMessage) {
          notify({
            message: json.infoMessage,
            type: "success",
            displayTime: 3000,
            height: 50,
            shading: false,
          });
        }
        return Promise.resolve(json);
      })
      .catch((er) => {
        if (er instanceof SyntaxError) {
          return Promise.resolve();
        }

        er.clone()
          .json()
          .then((err) => {
            // return showErrorMessage(err.errorMessage ? err.errorMessage : err.warningMessage);
            notify({
              message: err.errorMessage ? err.errorMessage : err.warningMessage,
              type: "error",
              displayTime: 3000,
              height: 50,
              shading: false,
            });
          });
        return Promise.reject(er);
      });
  };

  const setAuthData = (data) => {
    if (data) {
      sessionStorage.setItem("auth", JSON.stringify(data));
    } else {
      sessionStorage.removeItem("auth");
    }
  };

  const getAuthData = () => {
    if (data == null) {
      data = sessionStorage.getItem("auth") ? JSON.parse(sessionStorage.getItem("auth")) : null;
    }
    return data;
  };

  const clearAuthData = () => {
    data = null;
    return sessionStorage.clear();
  };

  const getToken = async () => {
    const token = getAuthData() && getAuthData().access_token;
    if (token) {
      return token;
    }
    return null;
  };

  const useAuth = () => {
    return getToken() !== null;
  };

  const isAuth = (authName) => {
    if (getAuthData() === null || getAuthData().authorities === null) {
      return false;
    }
    let auth = getAuthData().authorities;
    let state = false;
    auth.forEach((item) => {
      if (Array.isArray(authName)) {
        authName.forEach((a) => {
          if (item === "ROLE_" + a) {
            state = true;
          }
        });
      } else {
        if (item === "ROLE_" + authName) {
          state = true;
        }
      }
    });
    return state;
  };

  return {
    getAuthData,
    setAuthData,
    clearAuthData,
    useAuth,
    authFetch,
    isAuth,
    getToken,
  };
}

export default AuthProvider;
