import React, { useEffect, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  Lookup,
  RequiredRule,
  FilterRow,
  Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { FileUploader } from "devextreme-react";
import AuthProvider from "../../../../providers/AuthProvider";
import DocumentService from "../DocumentService";
import { EAnnouncementType, EDocumentType } from "../../../../providers/ConstantProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import { useTranslation } from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const documentService = DocumentService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return documentService.findAll(loadOptions).then(
        (data) => ({
          data: data.items,
          totalCount: data.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return documentService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.image = image;
      values.thumbnail = thumbnail;
      return documentService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      values.image = image;
      values.thumbnail = thumbnail;
      return documentService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return documentService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

let image, thumbnail;

function DocumentPage() {
  const { t } = useTranslation();
  useEffect(() => {}, []);
  const [dataGrid, setDataGrid] = useState();

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("document") + " " + t("lists")}
        icon="filter_none"
        fontSize={"medium"}
        subTitle={t("document") + " " + t("manager")}
      />
      <SuiBox mt={2} p={3} color="red" shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
          onInitNewRow={() => {
            image = null;
            thumbnail = null;
          }}
          onEditingStart={() => {
            image = null;
            thumbnail = null;
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item location="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("documentUpdate")}
            allowAdding={authProvider.isAuth("documentSave")}
            allowDeleting={authProvider.isAuth("documentDelete")}
          >
            <Popup
              caption={t("{{key, capitalize}}", { key: "document.information" })}
              showTitle={true}
            />
            <Form>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "general.information" })}
              >
                <Item dataField="documentType" />
                <Item dataField="title" />
                <Item dataField="description" />
                <Item dataField="link" />
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "file.information" })}
              >
                <Item dataField="thumbnail" />
                <Item dataField="image" />
              </Item>
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
          <Column
            caption={t("{{key, capitalize}}", { key: "document.view" })}
            dataField="documentType"
            width={200}
          >
            <Lookup
              dataSource={EAnnouncementType}
              displayExpr={(row) => {
                return t("{{key, capitalize}}", {
                  key: row.value,
                });
              }}
              valueExpr="value"
            />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "title" })}
            dataField="title"
            dataType="string"
            sortOrder="asc"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "description" })}
            dataField="description"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "link" })}
            dataField="link"
            dataType="string"
          />
          <Column
            dataField="thumbnail"
            caption="Thumbnail"
            visible={false}
            editCellRender={(d) => {
              return (
                <FileUploader
                  selectButtonText={t("{{key, capitalize}}", { key: "select.image" })}
                  labelText={t("drop.image")}
                  accept="image/*"
                  uploadMode="useForm"
                  onValueChanged={(val) => {
                    image = val.value[0];
                    d.setValue(image);
                  }}
                />
              );
            }}
            allowSearch={false}
            allowFiltering={false}
          />
          <Column
            dataField="image"
            caption={t("{{key, capitalize}}", { key: "document" })}
            visible={false}
            editCellRender={(d) => {
              return (
                <FileUploader
                  selectButtonText={t("{{key, capitalize}}", { key: "select.document" })}
                  labelText={t("drop.document")}
                  uploadMode="useForm"
                  onValueChanged={(val) => {
                    thumbnail = val.value[0];
                    d.setValue(thumbnail);
                  }}
                />
              );
            }}
            allowSearch={false}
            allowFiltering={false}
          />
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DocumentPage;
