import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  Lookup,
  FilterRow,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import ReportManagerService from "./ReportManagerService";
import RoleService from "../../users/role/RoleService";
import { FileUploader } from "devextreme-react";
import { useTranslation } from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const reportManagerService = ReportManagerService();
const roleService = new RoleService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return reportManagerService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return reportManagerService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.personType = "Person";
      return reportManagerService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return reportManagerService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return reportManagerService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

const roleDataSource = new CustomStore({
  key: "id",
  load: () => {
    return roleService.findAll().then((response: any) => {
      return response;
    });
  },

  byKey: (key: any) => {
    return roleService.findOne(key).then((response) => {
      return response;
    });
  },
});

const reportItemsDataSource = new CustomStore({
  load: () => {
    return reportManagerService
      .findAllReports()
      .then((response) => {
        return response.json();
      })
      .then((data: any) => {
        return data.result;
      });
  },

  byKey: (key: any) => {
    return reportManagerService
      .findAllReports()
      .then((response) => {
        return response.json();
      })
      .then((data: any) => {
        return data.result.filter((f) => Number(f.Key) === Number(key))[0];
      });
  },
});

const onGridInsertedUpdated = (d, val) => {
  if (!d.value) {
    d.setValue([val.data]);
  } else {
    d.setValue(d.value);
  }
};
const editGridRolesCell = (d) => {
  return (
    <DataGrid
      dataSource={d.value ? d.value : []}
      allowSearch={true}
      allowFiltering={true}
      allowSorting={true}
      onRowInserted={(val) => {
        onGridInsertedUpdated(d, val);
      }}
      onRowUpdated={(val) => {
        onGridInsertedUpdated(d, val);
      }}
      onRowRemoved={(val) => {
        onGridInsertedUpdated(d, val);
      }}
    >
      <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true} />
      <Paging defaultPageSize={10} />
      <SearchPanel visible={true} width={240} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
      <Column caption="Rol" dataField="id">
        <Lookup dataSource={roleDataSource} displayExpr="description" valueExpr="id" />
        <RequiredRule />
      </Column>
    </DataGrid>
  );
};

function ReportManagerPage() {
  const { t } = useTranslation();
  const [dataGrid, setDataGrid] = useState();

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("report") + " " + t("lists")}
        icon="assessment"
        fontSize="medium"
        subTitle={t("report") + " " + t("manager")}
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("reportUpdate")}
            allowAdding={authProvider.isAuth("reportSave")}
            allowDeleting={authProvider.isAuth("reportDelete")}
          >
            <Form>
              <Item dataField="orderNumber" />
              <Item dataField="title" />
              <Item dataField="description" />
              <Item dataField="reportName" />
              <Item dataField="image" colSpan={2} />
              <Item dataField="roles" colSpan={2} />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption={t("{{key, capitalize}}", { key: "order.number" })}
            dataField="orderNumber"
            dataType="number"
            sortOrder="asc"
            width={120}
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "title" })}
            dataField="title"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "description" })}
            dataField="description"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column caption={t("{{key, capitalize}}", { key: "report.name" })} dataField="reportName">
            <Lookup dataSource={reportItemsDataSource} displayExpr="Value" valueExpr="Key" />
            <RequiredRule />
          </Column>

          <Column
            caption=" "
            dataField="roles"
            visible={false}
            allowSearch={false}
            allowFiltering={false}
            editCellRender={editGridRolesCell}
          />

          <Column
            dataField="image"
            caption={t("{{key, capitalize}}", { key: "image" })}
            accept="image/*"
            visible={false}
            editCellRender={(d) => {
              return (
                <FileUploader
                  selectButtonText={t("{{key, capitalize}}", { key: "select.image" })}
                  labelText={t("drop.image")}
                  uploadMode="useForm"
                  onValueChanged={(val) => {
                    d.setValue(null);
                    d.setValue(val.value[0]);
                  }}
                />
              );
            }}
            allowSearch={false}
            allowFiltering={false}
          />
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReportManagerPage;
