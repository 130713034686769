import { useTranslation } from "react-i18next";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import React, { useEffect, useRef, useState } from "react";
import DeviceService from "../../device/DeviceService";
import { Step, StepLabel, Stepper } from "@mui/material";
import { LoadPanel } from "devextreme-react";
import {
  closeWebSocket,
  initWebsocket,
  sendWebSocketMessage,
} from "../../../../providers/WebSocketProvider";
import SelectDevicePage from "../share/select/device";
import SuiButton from "../../../../components/SuiButton";
import SelectVendorPage from "../share/select/vendor";
import VendorService from "../../vendor/VendorService";
import Icon from "@mui/material/Icon";
import PageLayout from "../../../../layouts/PageLayout";
import { closeFullscreen, openFullscreen } from "../../../../providers/FullscreenProvider";
import InspectionPage from "../share/inspection";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import SuiSnackbar from "../../../../components/SuiSnackbar";

const deviceService = DeviceService();
const vendorService = VendorService();

let filter;
let timeOutId;
// let vendor;

function NewInspectionPage() {
  const { t } = useTranslation();
  useEffect(() => {}, []);
  const [showLoadPanel, setShowLoadPanel] = useState(true);
  const [data, setData] = useState();
  const [vendorData, setVendorData] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(!showSnackbar);
  const [snackbarContent, setSnackbarContent] = useState("");

  const [device, setDevice] = useState();
  const [vendor, setVendor] = useState();

  const [activeStep, setActiveStep] = useState(0);
  const activeStepRef = useRef(0);

  const returnFunction = (e) => {
    const data = e.detail;
    // console.log('returnFunction', data);
    // console.log('activeStep', activeStepRef);
    switch (data.command) {
      case "open":
        data.command = "offer";
        sendWebSocketMessage(data);
        timeOutId = setTimeout(() => {
          setSnackbarContent(t("sureDeviceTurnOn"));
          setShowSnackbar(true);
          closeWebSocket();
          setShowLoadPanel(false);
          // loadDeviceData();
        }, 5000);
        break;
      case "answer":
        if (timeOutId) {
          clearTimeout(timeOutId);
        }
        if (activeStepRef.current < 1) {
          activeStepRef.current = 1;
          setActiveStep(1);
        }
        setShowLoadPanel(false);
        // handleNext();
        break;
      default:
    }
  };
  useEffect(() => {
    window.addEventListener("socket", returnFunction);
    loadDeviceData();
    loadVendorData();
    return () => {
      closeWebSocket();
      window.removeEventListener("socket", returnFunction);
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, []);

  const openWebSocket = (code) => {
    setShowLoadPanel(true);
    initWebsocket(code);
  };

  const loadDeviceData = () => {
    setShowLoadPanel(true);
    deviceService
      .findAll({
        sort: [{ selector: "code", desc: false }],
        filter: filter ? filter : null,
      })
      .then((response) => {
        setShowLoadPanel(false);
        setData(response.items);
      })
      .catch((err) => {
        setShowLoadPanel(false);
      });
  };

  const loadVendorData = () => {
    setShowLoadPanel(true);
    vendorService
      .findAllInspectionViewTrue({
        sort: [{ selector: "code", desc: false }],
        filter: filter ? filter : null,
      })
      .then((response) => {
        setShowLoadPanel(false);
        setVendorData(response);
      })
      .catch((err) => {
        setShowLoadPanel(false);
      });
  };

  const steps = [
    t("{{key, capitalize}}", { key: "selectDevice" }),
    t("{{key, capitalize}}", { key: "selectBrand" }),
    t("{{key, capitalize}}", { key: "inspection" }),
  ];
  const isLastStep = activeStep === steps.length - 1;
  // const handleNext = () => {
  //     setActiveStep(activeStep + 1);
  //     activeStepRef.current = activeStep + 1;
  // }
  const handleBack = () => {
    if (activeStep === 1) {
      closeWebSocket();
      loadDeviceData();
    }
    setActiveStep(activeStep - 1);
    activeStepRef.current = activeStep - 1;
  };

  const shareModule = () => {
    return (
      <>
        <SuiBox pt={2} shadow="none" bgColor="transparent">
          {activeStep < 2 ? (
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : null}

          {activeStep === 0 ? (
            <SelectDevicePage
              data={data}
              initWebsocket={openWebSocket}
              onSelectDevice={(d) => {
                setDevice(d);
              }}
            />
          ) : activeStep === 1 ? (
            <SelectVendorPage
              device={device}
              selectItem={(item) => {
                setVendor(item);
                setActiveStep(2);
                activeStepRef.current = 2;
              }}
              data={vendorData}
            />
          ) : (
            <InspectionPage vendor={vendor} device={device} />
          )}

          <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
            {activeStep === 0 ? (
              <SuiBox />
            ) : (
              <SuiButton variant="gradient" color="light" onClick={handleBack}>
                {t("back")}
              </SuiButton>
            )}

            {isLastStep ? (
              <SuiButton
                variant="gradient"
                color="warning"
                onClick={() => {
                  closeWebSocket();
                  setActiveStep(0);
                  activeStepRef.current = 0;
                }}
              >
                {t("close")}
              </SuiButton>
            ) : (
              <SuiBox />
            )}
          </SuiBox>
        </SuiBox>
      </>
    );
  };

  return (
    <>
      {fullscreen ? (
        <PageLayout>{shareModule()}</PageLayout>
      ) : (
        <DashboardLayout>
          <DashboardNavbar
            title={t("new") + " " + t("inspection")}
            icon="control_point_duplicate"
            fontSize={"medium"}
            onChangeSearch={(e) => {
              if (activeStep === 0) {
                filter = e ? [["name", "contains", e]] : null;
                loadDeviceData();
              } else if (activeStep === 1) {
                filter = e ? [["name", "contains", e]] : null;
                loadVendorData();
              }
            }}
          />
          {shareModule()}
          <Footer />
        </DashboardLayout>
      )}

      {/*<SuiButton*/}
      {/*  sx={{ position: "fixed", right: "5px", bottom: "5px" }}*/}
      {/*  variant="gradient"*/}
      {/*  color="white"*/}
      {/*  iconOnly*/}
      {/*  circular*/}
      {/*  onClick={() => {*/}
      {/*    if (fullscreen) {*/}
      {/*      closeFullscreen();*/}
      {/*      setFullscreen(false);*/}
      {/*    } else {*/}
      {/*      setFullscreen(true);*/}
      {/*      openFullscreen();*/}
      {/*    }*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Icon fontSize="small">{fullscreen ? "zoom_in_map" : "zoom_out_map"}</Icon>*/}
      {/*</SuiButton>*/}
      <LoadPanel
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={true}
      />
      <SuiSnackbar
        sx={{ maxWidth: 400 }}
        color="error"
        icon="error"
        title={t("{{key, capitalize}}", { key: "error" })}
        content={snackbarContent}
        dateTime=""
        autoHideDuration={10000}
        open={showSnackbar}
        onClose={toggleSnackbar}
        close={toggleSnackbar}
      />
    </>
  );
}

export default NewInspectionPage;
