import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Lookup,
  Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import CompanyPrefixService from "./CompanyPrefixService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import { FileUploader } from "devextreme-react";
import avatar from "../../../assets/images/faces/logo.png";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { EActiveType } from "../../../providers/ConstantProvider";

const authProvider = AuthProvider();
const companyPrefixService = CompanyPrefixService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return companyPrefixService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return companyPrefixService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.personType = "Person";
      return companyPrefixService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return companyPrefixService.update(values).then(
        () => {
          window.location.reload();
        },
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return companyPrefixService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function CompanyPrefixPage() {
  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar title={t("company.prefix") + " " + t("lists")} icon="store" fontSize="medium" />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("companyPrefixUpdate")}
            allowAdding={authProvider.isAuth("companyPrefixSave")}
            allowDeleting={authProvider.isAuth("companyPrefixDelete")}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "company.prefix.information" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="prefix" />
              <Item dataField="name" />
              <Item dataField="image" />
              <Item dataField="view" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption="Logo"
            dataField="contentId"
            dataType="string"
            width={140}
            cellComponent={(val) => {
              const d = val.data ? val.data : val;
              return (
                <img
                  width="100"
                  src={
                    d.value
                      ? `${process.env.REACT_APP_API_URL}binaryContents/getPublicImage/` + d.value
                      : avatar
                  }
                  alt="Person"
                />
              );
            }}
            allowSearch={false}
            allowFiltering={false}
            allowSorting={false}
          />

          <Column
            caption={t("{{key, capitalize}}", { key: "prefix" })}
            dataField="prefix"
            dataType="string"
            sortOrder="asc"
            width={200}
          >
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="name"
            dataType="string"
          >
            <RequiredRule />
          </Column>

          <Column
            dataField="image"
            caption={t("{{key, capitalize}}", { key: "image" })}
            visible={false}
            editCellRender={(d) => {
              return (
                <FileUploader
                  selectButtonText={t("{{key, capitalize}}", { key: "select.image" })}
                  labelText={t("drop.image")}
                  uploadMode="useForm"
                  onValueChanged={(val) => {
                    d.setValue(null);
                    d.setValue(val.value[0]);
                  }}
                />
              );
            }}
            allowSearch={false}
            allowFiltering={false}
          />
          <Column caption={t("{{key, capitalize}}", { key: "view" })} dataField="view" width={200}>
            <Lookup
                dataSource={EActiveType}
                displayExpr={(row) => {
                  return t("{{key, capitalize}}", {
                    key: row.label,
                  });
                }}
                valueExpr="value"
            />
          </Column>
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CompanyPrefixPage;
