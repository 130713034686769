import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import React, { useEffect, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Form,
  Item,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  Popup,
  RemoteOperations,
  RequiredRule,
  SearchPanel,
  Toolbar,
  FilterRow,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import AuthProvider from "../../../../providers/AuthProvider";
import RoleService from "./RoleService";
import { useTranslation } from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const authProvider = AuthProvider();
const roleService = RoleService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return roleService.findAll(loadOptions).then(
        (data) => ({
          data: data,
          totalCount: data.length,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.message;
          });
        }
      );
    },
    byKey: (key: any) => {
      return roleService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return roleService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.message;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return roleService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.message;
          });
        }
      );
    },
    remove: (key: any) => {
      return roleService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.message;
          });
        }
      );
    },
  }),
  filter: ["personType", "=", "Person"],
});

const rolesDataSource = new CustomStore({
  key: "id",
  load: () => {
    return roleService.findAll().then((response: any) => {
      return {
        data: response,
        totalCount: response.length,
      };
    });
  },
  byKey: (key: any) => {
    return roleService.findOne(key).then((response: any) => {
      return response;
    });
  },
});

let authoritiesData = [];

const getAuthoritiesDataSource = (updateItem) => {
  if (updateItem.value) {
    console.log("updateItem.value");
    return updateItem.value;
  }
  console.log("getAuthoritiesDataSource", authoritiesData);
  return authoritiesData;
};

const onGridInsertedUpdated = (d, val) => {
  console.log("onGridInsertedUpdated", d, val);
  if (!d.value) {
    d.setValue([val.data]);
  } else {
    d.setValue(d.value);
  }
};

function RolePage() {
  const { t } = useTranslation();
  useEffect(() => {
    roleService.findAllAuthorities().then((response) => {
      response.forEach((item) => {
        authoritiesData.push({
          selected: false,
          authority: item,
        });
      });
    });
  }, []);
  const [dataGrid, setDataGrid] = useState();

  const editGridRoleAuthoritiesCell = (d) => {
    console.log("editGridRoleAuthoritiesCell", d);
    if (!d.value) {
      d.setValue(authoritiesData);
    }
    return (
      <DataGrid
        dataSource={getAuthoritiesDataSource(d)}
        allowSearch={true}
        allowFiltering={true}
        allowSorting={true}
        onRowInserted={(val) => {
          onGridInsertedUpdated(d, val);
        }}
        onRowUpdated={(val) => {
          onGridInsertedUpdated(d, val);
        }}
        onRowRemoved={(val) => {
          onGridInsertedUpdated(d, val);
        }}
      >
        <Editing mode="cell" allowUpdating={true} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
        <Toolbar>
          <Item name="searchPanel" />
        </Toolbar>
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
        <Column
          caption={t("{{key, capitalize}}", { key: "authority.group" })}
          dataField="authority.groupName"
          width={200}
          groupIndex={0}
          sortOrder="asc"
          allowEditing={false}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={t("{{key, capitalize}}", { key: "state" })}
          width={150}
          dataField="selected"
          dataType="boolean"
          setCellValue={(newData, value) => {
            newData.selected = value;
          }}
        />
        <Column
          caption={t("{{key, capitalize}}", { key: "description" })}
          dataField="authority.description"
          dataType="string"
          allowEditing={false}
        />
      </DataGrid>
    );
  };

  const editGridRolesCell = (d) => {
    return (
      <DataGrid
        dataSource={d.value ? d.value : []}
        allowSearch={true}
        allowFiltering={true}
        allowSorting={true}
        onRowInserted={(val) => {
          onGridInsertedUpdated(d, val);
        }}
        onRowUpdated={(val) => {
          onGridInsertedUpdated(d, val);
        }}
        onRowRemoved={(val) => {
          onGridInsertedUpdated(d, val);
        }}
      >
        <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true} />
        <Paging defaultPageSize={10} />
        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100, 200]} />
        <Column caption={t("{{key, capitalize}}", { key: "authority.group" })} dataField="id">
          <Lookup dataSource={rolesDataSource} displayExpr="description" valueExpr="id" />
          <RequiredRule />
        </Column>
      </DataGrid>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("role") + " " + t("lists")}
        icon="verified_user"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          rowAlternationEnabled={true}
          onInitNewRow={() => {
            authoritiesData.forEach((item) => {
              item.selected = false;
            });
          }}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <RemoteOperations filtering={true} sorting={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item location="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("roleUpdate")}
            allowAdding={authProvider.isAuth("roleSave")}
            allowDeleting={authProvider.isAuth("roleDelete")}
          >
            <Popup title={t("{{key, capitalize}}", { key: "role.information" })} showTitle={true} />
            <Form>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "general.information" })}
              >
                <Item dataField="name" />
                <Item dataField="description" />
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "role.permission.information" })}
              >
                <Item dataField="roles" />
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "authorities.information" })}
                colSpan={2}
              >
                <Item dataField="roleAuthorities" />
              </Item>
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="name"
            dataType="string"
            sortOrder="asc"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "description" })}
            dataField="description"
            dataType="string"
          >
            <RequiredRule />
          </Column>

          <Column
            caption=" "
            visible={false}
            allowSearch={false}
            allowFiltering={false}
            dataField="roles"
            editCellRender={editGridRolesCell}
          />

          <Column
            caption=" "
            visible={false}
            allowSearch={false}
            allowFiltering={false}
            dataField="roleAuthorities"
            editCellRender={editGridRoleAuthoritiesCell}
          />
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RolePage;
