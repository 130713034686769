import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Form,
  Item,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  Popup,
  RequiredRule,
  SearchPanel,
  Toolbar,
} from "devextreme-react/data-grid";
import Popup1, { Position } from "devextreme-react/popup";
import Button from "devextreme-react/button";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import UserService from "./UserService";
import RoleService from "../role/RoleService";
import DepartmentTreeList from "../../departments/departmentTreeList";
import { useTranslation } from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import UserOperationService from "./UserOperationService";
import ScrollView from "devextreme-react/scroll-view";

const authProvider = AuthProvider();
const userService = UserService();
const roleService = RoleService();
const userOperationService = UserOperationService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return userService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return userService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.personType = "Person";
      return userService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return userService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return userService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

const roleDataSource = new CustomStore({
  key: "id",
  load: () => {
    return roleService.findAll().then((response: any) => {
      return {
        data: response,
        totalCount: response.length,
      };
    });
  },
  byKey: (key: any) => {
    return roleService.findOne(key).then((response: any) => {
      return response;
    });
  },
});

function onEditorPreparing(e) {
  if (e.dataField === "note") {
    e.editorName = "dxTextArea";
  }
}

function UserPage() {
  const { t } = useTranslation();
  const [dataGrid, setDataGrid] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [openUserOperationPopup, setOpenUserOperationPopup] = useState(false);
  const [userOperationDataGrid, setUserOperationDataGrid] = useState();

  const userOperationDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      loadOptions.filter = ["user.id", "=", selectedRow.id];
      return userOperationService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return userOperationService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.user = selectedRow;
      return userOperationService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return userOperationService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return userService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  });

  const onGridInsertedUpdated = (d, val) => {
    if (!d.value) {
      d.setValue([val.data]);
    } else {
      d.setValue(d.value);
    }
  };
  const editGridDepartmentRolesCell = (d) => {
    return (
      <DataGrid
        dataSource={d.value ? d.value : []}
        allowSearch={true}
        allowFiltering={true}
        allowSorting={true}
        onRowInserted={(val) => {
          onGridInsertedUpdated(d, val);
        }}
        onRowUpdated={(val) => {
          onGridInsertedUpdated(d, val);
        }}
        onRowRemoved={(val) => {
          onGridInsertedUpdated(d, val);
        }}
      >
        <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true} />
        <Paging defaultPageSize={10} />
        <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
        <Column caption={t("{{key, capitalize}}", { key: "role" })} dataField="role.id">
          <Lookup dataSource={roleDataSource} displayExpr="description" valueExpr="id" />
          <RequiredRule />
        </Column>

        <Column
          caption={t("{{key, capitalize}}", { key: "department" })}
          dataField="department"
          cellRender={(cr) => {
            return <div>{cr.data.department.name}</div>;
          }}
          editCellRender={(ecr) => {
            return (
              <DepartmentTreeList
                value={ecr.value && ecr.value.id ? ecr.value.id : null}
                onValueChanged={(e) => {
                  ecr.setValue(e);
                  // console.log('afterHandleChange', e, ecr);
                }}
              />
            );
          }}
        >
          <RequiredRule />
        </Column>
      </DataGrid>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("user") + " " + t("lists")}
        icon="supervisor_account"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item location="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("userUpdate")}
            allowAdding={authProvider.isAuth("userSave")}
            allowDeleting={authProvider.isAuth("userDelete")}
          >
            <Popup title={t("{{key, capitalize}}", { key: "user.information" })} showTitle={true} />
            <Form>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "general.information" })}
              >
                <Item dataField="enabled" />
                <Item dataField="username" />
                <Item dataField="password" />
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "person.information" })}
              >
                <Item dataField="person.identityNumber" />
                <Item dataField="person.name" />
                <Item dataField="person.surName" />
              </Item>
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "role.information" })}
                colSpan={2}
              >
                <Item dataField="userDepartmentRoles" />
              </Item>
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
          <Column
            caption=""
            dataField="id"
            dataType="number"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            allowEditing={false}
            cellRender={(item) => (
              <Button
                type="default"
                icon="bulletlist"
                onClick={() => {
                  console.log(item.data);
                  setSelectedRow(item.data);
                  setOpenUserOperationPopup(true);
                }}
              />
            )}
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "active" })}
            dataField="enabled"
            dataType="boolean"
            width={120}
          >
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "username" })}
            dataField="username"
            dataType="string"
            visible={false}
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "password" })}
            dataField="password"
            dataType="string"
            visible={false}
            allowSearch={false}
            allowFiltering={false}
          >
            {/*<RequiredRule />*/}
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "identity.number" })}
            dataField="person.identityNumber"
            dataType="string"
            sortOrder="asc"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="person.name"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "surname" })}
            dataField="person.surName"
            dataType="string"
          >
            <RequiredRule />
          </Column>

          <Column
            caption=" "
            dataField="userDepartmentRoles"
            visible={false}
            allowSearch={false}
            allowFiltering={false}
            editCellRender={editGridDepartmentRolesCell}
          />
        </DataGrid>
      </SuiBox>
      <Footer />
      <Popup1
        visible={openUserOperationPopup}
        onHiding={() => setOpenUserOperationPopup(false)}
        showCloseButton={true}
        showTitle={true}
        title={
          selectedRow
            ? (selectedRow.person.name !== undefined ? selectedRow.person.name: ' ') +
              " " +
              (selectedRow.person.surName !== undefined ? selectedRow.person.surName: ' ') +
              " " +
              t("{{key, capitalize}}", { key: "user.operation" })
            : t("{{key, capitalize}}", { key: "user.operation" })
        }
        closeOnOutsideClick={true}
        width={"65%"}
        height={"60%"}
      >
        <Position my="center" />
        {selectedRow ? (
          <ScrollView>
            <DataGrid
              dataSource={userOperationDataSource}
              showBorders={false}
              remoteOperations={false}
              onEditorPreparing={onEditorPreparing}
              rowAlternationEnabled={true}
              ref={(ref) => {
                setUserOperationDataGrid(ref);
              }}
            >
              <FilterRow visible={true} />
              <ColumnChooser enabled={false} />
              <LoadPanel enabled={true} />
              <Toolbar>
                <Item department="after">
                  <Button
                    icon="refresh"
                    onClick={() => {
                      userOperationDataGrid.instance.refresh();
                    }}
                  />
                </Item>
                <Item name="addRowButton" />
                <Item name="searchPanel" />
                <Item name="columnChooserButton" />
              </Toolbar>
              <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
              <Editing
                mode="popup"
                allowUpdating={authProvider.isAuth("userOperationUpdate")}
                allowAdding={authProvider.isAuth("userOperationSave")}
                allowDeleting={authProvider.isAuth("userOperationDelete")}
              >
                <Popup
                  title={t("{{key, capitalize}}", { key: "user.operation" })}
                  showTitle={true}
                />
                <Form>
                  <Item dataField="customerName" />
                  <Item dataField="address" />
                  <Item dataField="phoneNumber" />
                  <Item dataField="sellDate" />
                  <Item dataField="note" colSpan={2} />
                </Form>
              </Editing>
              <Paging defaultPageSize={5} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 15, 20, 50, 100]}
                showInfo={true}
              />

              <Column
                caption={t("{{key, capitalize}}", { key: "customerName" })}
                dataField="customerName"
              >
                <RequiredRule />
              </Column>
              <Column caption={t("{{key, capitalize}}", { key: "address" })} dataField="address">
                <RequiredRule />
              </Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "phoneNumber" })}
                dataField="phoneNumber"
              >
                <RequiredRule />
              </Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "sellDate" })}
                dataField="sellDate"
                dataType="date"
                format={"dd.MM.yyyy"}
                sortOrder="desc"
                width={150}
              >
                <RequiredRule />
              </Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "note" })}
                dataField="note"
                visible={false}
              >
                <RequiredRule />
              </Column>
            </DataGrid>
          </ScrollView>
        ) : (
          <></>
        )}
      </Popup1>
    </DashboardLayout>
  );
}

export default UserPage;
