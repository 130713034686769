import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import DeviceService from "./DeviceService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DepartmentTreeList from "../../share/departments/departmentTreeList";
import { FileUploader } from "devextreme-react";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dialog, DialogTitle, Icon, ListItemIcon } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import SshViewer from "../ssh/SshViewer";

const authProvider = AuthProvider();
const deviceService = DeviceService();

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return deviceService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return deviceService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.personType = "Person";
      return deviceService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return deviceService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return deviceService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function DevicePage() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const [openDialog, setOpenDialog] = useState(false);

  const [deviceItem, setDeviceItem] = useState();

  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar title={t("device") + " " + t("lists")} icon="devices" fontSize="medium" />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("deviceUpdate")}
            allowAdding={authProvider.isAuth("deviceSave")}
            allowDeleting={authProvider.isAuth("deviceDelete")}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "device.information" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="code" />
              <Item dataField="name" />
              <Item dataField="department" />
              <Item dataField="image" editorType="dxFileUploader" />
              <Item dataField="txPowerDbm" />
              <Item dataField="readerId" />
              <Item dataField="invoiceDate" editorType="dxDateBox" />
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

          <Column
            caption=""
            dataField="id"
            dataType="numeric"
            width="80"
            allowSearch={false}
            allowFiltering={false}
            cellRender={(item) => {
              return (
                <>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(evt) => {
                      setDeviceItem(item.data);
                      handleClick(evt);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpenDialog(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <Icon fontSize="small">terminal</Icon>
                      </ListItemIcon>
                      <Typography variant="inherit">ssh</Typography>
                    </MenuItem>
                  </Menu>
                </>
              );
            }}
          />

          <Column
            caption={t("{{key, capitalize}}", { key: "code" })}
            dataField="code"
            dataType="string"
            sortOrder="asc"
            width={200}
          >
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="name"
            dataType="string"
          >
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "department" })}
            dataField="department"
            allowSearch={false}
            allowSorting={false}
            allowFiltering={false}
            visible={false}
            editCellRender={(ecr) => {
              return (
                <DepartmentTreeList
                  value={ecr.value && ecr.value.id ? ecr.value.id : null}
                  onValueChanged={(e) => {
                    ecr.setValue(e);
                  }}
                />
              );
            }}
          >
            <RequiredRule />
          </Column>

          <Column
            caption={t("{{key, capitalize}}", { key: "department" })}
            dataField="department.name"
            dataType="string"
            cellRender={(cr) => {
              return <div>{cr.data.department.name}</div>;
            }}
          >

          </Column>

          <Column
            dataField="image"
            caption={t("{{key, capitalize}}", { key: "image" })}
            visible={false}
            editCellRender={(d) => {
              return (
                <FileUploader
                  selectButtonText={t("{{key, capitalize}}", { key: "select.image" })}
                  labelText={t("drop.image")}
                  uploadMode="useForm"
                  onValueChanged={(val) => {
                    d.setValue(null);
                    d.setValue(val.value[0]);
                  }}
                />
              );
            }}
            allowSearch={false}
            allowFiltering={false}
          />

          <Column
            caption={t("{{key, capitalize}}", { key: "power" })}
            dataField="txPowerDbm"
            dataType="number"
            visible={false}
          />
          <Column
              caption={t("{{key, capitalize}}", {key: "reader ID"})}
              dataField="readerId"
              dataType="string"
              visible={true}

          > <RequiredRule/>
          </Column>
          <Column
              caption={t("{{key, capitalize}}", {key: "invoice Date"})}
              dataField="invoiceDate"
              dataType="date"
              format={"dd.MM.yyyy"}
              visible={true}
          >
            <RequiredRule/>
          </Column>
        </DataGrid>
      </SuiBox>
      <Footer />

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenDialog(false);
          }}
        >
          {deviceItem ? deviceItem.name : ""}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <SshViewer item={deviceItem} />
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default DevicePage;
