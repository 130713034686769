import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "../../../../../assets/theme/base/breakpoints";
import SuiTypography from "../../../../../components/SuiTypography";
import SuiBox from "../../../../../components/SuiBox";
import Card from "@mui/material/Card";
import { Tooltip } from "@mui/material";
import SuiInput from "../../../../../components/SuiInput";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import DataGrid, {
  Column,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { sendWebSocketMessage } from "../../../../../providers/WebSocketProvider";
import { LoadPanel } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { getLangLocalStorage } from "../../../../../providers/LanguageProvider";
import SuiSnackbar from "../../../../../components/SuiSnackbar";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import GlobalInspectionService from "../../GlobalInspectionService";
import { ESection } from "../../../../../providers/AppConstantProvider";
import VendorService from "../../../vendor/VendorService";
import CompanyPrefixService from "../../../companyprefix/CompanyPrefixService";

const inspectionService = GlobalInspectionService();
const vendorService = new CompanyPrefixService();


const inspectionDataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return inspectionService.findAll(loadOptions).then(
        (response) => {
          response.items.forEach((item) => {
            item.totalCount = response.totalCount;
          });
          return {
            data: response.items,
            totalCount: response.totalCount,
          };
        },
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function InspectionPage({ vendor, device }) {
  const timeOutIdRef = useRef();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabIndex, setTabIndex] = useState(0);
  const tabIndexRef = useRef(0);

  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [difference, setDifference] = useState(false);
  const [showLoadPanel, setShowLoadPanel] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const toggleSnackbar = () => setShowSnackbar(false);
  const [snackbarContent, setSnackbarContent] = useState("");

  const [po, setPo] = useState();
  const poRef = useRef();
  const [quantity, setQuantity] = useState();
  const quantityRef = useRef();

  const [model, setModel] = useState();
  const [color, setColor] = useState();
  const [quality, setQuality] = useState();

  const [inspectionType, setInspectionType] = useState("globalInspection");

  const [dataGrid, setDataGrid] = useState();

  const [vendorData, setVendorData] = useState();

  // const [a1, setA1] = useState();
  // const [a2, setA2] = useState();
  // const [a3, setA3] = useState();
  // const [a4, setA4] = useState();
  // const [a5, setA5] = useState();
  // const [a6, setA6] = useState();
  const [errorVariable, setErrorVariable] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const returnInspection = (e) => {
    setShowLoadPanel(false);
    if (timeOutIdRef.current) {
      clearTimeout(timeOutIdRef.current);
    }
    const data = e.detail;
    console.log("returnInspection", data);
    setShowSnackbar(false);
    setSnackbarContent("");

    switch (data.command) {
      case "startAnswer":
        setShowLoadPanel(true);
        timeOutIdRef.current = setTimeout(() => {
          notify({
            message: t("notFoundTag"),
            type: "error",
            displayTime: 3000,
            height: 50,
            shading: false,
          });
          sendMessage("stopOffer", getTargetData());
          // setShowLoadPanel(false);
        }, 15000);
        break;
      case "stopAnswer":
      case "refreshAnswer":
      case "open":
      case "close":
        break;
      case "saveNextAnswer":
        notify({
          message: data.data,
          type: "success",
          displayTime: 3000,
          height: 50,
          shading: false,
        });
        inspectionDataSource.filter(["po", "=", poRef.current]);
        inspectionDataSource.reload();
        clear();
        break;
      case "error":
        setShowSnackbar(true);
        setSnackbarContent(t(data.data));
        break;
      case "data":
        const sizeData = beautifullyData(data.data);
        setData(sizeData);
        // setAssortments(sizeData);
        break;
      default:
    }
  };
  //TODO
  const beautifullyData = (dataObject) => {
    setErrorVariable(0);
    let tempGtin, tempErrorVariable;
    return dataObject
      .reduce(
        (res, obj) => {
          if (obj.gtin !== null) {
            if (tempGtin === undefined) {
              tempGtin = obj.gtin;
            }
            const key = obj.gtin;

            if (tempGtin !== obj.gtin) {
              tempErrorVariable = tempErrorVariable + 1;
              setErrorVariable(tempErrorVariable);
            }
            if (!(key in res)) {
              res.__array.push(
                (res[key] = {
                  gtin: obj.gtin,
                  brand: obj.brand,
                  companyPrefix: obj.companyPrefix,
                  counted: 1,
                  expected: tabIndexRef.current === 0 ? quantityRef.current : 1,
                })
              );
            } else {
              res[key].counted += 1;
              res[key].expected =
                tabIndexRef.current === 0 ? quantityRef.current : res[key].counted;
            }
          }
            return res;
        },
        { __array: [] }
      )
      .__array.sort(function (a, b) {
        return a.size - b.size;
      });
  };

  const gcd = (...arr) => {
    if ([...arr].length > 0) {
      const _gcd = (x, y) => (!y ? x : gcd(y, x % y));
      return [...arr].reduce((a, b) => _gcd(a, b));
    }
  };

  // const setAssortments = (sizeData) => {
  //   const ebob = gcd(...sizeData.map((v) => v.counted));
  //   sizeData.forEach((item, index) => {
  //     switch (index) {
  //       case 0:
  //         setA1((item.counted / ebob).toFixed(0));
  //         break;
  //       case 1:
  //         setA2((item.counted / ebob).toFixed(0));
  //         break;
  //       case 2:
  //         setA3((item.counted / ebob).toFixed(0));
  //         break;
  //       case 3:
  //         setA4((item.counted / ebob).toFixed(0));
  //         break;
  //       case 4:
  //         setA5((item.counted / ebob).toFixed(0));
  //         break;
  //       case 5:
  //         setA6((item.counted / ebob).toFixed(0));
  //         break;
  //       default:
  //     }
  //   });
  // };

  useEffect(() => {
    window.addEventListener("inspection", returnInspection);
    vendorService.findAll({}).then((r) => {
      console.log(r);
      setVendorData(r.items);
    });
    return () => {
      window.removeEventListener("inspection", returnInspection);
    };
  }, []);

  const sendMessage = (cmd, d) => {
    setShowLoadPanel(true);
    getLangLocalStorage().then((language) => {
      sendWebSocketMessage({ command: cmd, lang: language, data: d ? d : getTargetData() });
    });
    timeOutIdRef.current = setTimeout(() => {
      notify({
        message: t("sureDeviceTurnOn"),
        type: "error",
        displayTime: 3000,
        height: 50,
        shading: false,
      });
      setShowLoadPanel(false);
    }, 15000);
  };

  const getTargetData = () => {
    console.log("device", device, po);
    return {
      txPowerDbm: device.txPowerDbm,
      po: poRef.current,
      quantity: quantityRef.current,
      vendorCode: vendor.prefix,
      color: color,
      quality: quality,
      expected: quantityRef.current,
      model: model,
      inspectionType: inspectionType,
    };
  };

  const startOffer = () => {
    console.log("startOffer");
    clear();
    sendMessage("startOffer", getTargetData());
  };

  const clear = () => {
    setData({});
  };

  return (
    <>
      <Card>
        <SuiBox p={2} mt={1} width="100%">
          <Grid container spacing={3}>
            <Grid item xs={4} md={2}>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography
                    textTransform="capitalize"
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    PO*
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  inputProps={{ type: "text" }}
                  placeholder="PO*"
                  icon={{
                    component: "beenhere",
                    direction: "left",
                  }}
                  onChange={(e) => {
                    setPo(e.target.value);
                    poRef.current = e.target.value;
                    clear();
                    dataGrid.clearFilter();
                    inspectionDataSource.filter(["po", "=", poRef.current]);
                    inspectionDataSource.reload();
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={4} md={2}>
              <SuiBox mb={2} lineHeight={1.25}>
                <SuiBox mb={1} ml={0.5}>
                  <SuiTypography
                    textTransform="capitalize"
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("{{key, capitalize}}", { key: "targetQuantity" })}
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  inputProps={{ type: "number" }}
                  placeholder={t("{{key, capitalize}}", { key: "targetQuantity" })}
                  icon={{
                    component: "beenhere",
                    direction: "left",
                  }}
                  // value={quantity}
                  onChange={(e) => {
                    setQuantity(Number(e.target.value));
                    quantityRef.current = Number(e.target.value);
                    // clear();
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" width="60%">
            <SuiBox width="80%">
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabIndex}
                  onChange={(event, tabIndex) => {
                    tabIndexRef.current = tabIndex;
                    setTabIndex(tabIndex);
                    if (tabIndex === 0) setInspectionType("globalInspection");
                    // else setInspectionType("globalInspection");
                  }}
                >
                  <Tab label={t("{{key, capitalize}}", { key: "freeReading" })} />
                  {/*<Tab label={t("{{key, capitalize}}", {key: "assortmentChecking"})}/>*/}
                </Tabs>
              </AppBar>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <SuiBox p={2} mt={0} width="100%" sx={{ minHeight: "20.25rem" }}>
          <SuiBox
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SuiBox sx={{ marginRight: "auto" }}>
              <DataGrid
                showBorders={false}
                remoteOperations={false}
                rowAlternationEnabled={true}
                dataSource={data}
              >
                <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
                <Column dataField="companyPrefix" caption={t("{{key, capitalize}}", { key: "brand" })}>
                  <Lookup dataSource={vendorData} displayExpr="name" valueExpr="prefix" />
                </Column>
                <Column dataField="gtin" caption={t("{{key, capitalize}}", { key: "gtin" })} />
                <Column
                  dataField="expected"
                  caption={t("{{key, capitalize}}", { key: "expected" })}
                  dataType="number"
                />
                <Column
                  dataField="counted"
                  caption={t("{{key, capitalize}}", { key: "counted" })}
                  dataType="number"
                />
                <Column
                  dataField="difference"
                  caption={t("{{key, capitalize}}", { key: "difference" })}
                  dataType="number"
                  cellRender={(cellData) => {
                    return (
                      <div>
                        {cellData.value === 0 ? (
                          <SuiTypography variant="body2" color="dark">
                            {cellData.value}
                          </SuiTypography>
                        ) : (
                          <SuiTypography variant="body2" color="error">
                            {cellData.value}
                          </SuiTypography>
                        )}
                      </div>
                    );
                  }}
                  calculateCellValue={(rowData) => {
                    const val = rowData.counted - rowData.expected;
                    if (data && data.length > 0) {
                      const totalCounted = data
                        .map((item) => item.counted)
                        .reduce((acc, item) => item + acc);
                      const totalExpected = data
                        .map((item) => item.expected)
                        .reduce((acc, item) => item + acc);
                      debugger;
                      if (tabIndex === 0) {
                        setDifference(
                          totalCounted === totalExpected &&
                            totalCounted === quantity &&
                            (errorVariable === 0 || isNaN(errorVariable))
                        );
                      } else {
                        setDifference(
                          totalCounted === totalExpected &&
                            totalCounted === quantity &&
                            data.length === 1 &&
                            errorVariable === 0
                        );
                      }
                    }
                    return val;
                  }}
                />

                <Summary>
                  <TotalItem
                    column="counted"
                    summaryType="sum"
                    displayFormat={
                      t("{{key, capitalize}}", { key: "sum" }) +
                      ": {0} " +
                      t("{{key, capitalize}}", { key: "number" })
                    }
                  />
                </Summary>
              </DataGrid>
            </SuiBox>
            <SuiBox>
              <SuiBox display="flex" alignItems="center">
                <SuiBox
                  sx={{ cursor: "pointer" }}
                  lineHeight={0}
                  onClick={() => {
                    // setData({});
                    sendMessage("stopOffer", getTargetData());
                  }}
                >
                  <Tooltip title={t("{{key, capitalize}}", { key: "stop" })} placement="top" arrow>
                    <CardMedia
                      component="img"
                      height="60"
                      image="/images/inspection/stop.png"
                      alt="stop"
                    />
                  </Tooltip>
                </SuiBox>

                {po != null && quantity != null && quantity > 0 ? (
                  <SuiBox
                    sx={{ cursor: "pointer" }}
                    lineHeight={0}
                    onClick={() => {
                      startOffer();
                    }}
                  >
                    <Tooltip
                      title={t("{{key, capitalize}}", { key: "start" })}
                      placement="top"
                      arrow
                    >
                      <CardMedia
                        component="img"
                        height="60"
                        image="/images/inspection/play.png"
                        alt="start"
                      />
                    </Tooltip>
                  </SuiBox>
                ) : null}
                {po != null && quantity != null && data.length > 0 && difference ? (
                  <SuiBox display="flex" alignItems="center">
                    <SuiBox
                      sx={{ cursor: "pointer" }}
                      lineHeight={0}
                      onClick={() => {
                        sendMessage("saveNextOffer", null);
                      }}
                    >
                      <Tooltip
                        title={t("{{key, capitalize}}", { key: "saveNext" })}
                        placement="top"
                        arrow
                      >
                        <CardMedia
                          component="img"
                          height="60"
                          image="/images/inspection/save.png"
                          alt="save and next"
                        />
                      </Tooltip>
                    </SuiBox>
                  </SuiBox>
                ) : null}
              </SuiBox>
              {data.length > 0 ? (
                <CardMedia
                  sx={{ ml: 10, mt: 4, width: 120 }}
                  component="img"
                  image={
                    difference
                      ? "/images/inspection/approved.png"
                      : "/images/inspection/rejected.png"
                  }
                  alt="green iguana"
                />
              ) : null}
            </SuiBox>
          </SuiBox>
        </SuiBox>

        <SuiBox p={2} mt={1} width="100%">
          <DataGrid
            showBorders={false}
            remoteOperations={true}
            rowAlternationEnabled={true}
            dataSource={inspectionDataSource}
            ref={(ref) => {
              if (ref) {
                setDataGrid(ref.instance);
              }
            }}
          >
            <SearchPanel visible={false} />
            <Paging enabled={false} />
            <Column dataField="id" caption="id" filterValue={0} width={0} />
            <Column
              dataField="boxNumber"
              caption={t("{{key, capitalize}}", { key: "boxNumber" })}
              width={120}
            />
            {/*<Column dataField="vendor.name" caption={t("{{key, capitalize}}", { key: "brand" })} />*/}
            {/*<Column dataField="section" caption={t("{{key, capitalize}}", { key: "department" })}>*/}
            {/*  <Lookup*/}
            {/*    dataSource={ESection}*/}
            {/*    displayExpr={(data) => {*/}
            {/*      return t("{{key, capitalize}}", { key: data.label });*/}
            {/*    }}*/}
            {/*    valueExpr="value"*/}
            {/*  />*/}
            {/*</Column>*/}
            <Column dataField="gtin" caption={t("{{key, capitalize}}", { key: "gtin" })} />
            {/*<Column dataField="boxNumber" caption={t("{{key, capitalize}}", { key: "boxNumber" })} />*/}
            <Column dataField="quantity" caption={t("{{key, capitalize}}", { key: "quantity" })} />
          </DataGrid>
        </SuiBox>
      </Card>
      <LoadPanel
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={true}
      />
      {snackbarContent !== "" ? (
        <SuiSnackbar
          sx={{ maxWidth: 400 }}
          color="error"
          icon="error"
          title={t("{{key, capitalize}}", { key: "error" })}
          content={snackbarContent}
          dateTime=""
          autoHideDuration={10000}
          open={showSnackbar}
          onClose={toggleSnackbar}
          close={toggleSnackbar}
        />
      ) : null}
    </>
  );
}

export default InspectionPage;
