import React, { useEffect, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  Pager,
  Paging,
  SearchPanel,
  Editing,
  Form,
  Toolbar,
  Item,
  ColumnChooser,
  LoadPanel,
  RequiredRule,
  FilterRow,
  Lookup,
  Popup,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../../providers/AuthProvider";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import SgtinTypeService from "./SgtinTypeService";

const authProvider = AuthProvider();
const sgtinTypeService = SgtinTypeService();

const formatOptions = [
  { id: "96 bits", name: "96 bits" },
  // { id: "198 bits", name: "198 bits" },
];

const serialOptions = [
  { id: "Random", name: "Random" },
  { id: "Sequential", name: "Sequential" },
];

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return sgtinTypeService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return sgtinTypeService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      values.serialNo = dataSource.serialNo;
      return sgtinTypeService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.serialNo = dataSource.serialNo;
      values.id = key;
      return sgtinTypeService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return sgtinTypeService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function SgtinTypesPage() {
  const [dataGrid, setDataGrid] = useState();
  const { t } = useTranslation();
  const [valueVisible, setValueVisible] = useState(true);
  const [isNewRow, setIsNewRow] = useState(false);

  const handleSerialNoChange = (e) => {
    if (e.event?.preventDefault) {
      e.event.preventDefault();
    }
    setValueVisible(e.value !== "Sequential");
    dataSource.serialNo = e.value;
  };
  const onInitNewRow = () => {
    setIsNewRow(true);
    setValueVisible(true);
  };

  const onEditingStart = (e) => {
    setIsNewRow(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("productTypes") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            setDataGrid(ref);
          }}
          onInitNewRow={onInitNewRow}
          onEditingStart={onEditingStart}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            allowUpdating={authProvider.isAuth("sgtinTypeUpdate")}
            allowAdding={authProvider.isAuth("sgtinTypeSave")}
            allowDeleting={authProvider.isAuth("sgtinTypeDelete")}
          >
            <Popup title={t("{{key, capitalize}}", { key: "sgtin.type.title" })} showTitle={true} />

            <Form>
                <Item dataField="indexName" colSpan={2} />
                <Item
                  dataField="format"
                  colSpan={2}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: formatOptions,
                    displayExpr: "name",
                    valueExpr: "id",
                    placeholder: "Select a format",
                    disabled: !isNewRow,
                  }}
                />
                <Item
                  dataField="filterValue"
                  colSpan={2}
                  editorType="dxNumberBox"
                  editorOptions={{
                    min: 0,
                    max: 7,
                    valueExpr: "id",
                    showSpinButtons: true,
                    placeholder: "Enter a filter value",
                    disabled: !isNewRow,
                    onKeyDown: (e) => {
                      const allowedKeys = ["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight"];
                      const value = parseInt(e.event.key, 10);
                      const inputValue = e.component.option("text");
                      if (
                        !allowedKeys.includes(e.event.key) &&
                        (inputValue.length >= 1 || isNaN(value) || value < 0 || value > 7)
                      ) {
                        e.event.preventDefault();
                      }
                    },
                  }}
                />
              <Item
                dataField="serialNo"
                colSpan={2}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: serialOptions,
                  displayExpr: "name",
                  valueExpr: "id",
                  onValueChanged: handleSerialNoChange,
                  placeholder: "Select Serial Type",
                  disabled: !isNewRow,
                }}
              />
                <Item
                  dataField="startValue"
                  colSpan={2}
                  editorType="dxNumberBox"
                  editorOptions={{
                    placeholder: "Enter start value",
                    disabled: valueVisible,
                    onKeyDown: (e) => {
                      const allowedKeys = ["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight"];
                      const value = parseInt(e.event.key, 10);
                      if (!allowedKeys.includes(e.event.key) && (isNaN(value) || value < 0)) {
                        e.event.preventDefault();
                      }
                    },
                    onInput: (e) => {
                      const inputValue = parseInt(e.component.option("text"), 10);
                      if (inputValue < 0) {
                        e.component.option("text", "");
                      }
                    },
                  }}
                />
                <Item
                  dataField="endValue"
                  colSpan={2}
                  editorType="dxNumberBox"
                  editorOptions={{
                    placeholder: "Enter end value",
                    disabled: valueVisible,
                    onKeyDown: (e) => {
                      const allowedKeys = ["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight"];
                      const value = parseInt(e.event.key, 10);
                      if (!allowedKeys.includes(e.event.key) && (isNaN(value) || value <= 0)) {
                        e.event.preventDefault();
                      }
                    },
                    onInput: (e) => {
                      const inputValue = parseInt(e.component.option("text"), 10);
                      if (inputValue <= 0) {
                        e.component.option("text", "");
                      }
                    },
                  }}
                />
              </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
          <Column
            caption={t("{{key, capitalize}}", { key: "name" })}
            dataField="indexName"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column caption={t("Format")} dataField="format" dataType="string">
            <RequiredRule />
          </Column>
          <Column caption={t("Filter Value")} dataField="filterValue" dataType="number">
            <RequiredRule />
          </Column>
          <Column caption={t("Serial No")} dataField="serialNo" dataType="string"></Column>
          <Column caption={t("Start Value")} dataField="startValue" dataType="string"></Column>
          <Column caption={t("End Value")} dataField="endValue" dataType="string"></Column>
        </DataGrid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SgtinTypesPage;
