import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import SuiBox from "../../../../components/SuiBox";
import Footer from "../../../../examples/Footer";
import ReportListService from "./ReportListService";
import { Dialog, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import ReportViewer from "../viewer";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { LoadPanel } from "devextreme-react";
import { useTranslation } from "react-i18next";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";

const reportListService = ReportListService();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function ReportListPage() {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [reportItem, setReportItem] = useState();
  const [showLoadPanel, setShowLoadPanel] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    reportListService
      .findAll({})
      .then((response) => {
        setShowLoadPanel(false);
        setData(response.items);
      })
      .catch((err) => {
        setShowLoadPanel(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("report") + " " + t("lists")}
        icon="assessment"
        fontSize="medium"
        onChangeSearch={(e) => {
          setShowLoadPanel(true);
          reportListService
            .findAll(
              e
                ? {
                    filter: [["title", "contains", e], "or", ["description", "contains", e]],
                  }
                : {}
            )
            .then((response) => {
              setShowLoadPanel(false);
              setData(response.items);
            })
            .catch((err) => {
              setShowLoadPanel(false);
            });
        }}
      />
      <SuiBox p={2} shadow="none" bgColor="transparent">
        <Grid container mt={2} spacing={0} bgcolor="transparent">
          {data &&
            data.map((val) => {
              return (
                <Grid
                  title={t("{{key, capitalize}}", { key: "show.report" })}
                  style={{ cursor: "pointer" }}
                  component={Card}
                  item
                  key={val.id}
                  mr={2}
                  mb={1}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  onClick={() => {
                    setReportItem(val);
                    setOpen(true);
                  }}
                >
                  <CardMedia
                    style={{ marginBottom: 14 }}
                    component="img"
                    height="140"
                    image={
                      val.contentId
                        ? process.env.REACT_APP_API_URL +
                          "binaryContents/getPublicImage/" +
                          val.contentId
                        : "images/report/report-icon-13332.png"
                    }
                    alt="image"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {val.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {val.description}
                    </Typography>
                  </CardContent>
                </Grid>
              );
            })}
        </Grid>
      </SuiBox>
      <Footer />

      <BootstrapDialog
        fullScreen
        onClose={() => {
          setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpen(false);
          }}
        >
          {reportItem && reportItem.title}
        </BootstrapDialogTitle>
        <DialogContent>
          <h4>{reportItem && reportItem.description}</h4>
          <ReportViewer item={reportItem} />
        </DialogContent>
      </BootstrapDialog>

      <LoadPanel
        visible={showLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={true}
      />
    </DashboardLayout>
  );
}

export default ReportListPage;
