import AuthProvider from "../../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "inspecitonDetail/";

function InspectionRecordsService() {
  const findAll = async (loadOptions) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loadOptions),
    };
    return authProvider.authFetch(basePath + "findAll", requestOptions);
  };
  const deleteOne = async (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: id,
    };
    return authProvider.authFetch(basePath + "delete", requestOptions);
  };
  const sendToInditex = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return authProvider.authFetch(basePath + "sendToInditex", requestOptions);
  };
  const removeInditexRecord = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return authProvider.authFetch(basePath + "removeInditexRecord", requestOptions);
  };
  return {
    findAll,
    deleteOne,
    sendToInditex,
    removeInditexRecord,
  };
}

export default InspectionRecordsService;
