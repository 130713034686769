export const EContactType: any = [
  { label: "Adres", value: "Address" },
  { label: "Email", value: "Email" },
  { label: "Telefon", value: "Phone" },
  { label: "Fax", value: "Fax" },
  { label: "Dahili Hat", value: "Intercom" },
];

export const EDocumentType: any = [
  { label: "Genel", value: "Public" },
  { label: "Özel", value: "Private" },
];

export const EAnnouncementType: any = [
  { label: "Genel", value: "Public" },
  { label: "Özel", value: "Private" },
];

export const EDataType: any = [
  { label: "Sayısal", value: "Number" },
  { label: "Metin", value: "Text" },
  { label: "Tarih", value: "DateTime" },
  { label: "Mantıksal", value: "Boolean" },
];

export const EActiveType: any = [
  { label: "Göster", value: true },
  { label: "Gösterme", value: false },
];
export const ECreatedPlatform: any = [
  { label: "itx", value: true },
  { label: "local", value: false }
];
