import React, { useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  Form,
  Item,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  Popup,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import TempeValidationService from "./TempeValidationService";
import { useTranslation } from "react-i18next";
import AuthProvider from "../../../providers/AuthProvider";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SuiBox from "../../../components/SuiBox";
import Footer from "../../../examples/Footer";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TempeSizeRequestService from "./TempeSizeRequestService";
import ScrollView from "devextreme-react/scroll-view";
import TempeEpcResultService from "./TempeEpcResultService";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import notify from "devextreme/ui/notify";
import TagSubTypeService from "../tagsubtype/TagSubTypeService";
import VendorService from "../vendor/VendorService";
import { NumberBox } from "devextreme-react";
import { saveAs } from "file-saver";
import TagTypeService from "../tagtype/TagTypeService";
import SectionService from "../section/SectionService";

const authProvider = AuthProvider();
const tempeValidationService = TempeValidationService();
const tempeSizeRequestService = TempeSizeRequestService();
const tempeEpcResultService = TempeEpcResultService();
const tagTypeService = TagTypeService();
const tagSubTypeService = TagSubTypeService();
const vendorService = VendorService();
const sectionService = SectionService();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const dataSource = new DataSource({
  store: new CustomStore({
    key: "id",
    load(loadOptions: any) {
      return tempeValidationService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tempeValidationService.findOne(key).then((response) => {
        return response;
      });
    },
    insert: (values: any) => {
      return tempeValidationService.save(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    update: (key: any, values: any) => {
      values.id = key;
      return tempeValidationService.update(values).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    remove: (key: any) => {
      return tempeValidationService.deleteOne(key).then(
        () => {},
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
  }),
});

function TempeTagRecordsPage() {
  const [dataGrid, setDataGrid] = useState();
  const [tagDataGrid, setTagDataGrid] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedTagRow, setSelectedTagRow] = useState();
  const [openTagRecord, setOpenTagRecord] = useState();
  const [openEPCRecord, setOpenEPCRecord] = useState();
  const { t } = useTranslation();

  function calculateQuality(data) {
    if (data.tempeValidation.quality != null) {
      return data.tempeValidation.quality.toString().padStart(3, "0");
    }
    return "000";
  }

  function calculateBodySize(data) {
    if (data.sizeCode != null) {
      return data.sizeCode.toString().padStart(2, "0");
    }
    return "00";
  }

  function calculateColorCode(data) {
    if (data.colorCode != null) {
      return data.colorCode.toString().padStart(3, "0");
    }
    return "000";
  }

  function calculateModel(data) {
    if (data.tempeValidation.model != null) {
      return data.tempeValidation.model.toString().padStart(4, "0");
    }
    return "0000";
  }

  function onChangeExtraRate(e) {
    if (e != null && e !== "") {
      tagDataGrid.instance.beginCustomLoading();
      return tempeSizeRequestService.changeExtraRate(selectedRow, e).then(
        () => {
          tagDataGrid.instance.endCustomLoading();
          tagDataGrid.instance.refresh();
        },
        (err) => {
          tagDataGrid.instance.endCustomLoading();
          tagDataGrid.instance.refresh();
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );

      // setExtraRate(e);
    } else {
      // setExtraRate(0);
    }
    // console.log(e);
  }

  function onEditorPreparing(e) {
    if (isedit)
      e.editorOptions.disabled =
        e.dataField == "tagSubType.id" || e.dataField == "tagType.id" ? false : true;
  }

  function onInitNewRow(e) {
    isNewRow = true;
    if (e.dataField === "po" && e.parentType === "dataRow") {
      e.editorOptions.disabled = e.row.data && e.row.data.po === "";
    }
  }

  function onEditCanceling(e) {
    isNewRow = false;
    isedit = false;
  }
  let isedit = false;
  function onEditingStart(e) {
    isNewRow = true;
    isedit = true;
  }

  let isNewRow = false;
  const tagSubTypeDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return tagSubTypeService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tagSubTypeService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const tagTypeDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return tagTypeService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tagTypeService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const sectionDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return sectionService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return sectionService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const vendorDataSource = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      if (isNewRow) {
        loadOptions.filter = ["view", "=", true];
      }
      return vendorService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return vendorService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const epcTags = new CustomStore({
    key: "id",
    load(loadOptions: any) {
      loadOptions.filter = ["tempeSizeRequest.id", "=", selectedTagRow.id];
      return tempeEpcResultService.findAll(loadOptions).then(
        (response) => ({
          data: response.items,
          totalCount: response.totalCount,
        }),
        (err) => {
          return err.json().then((r) => {
            throw r.errorMessage ? r.errorMessage : r.warningMessage;
          });
        }
      );
    },
    byKey: (key: any) => {
      return tempeEpcResultService.findOne(key).then((response) => {
        return response;
      });
    },
  });

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("EPC");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      includeHeader: false,

      encodeExecutableContent: true,
    }).then(function () {
      debugger;
      worksheet.spliceRows(0, 1);
      workbook.csv.writeBuffer().then((buffer) => {
        // eslint-disable-next-line no-undef
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "'" +
            selectedRow.po +
            "' - " +
            (selectedTagRow.barcode != undefined ? selectedTagRow.barcode : selectedRow.barcode) +
            // " - " +
            // selectedRow.quality.toString().padStart(3, "0") +
            // " - " +
            // selectedTagRow.colorCode.toString().padStart(3, "0") +
            // " - " +
            // selectedTagRow.sizeCode.toString().padStart(2, "0") +
            ".txt"
        );
      });
    });
    e.cancel = true;
  };

  const tagRecordDataSource = new DataSource({
    store: new CustomStore({
      key: "id",
      load(loadOptions: any) {
        loadOptions.filter = ["tempeValidation.id", "=", selectedRow.id];
        return tempeSizeRequestService.findAll(loadOptions).then(
          (response) => ({
            data: response.items,
            totalCount: response.totalCount,
          }),
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      byKey: (key: any) => {
        return tempeSizeRequestService.findOne(key).then((response) => {
          return response;
        });
      },
      insert: (values: any) => {
        return tempeSizeRequestService.save(values).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      update: (key: any, values: any) => {
        values.id = key;
        return tempeSizeRequestService.update(values).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
      remove: (key: any) => {
        return tempeSizeRequestService.deleteOne(key).then(
          () => {},
          (err) => {
            return err.json().then((r) => {
              throw r.errorMessage ? r.errorMessage : r.warningMessage;
            });
          }
        );
      },
    }),
  });

  let barcodeNumber;

  function setBarcodeValue(newData, value, currentRowData) {
    let controlBit;
    barcodeNumber = value.toString();
    newData.barcode = value;
    if (barcodeNumber.length === 14) {
      controlBit = barcodeNumber.substring(13, 14);
      barcodeNumber = barcodeNumber.substring(0, 13);

      var sum = 0;
      var factor = 3;

      for (var i = barcodeNumber.length; i > 0; --i) {
        let temp = parseInt(barcodeNumber.substring(i - 1, i));
        sum += temp * factor;
        factor = 4 - factor;
      }

      if (((10 - (sum % 10)) % 10).toString() === controlBit) {
        newData.model = barcodeNumber.substring(1, 5);
        newData.quality = barcodeNumber.substring(5, 8);
        notify({
          message: t("barcode.parse.success"),
          type: "success",
          displayTime: 3000,
          height: 50,
          shading: false,
        });
      } else {
        newData.model = 0;
        newData.quality = 0;
        notify({
          message: t("barcode.parse.failed"),
          type: "error",
          displayTime: 3000,
          height: 50,
          shading: false,
        });
      }
    } else {
      newData.model = 0;
      newData.quality = 0;
      notify({
        message: t("barcode.entered.missing"),
        type: "error",
        displayTime: 3000,
        height: 50,
        shading: false,
      });
    }
    this.defaultSetCellValue(newData, value);
    return;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={t("section") + " " + t("lists")}
        icon="filter_none"
        fontSize="medium"
      />
      <SuiBox mt={2} p={3} shadow="md" bgColor="white" sx={{ borderRadius: 2 }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          rowAlternationEnabled={true}
          onInitNewRow={onInitNewRow}
          onEditingStart={onEditingStart}
          onEditCanceling={onEditCanceling}
          onEditorPreparing={onEditorPreparing}
          ref={(ref) => {
            setDataGrid(ref);
          }}
        >
          <FilterRow visible={true} />
          <ColumnChooser enabled={false} />
          <LoadPanel enabled={true} />
          <Toolbar>
            <Item department="after">
              <Button
                icon="refresh"
                onClick={() => {
                  dataGrid.instance.refresh();
                }}
              />
            </Item>
            <Item name="addRowButton" />
            <Item name="searchPanel" />
            <Item name="columnChooserButton" />
          </Toolbar>
          <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
          <Editing
            mode="popup"
            // allowUpdating={false}
            allowUpdating={authProvider.isAuth("tempeValidationUpdate")}
            allowAdding={authProvider.isAuth("tempeValidationSave")}
            allowDeleting={authProvider.isAuth("tempeValidationDelete")}
          >
            <Popup
              title={t("{{key, capitalize}}", { key: "tempe.order.information" })}
              showTitle={true}
            />
            <Form>
              <Item dataField="po" colSpan={2} />
              <Item dataField="tagType.id" />
              <Item dataField="tagSubType.id" />
              <Item
                itemType="group"
                caption={t("{{key, capitalize}}", { key: "manuel.entry" })}
                colCount={2}
              >
                <Item dataField="model" />
                <Item dataField="quality" />
              </Item>
              <Item itemType="group" caption={t("{{key, capitalize}}", { key: "input.barcode" })}>
                <Item dataField="barcode" />
              </Item>
            </Form>
          </Editing>
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />
          <Column
            caption=""
            dataField="id"
            dataType="number"
            sortOrder="desc"
            width={70}
            allowFiltering={false}
            allowSorting={false}
            allowEditing={false}
            cellRender={(item) => (
              <Button
                type="default"
                icon="bulletlist"
                onClick={() => {
                  setSelectedRow(item.data);
                  setOpenTagRecord(true);
                  // selectedInditexRow = item.data;
                  // setErrorDetailDialog(true);
                }}
              />
            )}
          />
          <Column
            caption={t("{{key, capitalize}}", { key: "po" })}
            dataField="po"
            dataType="string"
          >
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "barcode" })}
            dataField="barcode"
            dataType="string"
            width={150}
            setCellValue={setBarcodeValue}
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "model" })}
            dataField="model"
            dataType="number"
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "quality" })}
            dataField="quality"
            dataType="number"
          ></Column>
          {/*<Column*/}
          {/*    caption={t("{{key, capitalize}}", {key: "supplierId"})}*/}
          {/*    dataField="supplierId"*/}
          {/*    dataType="number"*/}
          {/*>*/}
          {/*  <RequiredRule/>*/}
          {/*</Column>*/}
          <Column caption={t("{{key, capitalize}}", { key: "brand" })} dataField="brand.id">
            <Lookup dataSource={vendorDataSource} displayExpr="name" valueExpr="id" />
          </Column>
          <Column caption={t("{{key, capitalize}}", { key: "section" })} dataField="section.id">
            <Lookup dataSource={sectionDataSource} displayExpr="description" valueExpr="id" />
          </Column>
          <Column caption={t("{{key, capitalize}}", { key: "tagTypeId" })} dataField="tagType.id">
            <Lookup dataSource={tagTypeDataSource} displayExpr="description" valueExpr="id" />
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "tagSubTypeId" })}
            dataField="tagSubType.id"
          >
            <Lookup dataSource={tagSubTypeDataSource} displayExpr="description" valueExpr="id" />
            <RequiredRule />
          </Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "percentMatched" })}
            dataField="percentMatched"
            dataType="number"
          ></Column>
          <Column
            caption={t("{{key, capitalize}}", { key: "percentCreated" })}
            dataField="percentCreated"
            dataType="number"
          ></Column>
        </DataGrid>
      </SuiBox>
      <Footer />

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setReportItem(null);
        }}
        aria-labelledby="customized-dialog-title"
        open={openTagRecord}
        key={11}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            // setExtraRate(0);
            setOpenTagRecord(false);
            dataGrid.instance.refresh();
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? "'" +
              selectedRow.po +
              "' " +
              "-" +
              selectedRow.model +
              " -" +
              selectedRow.quality +
              " " +
              t("{{key, capitalize}}", { key: "order.content" })
            : t("{{key, capitalize}}", { key: "order.content" })}
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <ScrollView width="100%" height="100%">
            <DataGrid
              dataSource={tagRecordDataSource}
              showBorders={false}
              remoteOperations={false}
              rowAlternationEnabled={true}
              ref={(ref) => {
                setTagDataGrid(ref);
              }}
            >
              <FilterRow visible={false} />
              <ColumnChooser enabled={false} />
              <LoadPanel enabled={true} />
              <Toolbar>
                <Item department="after">
                  <Button
                    icon="refresh"
                    onClick={() => {
                      tagDataGrid.instance.refresh();
                    }}
                  />
                </Item>
                <Item location="after">
                  <Button
                    icon="save"
                    onClick={() => {
                      tagDataGrid.instance.beginCustomLoading();
                      return tempeSizeRequestService.createAllEPC(selectedRow).then(
                        () => {
                          tagDataGrid.instance.endCustomLoading();
                          tagDataGrid.instance.refresh();
                        },
                        (err) => {
                          tagDataGrid.instance.endCustomLoading();
                          tagDataGrid.instance.refresh();
                          // return err.json().then((r) => {
                          //   throw r.errorMessage ? r.errorMessage : r.warningMessage;
                          // });
                        }
                      );
                    }}
                  />
                </Item>
                <Item location="after">
                  <Button
                    icon="download"
                    onClick={() => {
                      tagDataGrid.instance.beginCustomLoading();
                      return tempeSizeRequestService.downloadAllEpc(selectedRow.id).then(
                        (res) => {
                          var blob2 = new Blob([res], { type: "text/plain;charset=utf-8" });
                          saveAs(
                            blob2,
                            "'" +
                              selectedRow.po +
                              "' " +
                              "-" +
                              selectedRow.model +
                              " -" +
                              selectedRow.quality +
                              ".zip"
                          );
                          tagDataGrid.instance.endCustomLoading();
                          tagDataGrid.instance.refresh();
                        },
                        (err) => {
                          tagDataGrid.instance.endCustomLoading();
                          tagDataGrid.instance.refresh();
                          // return err.json().then((r) => {
                          //   throw r.errorMessage ? r.errorMessage : r.warningMessage;
                          // });
                        }
                      );
                    }}
                  />
                </Item>

                {/*<Item department="after">*/}
                {/*    <label style={{alignContent:"center",textAlign:"center",justifyContent:"center"}}>Position</label>*/}
                {/*  <TextBox className="dx-field-value" defaultValue="424 N Main St." />*/}
                {/*</Item>*/}
                <Item name="addRowButton" />
                <Item name="searchPanel" />
                <Item name="columnChooserButton" />
                <Item>
                  <div style={{ display: "flex" }}>
                    <NumberBox
                      label={t("{{key, capitalize}}", { key: "extraRate" })}
                      defaultValue={0}
                      onValueChange={onChangeExtraRate}
                    />
                  </div>
                </Item>
              </Toolbar>
              <SearchPanel visible={true} highlightCaseSensitive={true} width={240} />
              <Editing
                mode="row"
                allowUpdating={authProvider.isAuth("tempeSizeRequestUpdate")}
                // allowUpdating={authProvider.isAuth("tempeValidationUpdate")}
                // allowAdding={authProvider.isAuth("tempeValidationSave")}
                // allowDeleting={authProvider.isAuth("tempeValidationDelete")}
                allowAdding={false}
                allowDeleting={false}
              ></Editing>
              <Paging defaultPageSize={10} />
              <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 20, 50, 100]} />

              <Column
                caption=""
                dataField="id"
                dataType="number"
                allowEditing={false}
                width={70}
                allowFiltering={false}
                allowSorting={false}
                cellRender={(item) => (
                  <>
                    {item.data.status === 0 ? (
                      <Button
                        icon="add"
                        onClick={() => {
                          tagDataGrid.instance.beginCustomLoading();
                          return tempeEpcResultService.saveAll(item.data.id).then(
                            () => {
                              tagDataGrid.instance.endCustomLoading();
                              tagDataGrid.instance.refresh();
                            },
                            (err) => {
                              return err.json().then((r) => {
                                tagDataGrid.instance.endCustomLoading();
                                // throw r.errorMessage ? r.errorMessage : r.warningMessage;
                              });
                            }
                          );
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {item.data.status === 1 ? (
                      <Button
                        type="default"
                        icon="clock"
                        onClick={() => tagDataGrid.instance.refresh()}
                      />
                    ) : (
                      <></>
                    )}
                    {item.data.status === 2 ? (
                      <Button
                        type="success"
                        icon="check"
                        onClick={async () => {
                          await new Promise((resolve) => setTimeout(resolve, 100)); // 3 sec
                          setSelectedTagRow(item.data);
                          setOpenEPCRecord(true);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              />

              <Column
                caption={t("{{key, capitalize}}", { key: "model" })}
                dataField="tempeValidation.model"
                dataType="number"
                allowEditing={false}
                calculateDisplayValue={calculateModel}
              ></Column>

              <Column
                caption={t("{{key, capitalize}}", { key: "barcode" })}
                dataField="barcode"
                allowEditing={false}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "quality" })}
                dataField="tempeValidation.quality"
                dataType="number"
                allowEditing={false}
                calculateDisplayValue={calculateQuality}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "colorCode" })}
                dataField="colorCode"
                dataType="number"
                sortOrder="asc"
                allowEditing={false}
                calculateDisplayValue={calculateColorCode}
              ></Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "sizeCode" })}
                dataField="sizeCode"
                sortOrder="asc"
                dataType="number"
                allowEditing={false}
                calculateDisplayValue={calculateBodySize}
              >
                <RequiredRule />
              </Column>

              <Column
                dataType="number"
                caption={t("{{key, capitalize}}", { key: "matched" })}
                dataField="matched"
                allowEditing={false}
              />
              <Column
                caption={t("{{key, capitalize}}", { key: "quantity" })}
                dataField="quantity"
                dataType="number"
                allowEditing={false}
              >
                <RequiredRule />
              </Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "extraRate" })}
                dataField="extraRate"
                dataType="number"
              >
                <RequiredRule />
              </Column>
              <Column
                caption={t("{{key, capitalize}}", { key: "quantityFinal" })}
                dataField="quantityFinal"
                dataType="number"
                allowEditing={false}
              >
                <RequiredRule />
              </Column>
            </DataGrid>
          </ScrollView>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        fullWidth={true}
        maxWidth="xl"
        onClose={() => {
          // setOpenEPCRecord(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openEPCRecord}
        key={12}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenEPCRecord(false);
          }}
        >
          {selectedRow !== undefined && selectedRow !== null
            ? selectedRow.po + " " + t("{{key, capitalize}}", { key: "epc.detail.title" })
            : t("{{key, capitalize}}", { key: "epc.detail.title" })}
          <br />
          <span style={{ fontSize: "smaller" }}>
            {selectedTagRow !== undefined && selectedTagRow !== null
              ? t("{{key, capitalize}}", { key: "model" }) +
                ":" +
                (selectedRow.model !== undefined
                  ? selectedRow.model.toString().padStart(4, "0")
                  : "0000") +
                " - " +
                t("{{key, capitalize}}", { key: "quality" }) +
                ":" +
                (selectedRow.quality !== undefined
                  ? selectedRow.quality.toString().padStart(3, "0")
                  : "000") +
                " - " +
                t("{{key, capitalize}}", { key: "colorCode" }) +
                ": " +
                (selectedTagRow.colorCode != undefined
                  ? selectedTagRow.colorCode.toString().padStart(3, "0")
                  : "000") +
                " - " +
                t("{{key, capitalize}}", { key: "sizeCode" }) +
                ":" +
                (selectedTagRow.sizeCode !== undefined
                  ? selectedTagRow.sizeCode.toString().padStart(2, "0")
                  : "00")
              : t("{{key, capitalize}}", { key: "epc.detail.title" })}
          </span>
        </BootstrapDialogTitle>
        <DialogContent sx={{ mt: 1.5 }}>
          <DataGrid
            dataSource={epcTags}
            showBorders={false}
            remoteOperations={false}
            rowAlternationEnabled={false}
            onExporting={onExporting}
          >
            <LoadPanel enabled={true} />
            <Toolbar>
              {/*<Item name="exportButton" />*/}
              <Item location="after">
                <Button
                  icon="download"
                  onClick={() => {
                    tagDataGrid.instance.beginCustomLoading();
                    return tempeEpcResultService.downloadAllEpc(selectedTagRow.id).then(
                      (res) => {
                        var blob2 = new Blob([res], { type: "text/plain;charset=utf-8" });
                        // eslint-disable-next-line no-undef
                        saveAs(
                          blob2,
                          (selectedTagRow.department !== undefined
                            ? selectedTagRow.department + "_"
                            : "") +
                            (selectedTagRow.po !== undefined ? selectedTagRow.po + "_" : "") +
                            (selectedTagRow.upc !== undefined ? selectedTagRow.upc + "_" : "") +
                            (selectedTagRow.color !== undefined ? selectedTagRow.color + "_" : "") +
                            (selectedTagRow.size !== undefined ? selectedTagRow.size : "") +
                            ".txt"
                        );
                        tagDataGrid.instance.endCustomLoading();
                        tagDataGrid.instance.refresh();
                      },
                      (err) => {
                        tagDataGrid.instance.endCustomLoading();
                        tagDataGrid.instance.refresh();
                        return err.json().then((r) => {
                          // throw r.errorMessage ? r.errorMessage : r.warningMessage;
                        });
                      }
                    );
                  }}
                />
              </Item>

              <Item name="columnChooserButton" />
            </Toolbar>
            <Export enabled={true} formats={["csv"]} allowExportSelectedData={true} />

            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15, 20, 50, 100]}
              showInfo={true}
            />
            <Selection mode="multiple" />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Column
              caption={t("{{key, capitalize}}", { key: "epc" })}
              width={400}
              dataField="epcHex"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "accessPasswordHex" })}
              dataField="accessPasswordHex"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "kill.password" })}
              dataField="killPasswordHex"
            ></Column>
            <Column
              caption={t("{{key, capitalize}}", { key: "userMemoryHex" })}
              dataField="userMemoryHex"
              allowExporting={false}
            ></Column>
            {/*<Column*/}
            {/*  caption={t("{{key, capitalize}}", { key: "sizeCode" })}*/}
            {/*  dataField="sizeCode"*/}
            {/*  allowExporting={false}*/}
            {/*  width={100}*/}
            {/*></Column>*/}
          </DataGrid>
        </DialogContent>
      </BootstrapDialog>
    </DashboardLayout>
  );
}

export default TempeTagRecordsPage;
