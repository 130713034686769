import AuthProvider from "./AuthProvider";

const authProvider = AuthProvider();

let ws: WebSocket;
export const initWebsocket = (code) => {
  closeWebSocket();
  const baseUrl = process.env.REACT_APP_API_URL.replace("http", "ws").replace(
    "/api/",
    "/signal/" + code
  );
  const url = baseUrl + "?access_token=" + authProvider.getAuthData().access_token;
  ws = new WebSocket(url);
  ws.onopen = () => {
    onMessage();
  };
  ws.onclose = (e) => {
    if (e.code !== 4001) {
      setTimeout(() => {
        initWebsocket(code);
      }, 5000);
      window.dispatchEvent(
        new CustomEvent("socket", {
          detail: {
            code: "close",
          },
        })
      );
    }
  };
};

const onMessage = () => {
  ws.onmessage = (event) => {
    const message = event.data;
    const d = JSON.parse(message);
    window.dispatchEvent(new CustomEvent("socket", { detail: d }));
    window.dispatchEvent(new CustomEvent("inspection", { detail: d }));
    // window.postMessage(d, '*');
  };
};
export const closeWebSocket = () => {
  if (ws) {
    ws.close(4001, "app_custom");
  }
};

export const sendWebSocketMessage = (msg) => {
  if (ws && ws.readyState === 1) {
    ws.send(JSON.stringify(msg));
  }
};
