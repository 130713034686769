import AuthProvider from "../../../../providers/AuthProvider";

const authProvider = AuthProvider();
const basePath = "sgtinType/";

function SgtinTypeService() {
    const findOne = async (id) => {
        const requestOptions = {
            method: "GET",
        };
        return authProvider.authFetch(basePath + "findOne/" + id, requestOptions);
    };

    const findAll = async (loadOptions) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(loadOptions),
        };
        return authProvider.authFetch(basePath + "findAll", requestOptions);
    };

    const save = async (data) => {
        const requestData  = {
            ...data,
            format: data.format,
            filterValue: data.filterValue,
            serialNo: data.serialNo,
            startValue: data.startValue,
            endValue: data.endValue,
        };

      if(requestData.endValue<requestData.startValue) {
          console.log("End Value değeri, Start Value değerinden küçük olamaz");
          return Promise.reject(new Response(JSON.stringify({ errorMessage: "End Value değeri, Start Value değerinden küçük olamaz" })));
      }

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
        };
        return authProvider.authFetch(basePath + "save", requestOptions);
    };

    const update = (data) => {
            const requestData = {
            ...data,
            format: data.format,
            filterValue: data.filterValue,
            serialNo: data.serialNo,
            startValue: data.serialNo === "Sequential" ? data.startValue : undefined,
            endValue: data.serialNo === "Sequential" ? data.endValue : undefined,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
        };
        return authProvider.authFetch(basePath + "update", requestOptions);
    };

    const deleteOne = async (id) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: id,
        };
        return authProvider.authFetch(basePath + "delete", requestOptions);
    };

    return {
        findOne,
        findAll,
        save,
        update,
        deleteOne,
    };
}

export default SgtinTypeService;
